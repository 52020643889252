import { SETTINGS_URL, UPLOADS_URL} from "../constants";
import { apiSlice } from "./apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: () => ({
        url: SETTINGS_URL,
      }),
      providesTags: ["Settings"],
    }),

    updateSettings: builder.mutation({
      query: (data) => ({
        url: SETTINGS_URL,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Settings"],
    }),
    createSettings: builder.mutation({
      query: (data) => ({
        url: SETTINGS_URL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Settings"],
    }),
    deleteSettings: builder.mutation({
      query: (data) => ({
        url: SETTINGS_URL,
        method: "DELETE",
        body: data,
      }),
    }),
    uploadLogo : builder.mutation({
      query: (formData) => ({
        url: `${UPLOADS_URL}/logo`,
        method: "POST",
        body: formData,
      }),
    }),
    updateLogoSettings: builder.mutation({

      query: (data) => ({
        url: `${SETTINGS_URL}/logo`,
        method: "PUT",
        body: data,
      }),

    }),
    uploadBackground : builder.mutation({
      query: (formData) => ({
        url: `${UPLOADS_URL}/background`,
        method: "POST",
        body: formData,
      }),
    }),
    updateBackgroundSettings: builder.mutation({
      query: (data) => ({
        url: `${SETTINGS_URL}/background`,
        method: "PUT",
        body: data,
      }),
    }),
    updateColorSettings: builder.mutation({
      query: (data) => ({
        url: `${SETTINGS_URL}/color`,
        method: "PUT",
        body: data,
      }),
    }),
    updateContactSettings: builder.mutation({
      query: (data) => ({
        url: `${SETTINGS_URL}/contact`,
        method: "PUT",
        body: data,
      }),
  }),
  updateSiteCopySettings: builder.mutation({
    query: (data) => ({
      url: `${SETTINGS_URL}/copy`,
      method: "PUT",
      body: data,
    }),
  }),
}),
});
export const {useGetSettingsQuery, useUpdateSettingsMutation, useCreateSettingsMutation, useDeleteSettingsMutation, useUploadLogoMutation,useUpdateLogoSettingsMutation,useUploadBackgroundMutation, useUpdateBackgroundSettingsMutation,useUpdateColorSettingsMutation, useUpdateContactSettingsMutation,useUpdateSiteCopySettingsMutation} = settingsApiSlice;