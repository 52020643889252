import React from "react";
import { Container, Row } from "react-bootstrap";
import FormColumnContainer from "../components/FormColumnContainer";
import { useGetOrderByIdQuery } from "../slices/ordersApiSlice";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Message from "../components/Message";

const OrderResult = () => {
  //get the order result and order_id from the url
  const { result, order_id } = useParams();
  const {
    data: order,
    isLoading: isLoadingOrder,
    error: errorOrder,
  } = useGetOrderByIdQuery(order_id);
  const [title, setTitles] = useState({});

  useEffect(() => {
    setTitles({ main: "Order Result", right: "Order Summary" });
    //fetch the order details
  }, []);

  return isLoadingOrder ? (
    <>Loader</>
  ) : errorOrder ? (
    <Message variant="danger">{errorOrder}</Message>
  ) : result === "success" ? (
    <Row className="justify-content-md-center my-2">
      <FormColumnContainer id="order_result" title={title}>
        <h3>Thank You Order Placed Successfully</h3>
        <div className="my-2 p-2 card-subsection">
          <h4>Order Summary</h4>
          <ul>
            <li>{order.serviceAddress.fullName}</li>
            <li>{order.serviceAddress.email}</li>
            <li>{order.serviceAddress.address1}</li>
            <li>{order.serviceAddress.address2}</li>
            <li>{order.serviceAddress.suburb}</li>
            <li>{order.serviceAddress.city}</li>
            <li>{order.serviceAddress.mobile}</li>
          </ul>
        </div>
        <div className="my-2 p-2 card-subsection">
          <h2>Order ID: {order_id}</h2>
          <p>Payment Method: {order.paymentMethod}</p>
          <p>Total: R{order.totalPrice}</p>
          <p>
            Your order has been placed successfully. You will receive an email
            with the order details.
          </p>
        </div>
      </FormColumnContainer>
    </Row>
  ) : result === "failure" ? (
    <Row className="justify-content-md-center">
      <FormColumnContainer id="order_result">
        <h1>Order Failed</h1>
        <p>
          Your order has failed. Please try again or contact customer support.
        </p>
      </FormColumnContainer>
    </Row>
  ) : result === "cancel" ? (
    <Row className="justify-content-md-center">
      <FormColumnContainer id="order_result">
        <h1>Order Cancelled</h1>
        <p>Your order has been cancelled.</p>
      </FormColumnContainer>
    </Row>
  ) : (
    <Container>
      <h1>Invalid Request</h1>
    </Container>
  );
};

export default OrderResult;
