import React, { useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Table, Button, Accordion, Modal, Form } from "react-bootstrap";
import {
  FaTimes,
  FaEdit,
  FaTrash,
  FaArrowRight,
  FaPlusCircle,
  FaEllipsisH,
} from "react-icons/fa";
import {
  useGetSalesLeadsQuery,
  useCreateSalesLeadMutation,
  useDeleteSalesLeadMutation,
  useUpdateSalesLeadMutation,
} from "../../slices/salesApiSlice";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const SalesLeadsListScreen = () => {
  const {
    data: salesLeads,
    isLoading,
    error,
    refetch,
  } = useGetSalesLeadsQuery();
  const [createSalesLead] = useCreateSalesLeadMutation();
  const [deleteSalesLead] = useDeleteSalesLeadMutation();
  const [updateSalesLead] = useUpdateSalesLeadMutation();

  const [showModal, setShowModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);

  const handleAddGenericSalesLead = async (category) => {
    const genericLead = {
      name: "ABC",
      company: "No Name",
      email: "test@example.com",
      phone: "123-456-7890",
      category,
      message: `This is a generic ${category} entry.`,
      status: "open",
    };
    await createSalesLead(genericLead);
    refetch(); // Refetch the sales leads after adding a new one
  };

  const handleDelete = async (id) => {
    await deleteSalesLead(id);
    refetch();
  };

  const handleUpdate = (salesLead) => {
    setSelectedLead(salesLead);
    console.log(salesLead)
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedLead(null);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSalesLead(selectedLead);
      refetch(); // Refetch the sales leads to get the updated data
      setShowModal(false);
    } catch (error) {
      console.error("Failed to update sales lead:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <h1 className="ml-2">Sales</h1>
          <Container>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Contact Form Leads</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Table striped responsive className="table-sm table-wrapper">
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>EMAIL</th>
                        <th>PHONE</th>
                        <th>CATEGORY</th>
                        <th>MESSAGE</th>
                        <th>STATUS</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesLeads &&
                        salesLeads
                          .filter(
                            (salesLead) => salesLead.category === "webform"
                          )
                          .map((salesLead) => (
                            <tr key={salesLead._id}>
                              <td>{salesLead.name}</td>
                              <td>{salesLead.email}</td>
                              <td>{salesLead.phone}</td>
                              <td>{salesLead.category}</td>
                              <td>{salesLead.message}</td>
                              <td>{salesLead.status}</td>
                              <td>
                                <LinkContainer
                                  to={`/admin/salesleads/${salesLead._id}`}
                                >
                                  <Button className="btn-sm">
                                    <FaArrowRight /> Details
                                  </Button>
                                </LinkContainer>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h4>Government Procurement</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <h5>
                    RFQ's
                    <Button
                      variant="light"
                      className="btn-sm mx-2"
                      onClick={() => handleAddGenericSalesLead("RFQ")}
                    >
                      <FaPlusCircle /> Add RFQ
                    </Button>
                    <a
                      href="https://secure.csd.gov.za/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button variant="light" className="btn-sm mx-2">
                        <FaArrowRight /> CSD
                      </Button>
                    </a>
                  </h5>
                  <Table striped responsive className="table-sm table-wrapper">
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>MESSAGE</th>
                        <th>STATUS</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesLeads &&
                        salesLeads
                          .filter((salesLead) => salesLead.category === "RFQ")
                          .map((salesLead) => (
                            <tr key={salesLead._id}>
                              <td>{salesLead.name}</td>
                              <td>{salesLead.message}</td>
                              <td>{salesLead.status}</td>
                              <td className="d-flex justify-content-around align-items-center">
                                <Button
                                  variant="light"
                                  className="btn-sm mx-2"
                                  onClick={() => handleDelete(salesLead._id)}
                                >
                                  <FaTrash />
                                </Button>
                                <LinkContainer
                                  to={`/admin/salesleads/${salesLead._id}`}
                                >
                                  <Button
                                    variant="light"
                                    className="btn-sm mx-2"
                                  >
                                    <FaEllipsisH />
                                  </Button>
                                </LinkContainer>
                                <Button
                                  variant="light"
                                  className="btn-sm mx-2"
                                  onClick={() => handleUpdate(salesLead)}
                                >
                                  <FaEdit />
                                </Button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                  <h5>
                    RFT's
                    <Button
                      variant="light"
                      className="btn-sm mx-2"
                      onClick={() => handleAddGenericSalesLead("RFT")}
                    >
                      <FaPlusCircle /> Add RFT
                    </Button>
                    <a
                      href="https://www.etenders.gov.za/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button variant="light" className="btn-sm mx-2">
                        <FaArrowRight /> eTenders
                      </Button>
                    </a>
                  </h5>
                  <Table striped responsive className="table-sm table-wrapper">
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>MESSAGE</th>
                        <th>STATUS</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesLeads &&
                        salesLeads
                          .filter((salesLead) => salesLead.category === "RFT")
                          .map((salesLead) => (
                            <tr key={salesLead._id}>
                              <td>{salesLead.name}</td>
                              <td>{salesLead.message}</td>
                              <td>{salesLead.status}</td>
                              <td className="d-flex justify-content-around align-items-center">
                                <Button
                                  variant="light"
                                  className="btn-sm mx-2"
                                  onClick={() => handleDelete(salesLead._id)}
                                >
                                  <FaTrash />
                                </Button>
                                <LinkContainer
                                  to={`/admin/salesleads/${salesLead._id}`}
                                >
                                  <Button
                                    variant="light"
                                    className="btn-sm mx-2"
                                  >
                                    <FaEllipsisH />
                                  </Button>
                                </LinkContainer>
                                <Button
                                  variant="light"
                                  className="btn-sm mx-2"
                                  onClick={() => handleUpdate(salesLead)}
                                >
                                  <FaEdit />
                                </Button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>

          {/* Modal for editing sales lead */}
          <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Sales Lead</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedLead && (
                <Form onSubmit={handleFormSubmit}>
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      value={selectedLead.name}
                      onChange={(e) =>
                        setSelectedLead({ ...selectedLead, name: e.target.value })
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="email" className="mt-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={selectedLead.email}
                      onChange={(e) =>
                        setSelectedLead({ ...selectedLead, email: e.target.value })
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="phone" className="mt-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone"
                      value={selectedLead.phone}
                      onChange={(e) =>
                        setSelectedLead({ ...selectedLead, phone: e.target.value })
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="message" className="mt-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter message"
                      value={selectedLead.message}
                      onChange={(e) =>
                        setSelectedLead({
                          ...selectedLead,
                          message: e.target.value,
                        })
                      }
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="category" className="mt-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedLead.category}
                      onChange={(e) =>
                        setSelectedLead({ ...selectedLead, category: e.target.value })
                      }
                    >
                      <option value="RFQ">RFQ</option>
                      <option value="RFT">RFT</option>

                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="status" className="mt-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedLead.status}
                      onChange={(e) =>
                        setSelectedLead({ ...selectedLead, status: e.target.value })
                      }
                    >
                      <option value="open">Open</option>
                      <option value="awaiting supplier">Awaiting Supplier</option>
                      <option value="processing">Processing</option>
                      <option value="submitted">Submitted</option>
                      <option value="closed">Closed</option>
                    </Form.Control>
                  </Form.Group>

                  <Button variant="primary" type="submit" className="mt-4">
                    Update
                  </Button>
                </Form>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default SalesLeadsListScreen;
