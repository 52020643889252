import { TASKS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const tasksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query({
      query: () => ({
        url: TASKS_URL,
      }),
      providesTags: (result = [], error, id) => [
        "Tasks",
        ...result.map(({ id }) => ({ type: "Task", id })),
      ],
    }),

    getTaskById: builder.query({
      query: (id) => ({
        url: `${TASKS_URL}/${id}`,
      }),
    }),
    providesTags: (result, error, id) => [{ type: "Tasks", id }],

    updateTask: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `${TASKS_URL}/${id}`,
        method: "PUT",
        body: payload,
      }),
      async onQueryStarted({ id, ...payload }, { dispatch, queryFulfilled }) {
        try {
          if (typeof queryFulfilled === 'function') {
            const result = await queryFulfilled({ id, ...payload });
            dispatch(
              tasksApiSlice.util.updateQueryData(
                "getTasks",
                undefined,
                (draft) => {
                  const index = draft.findIndex((task) => task.id === id);
                  draft[index] = result;
                }
              )
            );
          } else {
            console.log("queryFulfilled is not a function");
          }
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: ["Tasks"],
    }),

    deleteTask: builder.mutation({
      query: (id) => ({
        url: `${TASKS_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tasks"],
    }),

    createTask: builder.mutation({
      query: (data) => ({
        url: `${TASKS_URL}/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Tasks"],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useCreateTaskMutation,
} = tasksApiSlice;
