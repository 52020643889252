
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {useGetBMCQuery} from '../../slices/bmcApiSlice'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useState } from 'react'
import BmcBlock from '../../components/BmcBlock'
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

const BmcNotesScreen = () => {
    const {data:canvas, isLoading, error} = useGetBMCQuery()
    const [dragging, setDragging] = useState(false);
    const [draggedItem, setDraggedItem] = useState(null);

    const onDragStart = (start) => {
        setDragging(true);
        setDraggedItem(start);
    }
    const onDragEnd = (result) => {
        console.log(result);
        setDragging(false);
        const { destination, source } = result;
        console.log(destination, source);
        if (!destination) {
            return;
        }
        if (
            destination.index === source.index &&
            destination.droppableId === source.droppableId
        ) {
            return;
        }
        const canvasCopy = [...canvas];
        console.log(canvasCopy);
        const droppedItem = canvasCopy[source.index];
        canvasCopy.splice(source.index, 1);
        canvasCopy.splice(destination.index, 0, droppedItem);
        setDraggedItem(null);
    }

    return (
        <>
          {isLoading ? (<Loader/>) :  error ? (<Message variant='danger'>{error?.data?.message || error.error }</Message>):
          (
        <Container fluid>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="board" direction="horizontal" type="COLUMN">
            {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} >
                <Container fluid  >
                <h1>Business Model Canvas:Extremely Clean</h1>
                    <div class="bmc">
                    {canvas.map((bmc, index) => {
                        return (<BmcBlock block_id={bmc._id} title={bmc.block_name} notes={bmc.block_notes} index={index} />) 
                        
                    })}
                        {provided.placeholder}
                    </div>
                </Container>
                </div>
                )}

            </Droppable>
             </DragDropContext>
        </Container>
            )}
        </>
    ) //end of return

}//end of function
export default BmcNotesScreen