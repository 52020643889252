import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FloatingLabel, Row, Col } from "react-bootstrap";
import { useGetServicesQuery } from "../slices/servicesApiSlice";
import { addToCart, updateCart, clearCart } from "../slices/cartSlice";
import OneTimeCheckout from "../components/OneTimeCheckout";
import SchedulerCheckout from "../components/SchedulerCheckout";
import Loader from "./Loader";
import Message from "./Message";

const CleaningComponents = () => {
  const [selectedComponent, setSelectedComponent] = useState("Home");
  const [sliderValue, setSliderValue] = useState(0);
  const [roomNo, setRoomNo] = useState({ bedrooms: 2, bathrooms: 1 });
  const [selectedServiceBilling, setSelectedServiceBilling] = useState("");
  const dispatch = useDispatch();
  const {data: services, isLoading, error: serviceError} = useGetServicesQuery();
  const state = useSelector((state) => state.cart);

  const getServiceComponents = (category) => {
    let selectedComponents = services.filter(
      (service) => service.category === category
    );
    return selectedComponents ? selectedComponents[0].component : [];
  };
  const handleCreateServiceComponentForm = (selectedValue) => {
    setSelectedComponent(selectedValue);
  };

  const handleRoomNoUpdate = useCallback(
    (e, label) => {
      const existingItem = state.cartItems.find(
        (item) => item.name === "Basic Clean" || "Office Clean" || "Hotel Clean"
      );
      if (existingItem) {
        let previousValue = 0;
        const currentValue = parseInt(e.target.value);
        if (e.target.id === "Bedrooms") {
          previousValue = e.target.getAttribute("data-previous-value")
            ? parseInt(e.target.getAttribute("data-previous-value"))
            : roomNo.bedrooms;
        }
        if (e.target.id === "Bathrooms") {
          previousValue = e.target.getAttribute("data-previous-value")
            ? parseInt(e.target.getAttribute("data-previous-value"))
            : roomNo.bathrooms;
        }
        if (e.target.id === "Offices") {
          previousValue = e.target.getAttribute("data-previous-value")
            ? parseInt(e.target.getAttribute("data-previous-value"))
            : roomNo.offices;
        }

        if (currentValue > previousValue) {
          const updatedItem = {
            ...existingItem,
            duration: existingItem.duration + (currentValue - previousValue),
            bedrooms: e.target.id === "Bedrooms" ? currentValue : existingItem.bedrooms,
            bathrooms: e.target.id === "Bathrooms" ? currentValue: existingItem.bathrooms,
            offices: e.target.id === "Offices" ? currentValue : existingItem.offices,
          };
          dispatch(updateCart(updatedItem));
          setSliderValue(updatedItem.duration);
        } else if (currentValue < previousValue) {
          const updatedItem = {
            ...existingItem,
            duration: existingItem.duration - (previousValue - currentValue),
            bedrooms: e.target.id === "Bedrooms" ? currentValue : existingItem.bedrooms,
            bathrooms: e.target.id === "Bathrooms" ? currentValue: existingItem.bathrooms,
            offices: e.target.id === "Offices" ? currentValue : existingItem.offices,
          };
          dispatch(updateCart(updatedItem));
          setSliderValue(updatedItem.duration);
        }
        e.target.setAttribute("data-previous-value", currentValue);
      }
    },
    [dispatch, roomNo, setSliderValue, state.cartItems]
  );

  const formSelectFieldGenerator = useCallback(
    (length, label) => {
      const options = [];
      for (let i = 0; i <= length; i++) {
        options.push(
          <option
            value={i}
            selected={
              label === "Bedrooms"
                ? i === roomNo.bedrooms
                : label === "Bathrooms"
                ? i === roomNo.bathrooms
                : label === "Offices"
                ? i === roomNo.offices
                : null
            }
          >
            {i}
          </option>
        );
      }
      return (
        <Form.Group className="mb-3">
          <FloatingLabel label={label} className="mb-3">
            <Form.Select
              value={roomNo.label}
              onChange={handleRoomNoUpdate}
              id={label}
            >
              {options}
            </Form.Select>
          </FloatingLabel>
        </Form.Group>
      );
    },
    [handleRoomNoUpdate, roomNo]
  );

  useEffect(() => {
    //first clear the cart
    dispatch(clearCart());
    if (selectedComponent === "Home") {
      setRoomNo({ bedrooms: 2, bathrooms: 1 });
      //find the billing item Home component and add to cart
      const baseHomeClean = services
        .filter((service) => service.category === "Cleaning Services")[0]
        .component.filter((component) => component.name === "Home")[0];
      //add hours to the baseHomeClean.billings[0].duration
      //also add the roomNo object to the baseHomeClean.billings[0] cart item

      const updatedDuration =
        baseHomeClean.billing[0].duration + roomNo.bedrooms + roomNo.bathrooms;
      const updatedBilling = {
        ...baseHomeClean.billing[0],
        duration: updatedDuration,
        bedrooms: roomNo.bedrooms,
        bathrooms: roomNo.bathrooms,
      };
      dispatch(addToCart(updatedBilling));
      setSliderValue(updatedBilling.duration);
    } else if (selectedComponent === "Office") {
      setRoomNo({ offices: 5 });
      //find the billing item Office component and add to cart
      const baseOfficeClean = services
        .filter((service) => service.category === "Cleaning Services")[0]
        .component.filter((component) => component.name === "Office")[0];
      const updatedDuration =
        baseOfficeClean.billing[0].duration + roomNo.offices;
      const updatedBilling = {
        ...baseOfficeClean.billing[0],
        duration: updatedDuration,
        offices: roomNo.offices,  
      };
      dispatch(addToCart(updatedBilling));
      setSliderValue(updatedBilling.duration);
    } else if (selectedComponent === "Hotel and Airbnb") {
      //find the billing item Hotel and Airbnb component and add to cart
      const baseHotelClean = services
        .filter((service) => service.category === "Cleaning Services")[0]
        .component.filter(
          (component) => component.name === "Hotel and Airbnb"
        )[0];
      const updatedDuration =
        baseHotelClean.billing[0].duration + roomNo.bedrooms + roomNo.bathrooms;
      const updatedBilling = {
        ...baseHotelClean.billing[0],
        duration: updatedDuration,
        bedrooms: roomNo.bedrooms,
        bathrooms: roomNo.bathrooms,
      };
      dispatch(addToCart(updatedBilling));
      setSliderValue(updatedBilling.duration);

      setRoomNo({ bedrooms: 4, bathrooms: 3 });
    }
  }, [
    dispatch,
    roomNo.bathrooms,
    roomNo.bedrooms,
    roomNo.offices,
    selectedComponent,
    services,
  ]);

  const handleSliderChange = (e) => {
    const existingItem = state.cartItems.find(
      (item) => item.name === "Basic Clean" || "Office Clean" || "Hotel Clean"
    );
    if (existingItem) {
      const updatedItem = {
        ...existingItem,
        duration: parseInt(e.target.value),
      };
      dispatch(updateCart(updatedItem));
      setSliderValue(updatedItem.duration);
    }
  };
  const handleBillingTypeSwitch = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setSelectedServiceBilling("Recurring");
    } else {
      setSelectedServiceBilling("One-Time");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : serviceError ? (
        <Message variant="danger">
          {serviceError?.data?.message || serviceError.error}
        </Message>
      ) : (
        <>
          <Form.Group className="mb-3">
            <FloatingLabel label="Service Domain" className="mb-3">
              <Form.Select
                onChange={(e) =>
                  handleCreateServiceComponentForm(e.target.value)
                }
              >
                {getServiceComponents("Cleaning Services").map((component) => (
                  <option
                    key={component._id}
                    value={component.name}
                    selected={component.name === selectedComponent}
                  >
                    {component.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          {selectedComponent === "Home" && (
            <Row>
              <Col>{formSelectFieldGenerator(5, "Bedrooms")}</Col>
              <Col>{formSelectFieldGenerator(5, "Bathrooms")}</Col>
            </Row>
          )}

          {selectedComponent === "Office" && (
            <Row>
              <Col>{formSelectFieldGenerator(10, "Offices")}</Col>
              <Col>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Frequency" className="mb-3">
                    <Form.Select>
                      <option value=""> Select Frequency</option>
                      <option value="Daily">Daily</option>
                      <option value="Weekly" selected>
                        Weekly
                      </option>
                      <option value="Monthly">Monthly</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
          )}
          {selectedComponent === "Hotel and Airbnb" && (
            <Row>
              <Col>{formSelectFieldGenerator(5, "Bedrooms")}</Col>
              <Col>{formSelectFieldGenerator(5, "Bathrooms")}</Col>
            </Row>
          )}
          <Row className="justify-content-center">
            <Form.Label className="text-center">Hour Slider</Form.Label>
            <Form.Range
              value={sliderValue}
              min="1"
              max="24"
              name="room-slider"
              onChange={handleSliderChange}
              className="custom-slider"
              style={{ border: "1px solid lime" }}
            />
            <p className="text-center">
              Selected Hours: {parseInt(sliderValue)}
            </p>
          </Row>
          <Row>
            <div class="d-flex justify-content-center my-2">
              <span className="px-1 mx-1 switch_label">One-Time</span>
              <label class="switch">
                <input type="checkbox" onChange={handleBillingTypeSwitch} />
                <span class="slider"></span>
              </label>
              <span className="px-1 mx-4 switch_label">Recurring</span>
            </div>
            {selectedServiceBilling === "Recurring" ? (
              <SchedulerCheckout
                selectedServiceComponent={selectedComponent}
              />
            ) : (
              <OneTimeCheckout
                selectedServiceComponent={selectedComponent}
              />
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default CleaningComponents;
