import {useState, useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import { FaTimes, FaEdit, FaPlus } from 'react-icons/fa';
import {toast} from 'react-toastify'
import {useUpdateTaskMutation,useCreateTaskMutation} from '../slices/tasksApiSlice'
import {useGetUsersQuery} from '../slices/usersApiSlice'    
import Loader from '../components/Loader'
import Message from '../components/Message'
import Header from '../components/Header'
import FormContainer from '../components/FormContainer'
import {Button, Form} from 'react-bootstrap'
import { FaArrowRight,FaArrowLeft,FaEnvelope} from 'react-icons/fa';


const AddTask = () => {
    const [createTask, {isLoading: loadingCreate}] = useCreateTaskMutation()
    const {data:users, isLoading:loadingUsers, error} = useGetUsersQuery()
    const navigate = useNavigate()

    const submitHandler = async(e) => {
        e.preventDefault()
        try{

            const fd = new FormData(e.target)
            const taskForm = fd.getAll('taskData')
            const data = Object.fromEntries(fd.entries())
            data.taskData = taskForm
            console.log(data)
            await createTask({...data})
            toast.success('Task added/updated successfully')
            navigate('/tasks')
        }catch(err){
            toast.error(err?.data?.message || err.error)

        }
    } 

  return (
    <>
    <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">{/* Your content */}
        <button className='button-28' ><Link to='/admin/tasks'> Go Back <FaArrowLeft/></Link></button>
         
        {/* {isLoading ? <Loader/> : loadingUsers  ? <Loader/>: error ? <Message variant='danger'>{error}</Message> :  */}
        {loadingUsers  ? <Loader/>: error ? <Message variant='danger'>{error}</Message> : 

    <FormContainer title="Add Task">
        <Form onSubmit={submitHandler}>
      <Form.Group>
          <Form.Label htmlFor='name'>Add Task Name</Form.Label>
          <Form.Control type='text' name='name' placeholder='Enter Task Name'></Form.Control>
      </Form.Group>
      <Form.Group>
          <Form.Label htmlFor='name'>Add Task Description</Form.Label>
          <Form.Control type='textarea' name='description' placeholder='Enter Task Description'></Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor='status'>Task status</Form.Label>
        <Form.Select name="status">
            <option value="">--Please choose an option--</option>
            <option value="todo">Todo</option>
            <option value="doing">Doing</option>
            <option value="done">Done</option>
            <option value="backlog">Backlog</option>
        </Form.Select>
        </Form.Group>
        <Form.Group>
        <Form.Label htmlFor='assignee'>Task assignee</Form.Label>
        <Form.Select name="assignee" >
        <option value="" name='user'>--Please choose an option--</option>    
        {users.map(user => (
            <option id={user._id}>{user.email}</option>
            )
        )}
        </Form.Select>
        </Form.Group>
        <button type='submit' 
                className='button-28'> 
                Add <FaArrowRight/>
        </button> 
    </Form>
    </FormContainer>
    
  }
    </div>
    </main>
    </>
  )
}

export default AddTask