import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EditCategoryModal = ({
  show,
  handleClose,
  categoryData,
  setCategoryData,
  handleSaveChanges,
}) => {
  const handleCategoryChange = (e) => {
    setCategoryData({ ...categoryData, category: e.target.value });
  };

  const handleComponentChange = (index, field, value) => {
    const updatedComponents = [...categoryData.component];
    const updatedComponent = { ...updatedComponents[index] };
    updatedComponent[field] = value;
    updatedComponents[index] = updatedComponent;
    setCategoryData({ ...categoryData, component: updatedComponents });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="edit-category-name">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              type="text"
              value={categoryData.category}
              onChange={handleCategoryChange}
            />
          </Form.Group>
          {categoryData.component.map((comp, index) => (
            <div key={index} className="mt-4">
              <Form.Group controlId={`edit-component-name-${index}`}>
                <Form.Label>Component Name</Form.Label>
                <Form.Control
                  type="text"
                  value={comp.name}
                  onChange={(e) =>
                    handleComponentChange(index, "name", e.target.value)
                  }
                />
              </Form.Group>
              <Form.Group controlId={`edit-component-description-${index}`}>
                <Form.Label>Component Description</Form.Label>
                <Form.Control
                  type="text"
                  value={comp.description}
                  onChange={(e) =>
                    handleComponentChange(index, "description", e.target.value)
                  }
                />
              </Form.Group>
            </div>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCategoryModal;
