import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const AddBillModal = ({ show, handleClose, newBill, setNewBill, handleAddBill }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Bill</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="billType">
          <Form.Label>Bill Type</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter bill type"
            value={newBill.type}
            onChange={(e) => setNewBill({ ...newBill, type: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="billCategory">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter bill category"
            value={newBill.category}
            onChange={(e) => setNewBill({ ...newBill, category: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="billName">
          <Form.Label>Bill Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter bill name"
            value={newBill.name}
            onChange={(e) => setNewBill({ ...newBill, name: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="billRate">
          <Form.Label>Rate</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter bill rate"
            value={newBill.rate}
            onChange={(e) => setNewBill({ ...newBill, rate: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="billDuration">
          <Form.Label>Duration</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter bill duration"
            value={newBill.duration}
            onChange={(e) => setNewBill({ ...newBill, duration: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="billDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter bill description"
            value={newBill.description}
            onChange={(e) => setNewBill({ ...newBill, description: e.target.value })}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" onClick={handleAddBill}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBillModal;
