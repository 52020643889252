import React, { useState, useEffect } from "react";
import { Accordion, Form, Button, Row, Col } from "react-bootstrap";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import FormContainer from "../../components/FormContainer";
import {
  useGetSettingsQuery,
  useUpdateLogoSettingsMutation,
  useUploadLogoMutation,
  useUploadBackgroundMutation,
  useUpdateBackgroundSettingsMutation,
  useUpdateColorSettingsMutation,
  useUpdateContactSettingsMutation,
  useUpdateSiteCopySettingsMutation, // Add this import for site copy update
} from "../../slices/settingsApiSlice";

const SiteSettings = () => {
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [selectedLogoFile, setSelectedLogoFile] = useState(null);
  const [selectedBackgroundFile, setSelectedBackgroundFile] = useState(null);
  const [siteBg, setSiteBg] = useState("");
  const [siteColor, setSiteColor] = useState("");
  const [siteCopy, setSiteCopy] = useState("");
  const [siteCopyContent, setSiteCopyContent] = useState(""); // New state for editable site copy content
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const {
    data: settings,
    error: errorSettings,
    loading: loadingSettings,
    refetch,
  } = useGetSettingsQuery();

  const [updateLogoSettings] = useUpdateLogoSettingsMutation();
  const [uploadLogo] = useUploadLogoMutation();
  const [uploadBackground] = useUploadBackgroundMutation();
  const [updateBackgroundSettings] = useUpdateBackgroundSettingsMutation();
  const [updateColorSettings] = useUpdateColorSettingsMutation();
  const [updateContactSettings] = useUpdateContactSettingsMutation();
  const [updateSiteCopySettings] = useUpdateSiteCopySettingsMutation(); // Add this mutation for site copy update

  const [availableLogos, setAvailableLogos] = useState([]);
  const [availableBackgrounds, setAvailableBackgrounds] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [availableContacts, setAvailableContacts] = useState([]);
  const [availableSiteCopies, setAvailableSiteCopies] = useState([]);
  const [selectedPaletteName, setSelectedPaletteName] = useState("");
  const [selectedPaletteColor, setSelectedPaletteColor] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedURL, setSelectedURL] = useState("");
  

  useEffect(() => {
    if (settings && settings[0]) {
      setAvailableLogos(settings[0].site_logo);
      setAvailableBackgrounds(settings[0].site_bg);
      setAvailableColors(settings[0].site_palette);
      setAvailableContacts(settings[0].site_contacts);
      setAvailableSiteCopies(settings[0].site_copy);
      setName(settings[0].site_name); 
    }
  }, [settings]);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = { name, logo, siteBg, siteColor, selectedContact, siteCopy };
    updateLogoSettings(formData);
  };

  const uploadLogoHandler = async (e) => {
    e.preventDefault();
    if (!selectedLogoFile) {
      setError("Please select a logo file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("logo", selectedLogoFile);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await uploadLogo(formData, config).unwrap();
      if (response && response.data) {
        const { data } = response;
        setAvailableLogos([...availableLogos, data]);
        setMessage("Logo uploaded successfully");
      } else {
        setError("Upload failed: No data received.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      setError("Failed to upload logo. Please try again.");
    }
  };

  const uploadBackgroundHandler = async (e) => {
    e.preventDefault();
    if (!selectedBackgroundFile) {
      setError("Please select a background file to upload.");
      return;
    }
    const formData = new FormData();
    formData.append("background", selectedBackgroundFile);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await uploadBackground(formData, config).unwrap();
      if (response && response.data) {
        const { data } = response;
        setAvailableBackgrounds([...availableBackgrounds, data]);
        setMessage("Background uploaded successfully");
      } else {
        setError("Upload failed: No data received.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      setError("Failed to upload background. Please try again.");
    }
  };

  const updateIsSelectedLogo = async (logoUrl) => {
    if (!settings || settings.length === 0) {
      console.error("Settings are not loaded.");
      return;
    }

    try {
      const selectedLogo = settings
        .flatMap((setting) => setting.site_logo)
        .find((logo) => logo.site_logo_url === logoUrl);

      if (!selectedLogo) {
        console.error("Selected logo not found.");
        return;
      }

      const { data } = await updateLogoSettings(selectedLogo).unwrap();
      await refetch();
      console.log("Logo updated successfully:", data);
    } catch (error) {
      console.error("Failed to update logo settings:", error);
      setError("Failed to update logo settings. Please try again.");
    }
  };

  const updateIsSelectedBackground = async (bgUrl) => {
    if (!settings || settings.length === 0) {
      console.error("Settings are not loaded.");
      return;
    }
    try {
      const selectedBackground = settings
        .flatMap((setting) => setting.site_bg)
        .find((bg) => bg.site_bg_url === bgUrl);

      if (!selectedBackground) {
        console.error("Selected background not found.");
        return;
      }

      const { data } = await updateBackgroundSettings(selectedBackground).unwrap();
      await refetch();
      console.log("Background updated successfully:", data);
    } catch (error) {
      console.error("Failed to update background settings:", error);
      setError("Failed to update background settings. Please try again.");
    }
  };

  const handlePaletteNameChange = (e) => {
    const selectedName = e.target.value;
    setSelectedPaletteName(selectedName);

    const selectedColor =
      availableColors.find(
        (palette) => palette.site_palette_name === selectedName
      )?.site_palette_color || "";
    setSelectedPaletteColor(selectedColor);
  };

  const handlePaletteColorChange = (e) => {
    const colorValue = e.target.value;
    setSelectedPaletteColor(colorValue);
  };

  const handleUpdateColor = async () => {
    try {
      await updateColorSettings({
        sitePaletteName: selectedPaletteName,
        sitePaletteColor: selectedPaletteColor,
      }).unwrap();
      setMessage("Color updated successfully");
    } catch (error) {
      setError("Failed to update color. Please try again.");
    }
  };

  const handleUpdateContact = async () => {
    try {
      await updateContactSettings({ siteContactName: selectedContact, siteContactURL: selectedURL }).unwrap();
      setMessage("Contact updated successfully");
    } catch (error) {
      setError("Failed to update contact. Please try again.");
    }
  };

  const handleUpdateSiteCopy = async () => {
    try {
      await updateSiteCopySettings({ siteCopyName: siteCopy, siteCopyContent }).unwrap();
      setMessage("Site copy updated successfully");
    } catch (error) {
      setError("Failed to update site copy. Please try again.");
    }
  };

  const updateIsSelectedContact = (contactName) => {
    setSelectedContact(contactName);
    const contact = availableContacts.find((contact) => contact.site_contact_name === contactName);
    setSelectedURL(contact ? contact.site_contact_url : "");
  };

  const updateIsSelectedSiteCopy = (copyText) => {
    console.log(copyText)
    setSiteCopy(copyText);
    const copy = availableSiteCopies.find((copy) => copy.site_copy_name === copyText);
    setSiteCopyContent(copy ? copy.site_copy_text : "");
  };

  return (
    <>
      {loadingSettings ? (
        <Loader />
      ) : errorSettings ? (
        <Message variant="danger">{errorSettings?.data?.message || errorSettings.error}</Message>
      ) : (
        <FormContainer>
          <Accordion defaultActiveKey="0">
            <h1>Site Settings</h1>
            {error && <Message variant="danger">{error}</Message>}
            {message && <Message variant="success">{message}</Message>}

            <Form onSubmit={submitHandler}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Logo - Change or Upload</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mt-3" controlId="logoUpload">
                        <Form.Label>Upload Logo</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={(e) => setSelectedLogoFile(e.target.files[0])}
                        />
                        <Button className="mt-2" onClick={uploadLogoHandler}>
                          Upload
                        </Button>
                      </Form.Group>
                      <Form.Group className="mt-3" controlId="siteLogo">
                        <Form.Label>Logo</Form.Label>
                        <Form.Control
                          as="select"
                          value={logo}
                          onChange={(e) => updateIsSelectedLogo(e.target.value)}
                        >
                          <option value="">Select Logo</option>
                          {availableLogos.map((logo, index) => (
                            <option key={index} value={logo.site_logo_url}>
                              {logo.site_logo_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Background - Change or Upload</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mt-3" controlId="bgUpload">
                        <Form.Label>Upload Background</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={(e) => setSelectedBackgroundFile(e.target.files[0])}
                        />
                        <Button className="mt-2" onClick={uploadBackgroundHandler}>
                          Upload
                        </Button>
                      </Form.Group>
                      <Form.Group className="mt-3" controlId="siteBg">
                        <Form.Label>Background</Form.Label>
                        <Form.Control
                          as="select"
                          value={siteBg}
                          onChange={(e) => updateIsSelectedBackground(e.target.value)}
                        >
                          <option value="">Select Background</option>
                          {availableBackgrounds.map((bg, index) => (
                            <option key={index} value={bg.site_bg_url}>
                              {bg.site_bg_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Change Site Colors</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mt-3" controlId="paletteName">
                        <Form.Label>Site Domain</Form.Label>
                        <Form.Control
                          as="select"
                          value={selectedPaletteName}
                          onChange={handlePaletteNameChange}
                        >
                          <option value="">Select Palette</option>
                          {availableColors.map((palette, index) => (
                            <option key={index} value={palette.site_palette_name}>
                              {palette.site_palette_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      {selectedPaletteName && (
                        <>
                          <Form.Group className="mt-3" controlId="paletteColor">
                            <Form.Label>Pick A Colour</Form.Label>
                            <Form.Control
                              type="color"
                              value={selectedPaletteColor}
                              onChange={handlePaletteColorChange}
                            />
                          </Form.Group>
                          <Form.Group className="mt-3" controlId="hexInput">
                            <Form.Label>Or Enter Hex Value</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="#000000"
                              value={selectedPaletteColor}
                              onChange={handlePaletteColorChange}
                            />
                          </Form.Group>
                        
                          <Button className="mt-3" onClick={handleUpdateColor}>
                            Update Color
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>Contacts and Socials</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mt-3" controlId="siteContact">
                        <Form.Label>Contacts and Socials</Form.Label>
                        <Form.Control
                          as="select"
                          value={selectedContact}
                          onChange={(e) => updateIsSelectedContact(e.target.value)}
                        >
                          <option value="">Select One</option>
                          {availableContacts.map((contact, index) => (
                            <option key={index} value={contact.site_contact_name}>
                              {contact.site_contact_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      {selectedContact && (
                        <>
                          <Form.Group className="mt-3" controlId="contactUrl">
                            <Form.Label>URL</Form.Label>
                            <Form.Control
                              type="text"
                              value={selectedURL}
                              onChange={(e) => setSelectedURL(e.target.value)}
                            />
                          </Form.Group>
                          <Button className="mt-3" onClick={handleUpdateContact}>
                            Update Contact
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>Site Copy</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mt-3" controlId="siteCopy">
                        <Form.Label>Site Copy</Form.Label>
                        <Form.Control
                          as="select"
                          value={siteCopy}
                          onChange={(e) => updateIsSelectedSiteCopy(e.target.value)}
                        >
                          <option value="">Select Copy Section</option>
                          {availableSiteCopies.map((copy, index) => (
                            <option key={index} value={copy.site_copy_name}>
                              {copy.site_copy_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      {siteCopy && (
                        <>
                          <Form.Group className="mt-3" controlId="siteCopyContent">
                            <Form.Label>Edit Site Copy</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={10}
                              cols={75}
                              value={siteCopyContent}
                              onChange={(e) => setSiteCopyContent(e.target.value)}
                            />
                          </Form.Group>
                          <Button className="mt-3" onClick={handleUpdateSiteCopy}>
                            Update Site Copy
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="5">
                <Accordion.Header>Site Features</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mt-3" controlId="siteName">
                    <Form.Label>Site Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Site Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item> */}



              <Button className="mt-3" type="submit" variant="primary">
                Update
              </Button>
            </Form>
          </Accordion>
        </FormContainer>
      )}
    </>
  );
};

export default SiteSettings;
