const locations = [
  { city: 'Johannesburg', suburb: 'Sandton' },
  { city: 'Johannesburg', suburb: 'Randburg' },
  { city: 'Johannesburg', suburb: 'Roodepoort' },
  { city: 'Johannesburg', suburb: 'Midrand' },
  { city: 'Johannesburg', suburb: 'Fourways' },
  { city: 'Johannesburg', suburb: 'Bryanston' },
  { city: 'Johannesburg', suburb: 'Kempton Park' },
{city:'Tshwane',suburb:'Erasmuskloof'},
{city:'Tshwane',suburb:'Menlyn'},
{city:'Tshwane',suburb:'Centurion'},
{city:'Tshwane',suburb:'Silverton'},
{city:'Tshwane',suburb:'Erasmusrand'},
{city:'Tshwane',suburb:'Lynnwood'},
{city:'Tshwane',suburb:'Arcadia'},
{city:'Tshwane',suburb:'Hatfield'},
{city:'Tshwane',suburb:'Silver Lakes'},
{city:'Tshwane',suburb:'Shere'},
{city:'Tshwane',suburb:'Mooikloof'},
{city:'Tshwane',suburb:'Moreleta Park'},
{city:'Tshwane',suburb:'Garsfontein'},
{city:'Tshwane',suburb:'Faerie Glen'},
{city:'Tshwane',suburb:'Wapadrand'},
{city:'Tshwane',suburb:'Equestria'},
{city:'Tshwane',suburb:'Willow Park Manor'},
{city:'Tshwane',suburb:'Queenswood'},
{city:'Tshwane',suburb:'Villeria'},
{city:'Tshwane',suburb:'Montana'},
{city:'Tshwane',suburb:'Sinoville'},
{city:'Tshwane',suburb:'Annlin'},
{city:'Tshwane',suburb:'Doornpoort'},
{city:'Tshwane',suburb:'Wonderboom'},
{city:'Tshwane',suburb:'Wonderpark'},
{city:'Tshwane',suburb:'Karenpark'},
{city:'Tshwane',suburb:'Akasia'},
{city:'Tshwane',suburb:'Orchards'},
{city:'Tshwane',suburb:'Theresapark'},
{city:'Tshwane',suburb:'Chantelle'},
{city:'Tshwane',suburb:'Clarina'},
{city:'Tshwane',suburb:'Wolmer'},
{city:'Tshwane',suburb:'Rosslyn'},
{city:'Tshwane',suburb:'Soshanguve'},
{city:'Tshwane',suburb:'Mabopane'},
{city:'Tshwane',suburb:'Newlands'},
{city:'Cape Town',suburb:'City Bowl'},
{city:'Cape Town',suburb:'Atlantic Seaboard'},
{city:'Cape Town',suburb:'Constantia'},
{city:'Cape Town',suburb:'Sea Point'},
{city:'Cape Town',suburb:'Clifton'},
{city:'Cape Town',suburb:'Camps Bay'},
{city:'Cape Town',suburb:'Hout Bay'},
{city:'Cape Town',suburb:'Wynberg'},
{city:'Cape Town',suburb:'Newlands'},
{city:'Cape Town',suburb:'Pinelands'},
{city:'Cape Town',suburb:'Bellville'},
{city:'Cape Town',suburb:'Durbanville'},
{city:'Cape Town',suburb:'Kuils River'},
{city:'Cape Town',suburb:'Brackenfell'},
{city:'Cape Town',suburb:'Parow'},
{city:'Cape Town',suburb:'Goodwood'},
{city:'Cape Town',suburb:'Milnerton'},
{city:'Cape Town',suburb:'Blouberg'},
{city:'Cape Town',suburb:'Strand'},
{city:'Cape Town',suburb:'Somerset West'},
{city:'Cape Town',suburb:'Gordon’s Bay'},
{city:'Cape Town',suburb:'Stellenbosch'},
{city:'Cape Town',suburb:'Paarl'},
{city:'Cape Town',suburb:'Franschhoek'},
{city:'Durban',suburb:'Newlands East'},
{city:'Durban',suburb:'Newlands West'},
{city:'Durban',suburb:'Reservoir Hills'},
{city:'Durban',suburb:'Clare Hills'},
{city:'Durban',suburb:'Sydenham'},
{city:'Durban',suburb:'Overport'},
{city:'Durban',suburb:'Morningside'},
{city:'Durban',suburb:'Musgrave'},
{city:'Durban',suburb:'Berea'},
{city:'Durban',suburb:'Glenwood'},
{city:'Durban',suburb:'Umbilo'},
{city:'Durban',suburb:'Bluff'},
{city:'Durban',suburb:'Glenmore'},
{city:'Durban',suburb:'Bellair'},
{city:'Durban',suburb:'Montclair'},
{city:'Durban',suburb:'Woodlands'},
{city:'Durban',suburb:'Yellowwood Park'},
{city:'Durban',suburb:'Chatsworth'},
{city:'Durban',suburb:'Shallcross'},
{city:'Durban',suburb:'Queensburgh'},
{city:'Durban',suburb:'Malvern'},
{city:'Durban',suburb:'Escombe'},
{city:'Durban',suburb:'Northdene'},
  
];

export default locations;                         
