// src/components/Task.js
import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import {FaEllipsisH, FaTrash, FaEdit, FaFolder, FaLink, FaLine} from 'react-icons/fa'
import {Link, } from 'react-router-dom'
import {toast} from 'react-toastify'
import {useDeleteTaskMutation} from '../slices/tasksApiSlice'
import {useNavigate} from 'react-router-dom'
import Loader from './Loader'
import {Stack, Card, Nav } from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap'



function Task({ task, index }) {
  const [deleteTask, {isLoading: loadingDelete, refetch}] = useDeleteTaskMutation()
  const navigate = useNavigate()
  const deleteHandler = async(id) => {
    if (window.confirm('Are you sure you want to delete this task?')){
        try{
            await deleteTask(id)
            refetch()
            toast.success('Task deleted successfully')
            // navigate('/tasks')
        }catch(err){
            toast.error(err?.data?.message || err.error)
        }
    }
}
  return (
   
    <Draggable key={task._id} draggableId={task._id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className="task"
        >
       <Card key={task._id} className='p-1 mb-2' >
        <Card.Body>
          <Card.Title>{task.name}</Card.Title>
            <Card.Subtitle>{task.status}</Card.Subtitle>
              <Card.Text>{task.description}</Card.Text>
             {loadingDelete && <Loader/>}
             <Stack direction="horizontal" gap={3}> 
                <Nav.Link to="/admin/edit_task/"><FaEllipsisH/></Nav.Link>
                <Nav.Link to="/admin/tasks/"><FaTrash onClick={() => deleteHandler(task._id)}></FaTrash></Nav.Link>
                <LinkContainer to={`/edit/${task._id}`}><Nav.Link ><FaEdit/></Nav.Link></LinkContainer>  
                <Nav.Link to="/admin/edit_task/"><FaFolder/></Nav.Link>
                <Nav.Link to="/admin/edit_task/"><FaLink/></Nav.Link>
             </Stack>
          </Card.Body>
        </Card>
        </div>
      )}
    </Draggable>
  );
}

export default Task;
