import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const AddTaskModal = ({ show, handleClose, newTask, setNewTask, handleAddTask }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="taskType">
          <Form.Label>Task Type</Form.Label>
          <Form.Control
          type="text"
            placeholder="Enter task type"
            value={newTask.type}
            onChange={(e) => setNewTask({ ...newTask, type: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="taskName">
          <Form.Label>Task Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter task name"
            value={newTask.name}
            onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="taskCategory">
          <Form.Label>Task Category</Form.Label>
          <Form.Control
          type="text"
            placeholder="Enter task category"
            value={newTask.category}
            onChange={(e) => setNewTask({ ...newTask, category: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="taskDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter task description"
            value={newTask.description}
            onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="taskDuration">
          <Form.Label>Duration</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter task duration"
            value={newTask.duration}
            onChange={(e) => setNewTask({ ...newTask, duration: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="taskRate">
          <Form.Label>Rate</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter task rate"
            value={newTask.rate}
            onChange={(e) => setNewTask({ ...newTask, rate: e.target.value })}
          />
        </Form.Group>
       
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" onClick={handleAddTask}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTaskModal;
