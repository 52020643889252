import React, { useState } from "react";
import {
  Form,
  FloatingLabel,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import locations from "../data/locations";
import { saveServiceAddress } from "../slices/cartSlice";

const SearchBox = () => {
  const navigate = useNavigate();
  const { keyword: urlKeyword } = useParams();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const dispatch = useDispatch();



  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    //add a class when the search term is empty, to show field must be filled
    const filteredResults = locations.filter(
      (item) =>
        item.city.toLowerCase().includes(term) ||
        item.suburb.toLowerCase().includes(term)
    );
    setSearchResults(filteredResults);
  };

  const handleSelectOption = (city, suburb, e) => {
    console.log("Selected:", city, suburb);
    // Clear search term and results after selection
    setSearchTerm("");
    setSearchResults([]);
    console.log();
    setSearchTerm(suburb + ", " + city);
    //add selected suburb and city to cart
    dispatch(saveServiceAddress({ suburb: suburb, city: city }));
  };

  return (
    <Form className="my-3">
      <FloatingLabel
        controlId="floatingInput"
        label="Search and select your suburb"
        type="text"
      >
        <Form.Control
          //indicate to user that the field must be completed, warning message will be show,add text-danger class
          //remove the class after user selects an option
          style={{ borderColor: "red" }}
          className="is-invalid"
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          required
        />
      </FloatingLabel>

      {searchResults.length > 0 && (
        <ListGroup>
          {searchResults.map(({ city, suburb }, index) => (
            <ListGroupItem
              //add mouse pointer to list items
              style={{ cursor: "pointer" }}
              key={index}
              onClick={(e) => handleSelectOption(city, suburb, e)}
            >
              {suburb}, {city}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </Form>
  );
};

export default SearchBox;
