import {ORDERS_URL} from "../constants";
import {apiSlice} from "./apiSlice";


export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOrders: builder.query({
            query: () => ({
                url: ORDERS_URL,
            }),
            providesTags: ["Orders"],
            keepUnusedDataFor: 5,
        }),
        getOrderById: builder.query({
            query: (id) => ({
                url: `${ORDERS_URL}/${id}`,
            }),
        }),
        addOrderItems: builder.mutation({
            query: (data) => ({
                url: ORDERS_URL,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ["Orders"],
        }),
        updateOrderToPaid: builder.mutation({
            query: (id, data) => ({
                url: `${ORDERS_URL}/${id}/pay`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ["Orders"],
        }),
        updateOrderToCompleted: builder.mutation({
            query: (id) => ({
                url: `${ORDERS_URL}/${id}/complete`,
                method: 'PUT',
            }),
            invalidatesTags: ["Orders"],
        }),
        getPayFastPaymentIdentifier: builder.mutation({
            query: (id, data) => ({
                url: `${ORDERS_URL}/payfast/`,
                method: 'POST',
                body: data,
            }),
          
        }),
        //take in order_id, amount, email
        createYocoPayment: builder.mutation({
            query: ({order_id,amount}) => ({
              url: `${ORDERS_URL}/yoco/`,
              method: "POST",
              body: { order_id, amount },
            }),
          }),

    }),
});

export const {useGetOrdersQuery, useGetOrderByIdQuery, useAddOrderItemsMutation, useUpdateOrderToPaidMutation, useUpdateOrderToCompletedMutation, useGetPayFastPaymentIdentifierMutation,useCreateYocoPaymentMutation} = ordersApiSlice;
