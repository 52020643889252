import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Row, Col, FloatingLabel, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../components/FormContainer';
import Loader from '../components/Loader';
import { useLoginMutation, useResetForgotenPasswordMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';
import { FaArrowRight } from 'react-icons/fa';

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [login, { isLoading }] = useLoginMutation();
    const [resetForgotenPassword, {isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError }] = useResetForgotenPasswordMutation();
    const { userInfo } = useSelector((state) => state.auth);
    const { search } = useLocation();
    const sp = new URLSearchParams(search);
    const redirect = sp.get('redirect') || '/profile';

    useEffect(() => {
        if (userInfo) {
            navigate(redirect);
        }
    }, [navigate, redirect, userInfo]);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            const res = await login({ email, password }).unwrap();
            dispatch(setCredentials({ ...res }));
            navigate(redirect);
        } catch (err) {
            toast.error(err?.data?.message || err.error);
        }
    };

    const handleResetForgottenPassword = async () => {
        setEmailError(false); // Reset error state
        try {
            if (!email) {
                setEmailError(true); // Highlight the email field
                alert('Please enter your email address');
                return;
            }
            const res = await resetForgotenPassword({ email }).unwrap();
            alert(res.message || 'Please check your email for further instructions.');
        } catch (err) {
            setEmailError(true); // Highlight the email field
            toast.error(err?.data?.message || err.error || 'An error occurred.');
        }
    };

    return (
        <FormContainer title="Sign In">
            <Form onSubmit={submitHandler}>
                <Form.Group controlId="email" className="my-3">
                    <FloatingLabel controlId="floatingInput" label="Email Address" className="mb-3">
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={emailError ? 'is-invalid' : ''}
                        />
                    </FloatingLabel>
                    {emailError && <p className="text-danger mt-2">Please provide a valid email address.</p>}
                </Form.Group>
                <Form.Group controlId="password" className="my-3">
                    <FloatingLabel controlId="floatingInput" label="Password" className="mb-3">
                        <Form.Control
                            type="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FloatingLabel>
                </Form.Group>

                <button type="submit" disabled={isLoading} className="button-28">
                    Sign In <FaArrowRight />
                </button>
                {isLoading && <Loader />}
            </Form>
            <Row className="py-3">
                <Col>
                    New Customer? <Link to={redirect ? `/register?redirect=${redirect}` : '/register'} className="mx-2">Register</Link>
                    <Button variant="light" onClick={handleResetForgottenPassword}>Forgot Password?</Button>
                    {isUpdateError && <p className="text-danger mt-2">{updateError?.data?.message || 'Failed to reset password.'}</p>}
                    {isUpdateSuccess && <p className="text-success mt-2">Please check your email for further instructions.</p>}
                </Col>
               
            </Row>
        </FormContainer>
    );
};

export default LoginScreen;
