import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';

const Copy = ({ settings, isLoading, error }) => {
  return (
    <>
    {isLoading ? (
      <Loader />
    ) : error ? (
      <Message variant="danger">{error}</Message>
    ) : (
    <Card className="my-3 p-3 rounded">
      <div className="custom-card-header-about">
        <Stack direction="horizontal">
          <h3 className="text-center">Our Story</h3>
        </Stack>
      </div>
      <div className="my-2 p-2 card-subsection">
        {settings[0]?.site_copy
          .filter((copy) => copy.site_copy_name === 'about')
          .map((copy, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: copy.site_copy_text }} />
          ))}
      
      </div>
      <div className="custom-card-header-about">
        <h3>Vision</h3>
      </div>
      <div className="my-2 p-2 card-subsection">
        <p className="mb-3">
        {settings[0]?.site_copy
          .filter((copy) => copy.site_copy_name === 'vision')
          .map((copy, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: copy.site_copy_text }} />
          ))}
        </p>
      </div>
      <div className="custom-card-header-about">
        <h3>Mission</h3>
      </div>
      <div className="my-2 p-2 card-subsection">
        <p className="mb-3">
        {settings[0]?.site_copy
          .filter((copy) => copy.site_copy_name === 'mission')
          .map((copy, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: copy.site_copy_text }} />
          ))}
        </p>
      </div>
   
    </Card>
  )}
    </>
  );
};

export default Copy;
