import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const renderTooltip = (props, description, title) => (
  <Tooltip id="button-tooltip" {...props}>
    <strong>Service Tasks</strong>
    <br />
    {description}
  </Tooltip>
);

const ServicesCard = ({ service }) => {
  const navigate = useNavigate();
  const handleBookNow = (e) => {
    if (e.target.id === "General Pest Control") {
      navigate("/orderform/pestcontrol");
    } else if (e.target.id === "Cleaning Services") {
      navigate("/orderform/cleaning");
    }
  };

  return (
    <Card className="my-3 p-3 rounded" as="div">
      <Card.Body>
        <div className="custom-card-header">
          <Card.Title as="h3" className="text-center">
            {service.category}
          </Card.Title>
        </div>

        {service.component.map((comp, index) => (
          <Card.Text className="my-3 card-subsection" key={index}>
            <OverlayTrigger

              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) =>
                renderTooltip(
                  props,
                  comp.tasks.map((task) => task.name).join(", ")
                )
              }
            >
              <div>
                <strong>
                  <h5>{comp.name}</h5>
                </strong>
                <ul>
                  {comp.description.split(",").map((desc, i) => (
                    <li key={i}>{desc}</li>
                  ))}
                </ul>
              </div>
            </OverlayTrigger>
          </Card.Text>
        ))}
      </Card.Body>

      {/* <Button
        className="button-28"
        id={service.category}
        onClick={handleBookNow}
      >
        Book Now <FaArrowRight />
      </Button> */}
    </Card>
  );
};

export default ServicesCard;
