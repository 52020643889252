// src/components/Column.js
import React from 'react';
import { Droppable } from '@hello-pangea/dnd';
import Task from './Task';
import { FaEllipsisH,FaPlusCircle } from 'react-icons/fa';
import { Stack,Nav} from 'react-bootstrap' 
import {LinkContainer} from 'react-router-bootstrap'

function Column({ title, tasks, column }) {
  return (
    <Droppable droppableId={column} type="TASK">
    {(provided) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        style={{ margin: '0 5px', padding: '5px', border: '1px solid grey', height: '100%' }}
      >
        <div class="custom-task-card-header">
          <Stack direction="horizontal" gap={4}> 
            <h5 className="text-center">{title}</h5>
            <LinkContainer  to="/add_task/"><Nav.Link className="p-2 ms-auto"><FaPlusCircle/></Nav.Link></LinkContainer>
            {/* <Nav.Link to="/edit_task/"><FaEllipsisH/></Nav.Link> */}
          </Stack>
        </div>
        {tasks.map((task, index) => (
          <Task key={task._id} task={task} index={index} />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
  );
}
export default Column;
