import React, { useState } from 'react';
import { Modal, Form, Button, ListGroup } from 'react-bootstrap';

const AddCategoryModal = ({ show, handleClose, newCategory, setNewCategory, handleAddCategory }) => {
  const [components, setComponents] = useState([]);
  const [componentName, setComponentName] = useState('');
  const [componentDescription, setComponentDescription] = useState('');
  const [componentTasks, setComponentTasks] = useState([]);
  const [componentBilling, setComponentBilling] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [billingType, setBillingType] = useState('');
  const [billingRate, setBillingRate] = useState('');

  const handleAddComponent = () => {
    if (componentName && componentDescription) {
      setComponents([...components, { name: componentName, description: componentDescription, tasks: componentTasks, billing: componentBilling }]);
      setComponentName('');
      setComponentDescription('');
      setComponentTasks([]);
      setComponentBilling([]);
    }
  };

  const handleAddTask = () => {
    if (taskName && taskDescription) {
      setComponentTasks([...componentTasks, { name: taskName, description: taskDescription }]);
      setTaskName('');
      setTaskDescription('');
    }
  };

  const handleAddBilling = () => {
    if (billingType && billingRate) {
      setComponentBilling([...componentBilling, { type: billingType, rate: billingRate }]);
      setBillingType('');
      setBillingRate('');
    }
  };

  const handleRemoveComponent = (index) => {
    setComponents(components.filter((_, i) => i !== index));
  };

  const handleSaveCategory = () => {
    handleAddCategory({ ...newCategory, components });
    console.log({ ...newCategory, components });
  };

 

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Service Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="categoryName">
          <Form.Label>Category Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter category name"
            value={newCategory.category}
            onChange={(e) => setNewCategory({ ...newCategory, category: e.target.value })}
          />
        </Form.Group>

        <hr />

        <h5>Components</h5>
        <ListGroup>
          {components.map((component, index) => (
            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
              <div>
                <strong>{component.name}</strong>: {component.description}
                <ul>
                  <li><strong>Tasks:</strong></li>
                  {component.tasks.map((task, idx) => (
                    <li key={idx}>{task.name}: {task.description}</li>
                  ))}
                </ul>
                <ul>
                  <li><strong>Billing:</strong></li>
                  {component.billing.map((bill, idx) => (
                    <li key={idx}>{bill.type}: {bill.rate}</li>
                  ))}
                </ul>
              </div>
              <Button variant="danger" size="sm" onClick={() => handleRemoveComponent(index)}>
                Remove
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>

        <Form.Group controlId="componentName" className="mt-3">
          <Form.Label>Component Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter component name"
            value={componentName}
            onChange={(e) => setComponentName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="componentDescription" className="mt-2">
          <Form.Label>Component Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter component description"
            value={componentDescription}
            onChange={(e) => setComponentDescription(e.target.value)}
          />
        </Form.Group>
        <Button className="mt-2" onClick={handleAddComponent}>
          Add Component
        </Button>

        <hr />

        <h5>Tasks</h5>
        <Form.Group controlId="taskName">
          <Form.Label>Task Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter task name"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="taskDescription" className="mt-2">
          <Form.Label>Task Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter task description"
            value={taskDescription}
            onChange={(e) => setTaskDescription(e.target.value)}
          />
        </Form.Group>
        <Button className="mt-2" onClick={handleAddTask}>
          Add Task
        </Button>

        <hr />

        <h5>Billing</h5>
        <Form.Group controlId="billingType">
          <Form.Label>Billing Type</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter billing type"
            value={billingType}
            onChange={(e) => setBillingType(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="billingRate" className="mt-2">
          <Form.Label>Billing Rate</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter billing rate"
            value={billingRate}
            onChange={(e) => setBillingRate(e.target.value)}
          />
        </Form.Group>
        <Button className="mt-2" onClick={handleAddBilling}>
          Add Billing
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" onClick={handleSaveCategory}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCategoryModal;
