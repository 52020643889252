import {useState, useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Form, Button} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {useGetUserDetailsQuery,useUpdateUserProfileMutation} from '../../slices/usersApiSlice'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import FormContainer from '../../components/FormContainer'
import { FaArrowRight,FaEnvelope} from 'react-icons/fa';



const UserEditScreen = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)
    const {data:user, isLoading, refetch, error} = useGetUserDetailsQuery(id)
    const [updateUser, {isLoading: loadingUpdate}] = useUpdateUserProfileMutation()
    console.log(user)

    const submitHandler = async(e) => {
        e.preventDefault()
        try{
            await updateUser({id, name, email, isAdmin})
            toast.success('User updated successfully')
            navigate('/admin/userlist/')
        }catch(err){
            toast.error(err?.data?.message || err.error)
        }
    }

    useEffect(() => {
        if(user){
            setName(user.name)
            setEmail(user.email)
            setIsAdmin(user.isAdmin)
        }
    },[user])


  return (
    <>
    <Link to='/admin/userlist' className='btn btn-light my-3'>Go Back</Link>
    <FormContainer>
        <h1>Edit User</h1>
        {loadingUpdate && <Loader/>}
        {isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> : 
        
        <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control type='name' placeholder='Enter name' value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
            </Form.Group>
            <Form.Group controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control type='email' placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
            </Form.Group>
            <Form.Group controlId='isAdmin'>
                <Form.Check type='checkbox' label='Is Admin' checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)}></Form.Check>
            </Form.Group>
            <button type='submit' disabled={isLoading} 
                className='button-28'> 
                Sumbit Enquiry <FaArrowRight/>
            </button>  
        </Form>
        
        }
        
    </FormContainer>


    </>
  )
}

export default UserEditScreen