
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import {useGetBMCQuery} from '../../slices/bmcApiSlice'
import { Container, Row, Col, Card } from 'react-bootstrap'
import {Link} from 'react-router-dom'

const BmcListScreen = () => {
    const {data:canvas, isLoading, error} = useGetBMCQuery()
    console.log(canvas)
    
    return (
      <>
        {isLoading ? (<Loader/>) :  error ? (<Message variant='danger'>{error?.data?.message || error.error }</Message>):
        (
          <>
            <Container className='p-4'>
            <Link to='/admin/bmc/tasks' className='btn btn-dark my-3'>Tasks</Link>    
            <Link to='/admin/userlist' className='btn btn-dark my-3'>Notes</Link>    
            <h3 className='text-center'>Business Model Canvas</h3>    
            <Row>
                {canvas.map((bmc, index) => {
                    
                    return (
                    <>
                       {bmc.company}
                        {bmc.bmc_blocks.map((block, index) => {
                        return (
                            <Col key={block._id } sm={12} md={6} lg={4} xl={3}>
                                <Card  className="m-2" class="card border-1 bg-secondary">
                                <Card.Body class="card-body">
                                    <Card.Title class="card-title"><strong>{index +1}: {block.block_name}</strong></Card.Title>
                                    {/* <Card.Text>
                                    {block.block_description}
                                    </Card.Text> */}
                                    TASKS:  <span class="badge bg-warning">{block.block_tasks.length}</span>
                                    <ul class="list-group list-group-flush mb-4">
                                         
                                    {block.block_tasks.map((task, index) => {
                                        return (
                                            <Card.Text>
                                            <li> {task.task_name}</li>  
                                            </Card.Text>
                                            )}
                                        )}
                                        </ul>
                                     NOTES:<span class="badge bg-success">{block.block_notes.length}</span>     
                                    <ul class="list-group list-group-flush mb-4">
                                    {block.block_notes.map((note, index) => {
                                        return (
                                            <Card.Text>
                                              <li class>{note.note_name}</li>
                                            </Card.Text>
                                            )}
                                        )

                                    }
                                    </ul>
                                </Card.Body>
                                </Card>
                            </Col>
                           
                            );
                        })}
                    </>
                    );
                })} 
            </Row>


        </Container>
         </>
        )}
        </>
    )
}        
export default BmcListScreen


