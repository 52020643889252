import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  Form,
  Card,
  Button,
  Row,
  Col,
  Accordion,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useGetUserDetailsQuery } from "../slices/usersApiSlice";
import {
  useUpdateUserProfileMutation,
  useUploadUserAvatarMutation,
} from "../slices/usersApiSlice";
import { useGetOrdersQuery } from "../slices/ordersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { useGetUsersQuery } from "../slices/usersApiSlice";
import CurriculumVitaeForm from "../components/forms/CurriculumVitae";
import TaskGrid from "../components/TaskGrid";

const ProfileScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [image, setImage] = useState("");

  const { userInfo } = useSelector((state) => state.auth);
  const { data: orders, isLoading, error } = useGetOrdersQuery();
  const [updateProfile, { isLoading: loadingUpdateProfile }] =
    useUpdateUserProfileMutation();
  const {
    data: user,
    isLoading: loading,
    error: errorUser,
  } = useGetUserDetailsQuery(userInfo._id);
  const [uploadUserAvatar] = useUploadUserAvatarMutation();
  const { data: users ,isLoading:loadingAllUsers, error:errorAllUsers} = useGetUsersQuery();

  useEffect(() => {
    setName(userInfo.name);
    setEmail(userInfo.email);
  }, [userInfo.email, userInfo.name]);

  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        const res = await updateProfile({
          // NOTE: here we don't need the _id in the request payload as this is
          // not used in our controller.
          id: userInfo._id,
          name,
          email,
          password,
          image,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        toast.success("Profile updated successfully");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const handleAvatarChange = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadUserAvatar(formData).unwrap();
      toast.success(res.message);
      submitHandler({ ...formData, image: res.image });
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };


  const formGroupTemplate = (label, controlId, type, value, onChange) => (
    <Form.Group className="my-2" controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={`Enter ${label}`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      ></Form.Control>
    </Form.Group>
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : errorUser ? (
        <Message variant="danger">
          {errorUser?.data?.message || errorUser.error}
        </Message>
      ) : loadingAllUsers ? (
        <Loader />
      ) : errorAllUsers ? (
        <Message variant="danger">
          {errorAllUsers?.data?.message || errorAllUsers.error}
        </Message>
      ) : (
        <Container fluid
       
        >
          <Row>
            <Col>
              {user && user.isEmployee ? (
                user.curriculumVitae ? (
                  <Card className="my-3 p-3 rounded" as="div">
                    <TaskGrid user_tasks={user.email} />
                    <h2>Curriculum Vitae - {user.name}</h2>
                    <CurriculumVitaeForm user={user} />{" "}
                    {/*nested if isEmployee and has a CV let them update it*/}
                  </Card>
                ) : (
                  <Card className="my-3 p-3 rounded" as="div">
                    <h2>Curriculum Vitae - {user.name}</h2>
                    <p>Complete your CV and Submit For Processing</p>
                    <CurriculumVitaeForm user={user} />
                    <TaskGrid user_tasks={user.email} />
                  </Card>
                ) //nested if isEmployee but doesnt have a CV let them complete a blank form
              ) : user && user.isEmployer ? (
                <Card className="my-3 p-3 rounded" as="div">
                  <p>Return Employer Tasks</p>
                  <TaskGrid user_tasks={user.email} />
                </Card>
              ) : //  fetch the employer task list from mongodb
              //  map through the list and display each task as a card on kanban

              user && user.isAdmin ? (
                <Card className="my-3 p-3 rounded" as="div">
                 

                  <TaskGrid user_tasks={user.email} />
                  <h2>Curriculum Vitae - {user.name}</h2>
                  <p>Admins do not have a CV</p>
                </Card>
              ) : user && user.isCustomer ? (
                <>
                  <h2>My Orders</h2>
                  {isLoading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">
                      {error?.data?.message || error.error}
                    </Message>
                  ) : (
                    <Table striped hover responsive className="table-sm">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>DATE</th>
                          <th>TOTAL</th>
                          <th>PAID</th>
                          <th>DELIVERED</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((order) => (
                          <tr key={order._id}>
                            <td>{order._id}</td>
                            <td>{order.createdAt.substring(0, 10)}</td>
                            <td>{order.totalPrice}</td>
                            <td>
                              {order.isPaid ? (
                                order.paidAt.substring(0, 10)
                              ) : (
                                <FaTimes style={{ color: "red" }} />
                              )}
                            </td>
                            <td>
                              {order.isDelivered ? (
                                order.deliveredAt.substring(0, 10)
                              ) : (
                                <FaTimes style={{ color: "red" }} />
                              )}
                            </td>
                            <td>
                              <LinkContainer to={`/order/${order._id}`}>
                                <Button className="btn-sm" variant="light">
                                  Details
                                </Button>
                              </LinkContainer>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </>
              ) : null}
            </Col>

            <Col md={3}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {" "}
                    <h3>Update User Profile</h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Card className="my-3 p-3 rounded" as="div">
                      <Form onSubmit={submitHandler}>
                        {formGroupTemplate(
                          "Name",
                          "name",
                          "text",
                          name,
                          setName
                        )}
                        {formGroupTemplate(
                          "Email",
                          "email",
                          "email",
                          email,
                          setEmail
                        )}
                        {formGroupTemplate(
                          "Password",
                          "password",
                          "password",
                          password,
                          setPassword
                        )}
                        {formGroupTemplate(
                          "Confirm Password",
                          "confirmPassword",
                          "password",
                          confirmPassword,
                          setConfirmPassword
                        )}
                        {/* Add img, and file upload fields, if user is admin or employee */}
                        {user && (user.isEmployee || user.isAdmin) && (
                          <Form.Group className="my-2" controlId="image">
                            <Form.Label>Image</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={image}
                              value={image}
                              onChange={(e) =>
                                handleAvatarChange(e.target.value)
                              }
                            ></Form.Control>
                            {/* Add image preview */}
                            <img
                              src={image}
                              alt="avatar"
                              style={{ width: "100px" }}
                            />

                            <Form.Control
                              type="file"
                              id="image-file"
                              label="Choose File"
                              onChange={(e) => handleAvatarChange(e)}
                              custom
                            ></Form.Control>
                          </Form.Group>
                        )}

                        <button type="submit" className="button-28">
                          Update
                        </button>
                        {loadingUpdateProfile && <Loader />}
                      </Form>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ProfileScreen;
