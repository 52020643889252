import {Row,Col,ListGroup,} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {useGetProductDetailsQuery} from '../slices/productsApiSlice'
import Loader from "../components/Loader";

const ProductDetailScreen = () => {
    const { id:productId } = useParams()
    const {data: product, isLoading, error} = useGetProductDetailsQuery(productId)
    
  return (
    <>
      {isLoading ? (<Loader/>) : 
      error ? (<div>{error?.data?.message || error.error }</div>): 
      (<>
        <Row>
            <Col md={6}>
            {/* <Image src={product.image} alt={product.name} fluid/> */}
            </Col>
            <Col md={3}>
            <ListGroup variant='flush'>
                <ListGroup.Item>
                <h3>{product.name}</h3>
                </ListGroup.Item>
            </ListGroup>
            </Col>
        </Row>
      </>)}
      
    </>
  );
}

export default ProductDetailScreen;
