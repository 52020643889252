import { Alert } from "react-bootstrap"

const Message = ({variant, children}) => {
  return (
    <Alert variant={variant}>
      {children}
    </Alert>
  )
}
Message.apply.defaultProps = {
    variant: 'info'
    }
    
export default Message
