import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const AddComponentModal = ({ show, handleClose, newComponent, setNewComponent, handleAddComponent }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Component</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="componentName">
          <Form.Label>Component Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter component name"
            value={newComponent.name}
            onChange={(e) => setNewComponent({ ...newComponent, name: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="componentDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter component description"
            value={newComponent.description}
            onChange={(e) => setNewComponent({ ...newComponent, description: e.target.value,  })}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" onClick={handleAddComponent}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddComponentModal;
