import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  Header,
  WidthType,
  AlignmentType,
  ShadingType,
  ImageRun,
  TableOfContents,
  HeadingLevel,
  Footer,
  PageNumber,
  TabStopType,
  TabStopPosition,
  VerticalAlign,
} from "docx";
import { saveAs } from "file-saver";
import { styles, numbering, footers } from "./generator/WordDoc.js";
import cover from "../assets/img/cover.jpg";

const GenerateRFQResponseDoc = async ({
  salesLead,
  settings,
  services,
  logoBase64,
}) => {
  try {
    // Convert cover image to base64
    const fetchCoverImage = async () => {
      try {
        const response = await fetch(cover);
        const blob = await response.blob();
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error("Failed to fetch the cover image:", error);
        throw error;
      }
    };

    // Function to process settings array
    const processSettingsArray = (siteCopyName) => {
      const siteCopyItem = settings[0].site_copy.find(
        (copy) => copy.site_copy_name === siteCopyName
      );
      const stripHtml = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        return doc.body.textContent || "";
      };
      const strippedSiteCopyText = siteCopyItem
        ? stripHtml(siteCopyItem.site_copy_text)
        : "";
      const cleanedText = strippedSiteCopyText
        .replace(/\s+/g, " ")
        .replace(/\. +/g, ". ");

      return cleanedText;
    };

    // Convert site HTML Sections to text
    const siteHTMLtoText = (site_copy_name) => {
      const getHTML =
        settings[0]?.site_copy.find(
          (item) => item.site_copy_name === site_copy_name
        )?.content || "";
      const section = new DOMParser().parseFromString(getHTML, "text/html");
      return section.body.textContent || "";
    };

    // Prepare service pages content
    const servicePages = services
      ?.map((service) => {
        const category = new Paragraph({
          text: service.category,
          heading: HeadingLevel.HEADING_1,
          numbering: {
            reference: "rfq-response-numbering",
            level: 0,
          },
        });

        const components = service.component.map((comp) => [
          new Paragraph({
            text: comp.name,
            heading: HeadingLevel.HEADING_2,
            numbering: {
              reference: "rfq-response-numbering",
              level: 1,
            },
          }),
          new Paragraph({
            text: comp.description,
            numbering: {
              reference: "rfq-response-numbering",
              level: 2,
            },
          }),
        ]);

        return [category, ...components.flat()];
      })
      .flat();

    const coverImageBase64 = await fetchCoverImage();
    const doc = new Document({
      title: "RFQ Response",
      numbering: numbering,
      styles: styles,

      sections: [
        // Cover Page Section
        {
          properties: { type: "cover" },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `${salesLead.name}`,
                  bold: true,
                  size: 48,
                  break: 1,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new ImageRun({
                  data: coverImageBase64,
                  transformation: {
                    width: 550,
                    height: 800,
                  },
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              break: 2,
              text: `Date: ${new Date().toLocaleDateString()}`,
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              text: `${salesLead.message}`,
              alignment: AlignmentType.CENTER,
              bold: true,
            }),
          ],
        },
        // Table of Contents Section
        {
          properties: {},
          children: [
            new Paragraph({
              text: "Table of Contents",
              heading: HeadingLevel.HEADING_1,
              style: "Heading1",
            }),
            new Paragraph({ text: "", break: 1 }), // Add a break to ensure space before TOC
            new TableOfContents({
              headingStyleRange: "1-3", // Specify the range of heading levels to include
            }),
            new Paragraph({ text: "", break: 1 }), // Add a break after TOC
          ],
        },

        // Main Content Section
        {
          headers: {
            default: createHeader(logoBase64, settings), // Pass settings here
          },
          footers: {
            default: createFooter(salesLead),
          },
          children: [
            new Paragraph({
              text: "PROFILE:",
              heading: HeadingLevel.HEADING_1,
              bold: true,
              numbering: {
                reference: "rfq-response-numbering",
                level: 0,
              },
            }),
            new Paragraph({ text: "", break: 1 }), // Line break

            new Paragraph({
              text: "Introduction",
              heading: HeadingLevel.HEADING_2,
              numbering: {
                reference: "rfq-response-numbering",
                level: 1,
              },
            }),
            new Paragraph({
              text: processSettingsArray("about"),
              numbering: {
                reference: "rfq-response-numbering",
                level: 2,
              },
            }),
            new Paragraph({ text: "", break: 1 }), // Line break

            new Paragraph({
              text: "Mission",
              heading: HeadingLevel.HEADING_2,
              numbering: {
                reference: "rfq-response-numbering",
                level: 1,
              },
            }),
            new Paragraph({
              text: processSettingsArray("mission"),
              numbering: {
                reference: "rfq-response-numbering",
                level: 2,
              },
            }),
            new Paragraph({ text: "", break: 1 }), // Line break

            new Paragraph({
              text: "Vision",
              heading: HeadingLevel.HEADING_2,
              numbering: {
                reference: "rfq-response-numbering",
                level: 1,
              },
            }),
            new Paragraph({
              text: processSettingsArray("vision"),
              numbering: {
                reference: "rfq-response-numbering",
                level: 2,
              },
            }),
            new Paragraph({ text: "", break: 1 }), // Line break

            ...servicePages,

            new Paragraph({
              text: "PRICING SCHEDULE:",
              heading: HeadingLevel.HEADING_2,
              numbering: {
                reference: "rfq-response-numbering",
                level: 0,
              },
              pageBreakBefore: true,
            }),
            new Paragraph({ text: "", break: 1 }), // Line break

            // Conditionally add Products Table
            ...(salesLead.products && salesLead.products.length > 0
              ? [
                  new Paragraph({
                    text: "Products",
                    heading: HeadingLevel.HEADING_2,
                    numbering: {
                      reference: "rfq-response-numbering",
                      level: 1,
                    },
                  }),
                  await createProductsTable(salesLead.products),
                  new Paragraph({ text: "", break: 1 }), // Line break
                ]
              : []),

            // Conditionally add Services Table
            ...(services && services.length > 0
              ? [
                  new Paragraph({
                    text: "Services",
                    heading: HeadingLevel.HEADING_2,
                    numbering: {
                      reference: "rfq-response-numbering",
                      level: 1,
                    },
                  }),
                  await createServicesTable(services),
                ]
              : []),
          ],
        },
      ],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, `${salesLead.name}.docx`);
  } catch (error) {
    console.error("Error generating document", error);
  }
};


const createHeader = (logoBase64, settings) => {
  const filteredContacts = settings[0].site_contacts
    .filter(
      (contact) =>
        contact.site_contact_name === "email" ||
        contact.site_contact_name === "phone" ||
        contact.site_contact_name === "whatsapp" ||
        contact.site_contact_name === "website"
    )
    .map((contact) => {
      return new Paragraph({
        children: [
          new TextRun({
            text: ` ${contact.site_contact_url}`,
            bold: true,
            size: 14,
            break: 1,
          }),
        ],
        alignment: AlignmentType.RIGHT,
      });
    });

  return new Header({
    children: [
      new Table({
        width: { size: 100, type: WidthType.PERCENTAGE },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                width: { size: 30, type: WidthType.PERCENTAGE },
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: logoBase64,
                        transformation: {
                          width: 100,
                          height: 100,
                        },
                      }),
                    ],
                    alignment: AlignmentType.LEFT,
                  }),
                ],
                borders: {
                  top: { size: 0, color: "#ffffff" },
                  bottom: { size: 0, color: "#ffffff" },
                  left: { size: 0, color: "#ffffff" },
                  right: { size: 0, color: "#ffffff" },
                },
              }),
              new TableCell({
                width: { size: 70, type: WidthType.PERCENTAGE },
                children: filteredContacts,
                verticalAlign: "center",
                borders: {
                  top: { size: 0, color: "#ffffff" },
                  bottom: { size: 0, color: "#ffffff" },
                  left: { size: 0, color: "#ffffff" },
                  right: { size: 0, color: "#ffffff" },
                },
              }),
            ],
          }),
        ],
        borders: {
          top: { size: 0, color: "#ffffff" },
          bottom: { size: 0, color: "#ffffff" },
          left: { size: 0, color: "#ffffff" },
          right: { size: 0, color: "#ffffff" },
        },
      }),
    ],
  });
};
const createFooter = (salesLead) => {
  return new Footer({
    children: [
      new Table({
        width: { size: 100, type: WidthType.PERCENTAGE },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                width: { size: 25, type: WidthType.PERCENTAGE },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${salesLead.name}`,
                        bold: true,
                        size: 16, // This size is in half-points, so 20 means 10pt
                      }),
                    ],
                    alignment: AlignmentType.VERTICAL_CENTER,
                  }),
                ],
              }),
              new TableCell({
                width: { size: 75, type: WidthType.PERCENTAGE },
                VerticalAlign: VerticalAlign.CENTER,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${salesLead.message}`,
                        bold: true,
                        size: 16, // This size is in half-points, so 20 means 10pt
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                width: { size: 100, type: WidthType.PERCENTAGE },
                VerticalAlign: VerticalAlign.CENTER,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Page ",
                        bold: true,
                        size: 18,
                      }),
                      new TextRun({
                        children: [PageNumber.CURRENT],
                        field: true,
                        bold: true,
                        size: 18,
                      }),
                      new TextRun({
                        text: " of ",
                        bold: true,
                        size: 18,
                      }),
                      new TextRun({
                        children: [PageNumber.TOTAL_PAGES],
                        field: true,
                        bold: true,
                        size: 18,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                    tabStops: [
                      {
                        type: TabStopType.RIGHT,
                        position: TabStopPosition.MAX,
                      },
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
};

const createProductsTable = async (products) => {
  const headerRow = new TableRow({
    children: [
      new TableCell({
        width: { size: 20, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({
            text: "NO",
            allCaps: true,
          }),
        ],
        shading: { type: ShadingType.SOLID, color: "#fa9000" },
      }),
      new TableCell({
        width: { size: 40, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({
            text: "DESCRIPTION",
            allCaps: true,
          }),
        ],
        shading: { type: ShadingType.SOLID, color: "#fa9000" },
      }),
      new TableCell({
        width: { size: 10, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({
            text: "QUANTITY",
            allCaps: true,
          }),
        ],
        shading: { type: ShadingType.SOLID, color: "#fa9000" },
      }),
      new TableCell({
        width: { size: 10, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({
            text: "UOM",
            allCaps: true,
          }),
        ],
        shading: { type: ShadingType.SOLID, color: "#fa9000" },
      }),
      new TableCell({
        width: { size: 20, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({
            text: "PRICE",
            allCaps: true,
          }),
        ],
        shading: { type: ShadingType.SOLID, color: "#fa9000" },
      }),
    ],
  });

  const dataRows = products.map(
    (product, index) =>
      new TableRow({
        children: [
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: product.no.toString(),
              }),
            ],
            shading:
              index % 2 === 0
                ? { type: ShadingType.SOLID, color: "#D3D3D3" }
                : {},
          }),
          new TableCell({
            width: { size: 40, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: product.description,
              }),
            ],
            shading:
              index % 2 === 0
                ? { type: ShadingType.SOLID, color: "#D3D3D3" }
                : {},
          }),
          new TableCell({
            width: { size: 10, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: product.quantity.toString(),
              }),
            ],
            shading:
              index % 2 === 0
                ? { type: ShadingType.SOLID, color: "#D3D3D3" }
                : {},
          }),
          new TableCell({
            width: { size: 10, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: product.uom,
              }),
            ],
            shading:
              index % 2 === 0
                ? { type: ShadingType.SOLID, color: "#D3D3D3" }
                : {},
          }),
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: product.price.toFixed(2),
              }),
            ],
            shading:
              index % 2 === 0
                ? { type: ShadingType.SOLID, color: "#D3D3D3" }
                : {},
          }),
        ],
      })
  );

  const subTotal = products.reduce(
    (acc, product) => acc + product.price * product.quantity,
    0
  );

  const vat = subTotal * 0.15;
  const total = subTotal + vat;

  const totalsRow = new TableRow({
    children: [
      new TableCell({
        width: { size: 80, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({
            text: "Sub-Total",
            bold: true,
            color: "#fa9000",
            shading: { type: ShadingType.SOLID, color: "#f3f3f3" },
          }),
          new Paragraph({ text: "", break: 1 }), // Line break

          new Paragraph({
            text: "VAT @15%",
            bold: true,
            color: "#fa9000",
            shading: { type: ShadingType.SOLID, color: "#f3f3f3" },
          }),
          new Paragraph({ text: "", break: 1 }), // Line break

          new Paragraph({
            text: "TOTAL",
            bold: true,
            color: "#fa9000",
            shading: { type: ShadingType.SOLID, color: "#f3f3f3" },
          }),
        ],
        columnSpan: 4,
        shading: { type: ShadingType.SOLID, color: "#f3f3f3" },
      }),
      new TableCell({
        width: { size: 20, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({
            text: subTotal.toFixed(2),
            bold: true,
            color: "#fa9000",
            shading: { type: ShadingType.SOLID, color: "#f3f3f3" },
          }),
          new Paragraph({ text: "", break: 1 }), // Line break
          new Paragraph({
            text: vat.toFixed(2),
            bold: true,
            color: "#fa9000",
            shading: { type: ShadingType.SOLID, color: "#f3f3f3" },
          }),
          new Paragraph({ text: "", break: 1 }), // Line break
          new Paragraph({
            text: total.toFixed(2),
            bold: true,
            color: "#fa9000",
            shading: { type: ShadingType.SOLID, color: "#f3f3f3" },
          }),
        ],
        shading: { type: ShadingType.SOLID, color: "#f3f3f3" },
      }),
    ],
  });

  const table = new Table({
    rows: [headerRow, ...dataRows, totalsRow],
    width: { size: 100, type: WidthType.PERCENTAGE },
  });

  return table;
};

const createServicesTable = async (services) => {
  const headerRow = new TableRow({
    children: [
      new TableCell({
        width: { size: 20, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({
            text: "NO",
            allCaps: true,
          }),
        ],
        shading: { type: ShadingType.SOLID, color: "#fa9000" },
      }),
      new TableCell({
        width: { size: 80, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({
            text: "DESCRIPTION",
            allCaps: true,
          }),
        ],
        shading: { type: ShadingType.SOLID, color: "#fa9000" },
      }),
    ],
  });

  const dataRows = services.map((service, index) => {
    // Assign a sequential number if no is undefined
    const serviceNo = service.no || (index + 1).toString();
    return new TableRow({
      children: [
        new TableCell({
          width: { size: 20, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: serviceNo,
            }),
          ],
          shading:
            index % 2 === 0
              ? { type: ShadingType.SOLID, color: "#D3D3D3" }
              : {},
        }),
        new TableCell({
          width: { size: 80, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: service.description,
            }),
          ],
          shading:
            index % 2 === 0
              ? { type: ShadingType.SOLID, color: "#D3D3D3" }
              : {},
        }),
      ],
    });
  });

  const subTotal = services.reduce(
    (acc, service) => acc + service.price * (service.quantity || 0),
    0
  );
  const vat = subTotal * 0.15;
  const total = subTotal + vat;

  const totalsRow = new TableRow({
    children: [
      new TableCell({
        width: { size: 80, type: WidthType.PERCENTAGE },
        children: [
          new TextRun({
            text: "Sub-Total",
            bold: true,
          }),
          new Paragraph({ text: "", break: 1 }), // Line break
          new TextRun({
            text: "VAT @15%",
            bold: true,
          }),
          new Paragraph({ text: "", break: 1 }), // Line break
          new TextRun({
            text: "TOTAL",
            bold: true,
          }),
        ],
        columnSpan: 1,
      }),
      new TableCell({
        width: { size: 20, type: WidthType.PERCENTAGE },
        children: [
          new TextRun({
            text: subTotal.toFixed(2),
            bold:true,
          }),
          new Paragraph({ text: "", break: 1 }), // Line break
          new TextRun({
            text: vat.toFixed(2),
            bold:true,
          }),
          new Paragraph({ text: "", break: 1 }), // Line break
          new TextRun({
            text: total.toFixed(2),
            bold:true,
          }),
        ],
      }),
    ],
  });

  const table = new Table({
    rows: [headerRow, ...dataRows, totalsRow],
    width: { size: 100, type: WidthType.PERCENTAGE },
  });

  return table;
};

export default
 GenerateRFQResponseDoc;
