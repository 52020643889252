import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { userInfo } = useSelector((state) => state.auth);
  if (!userInfo) {
    return <Navigate to="/signin" />;
  }

  return userInfo ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoute;
