import React, { useEffect, useState } from "react";
import { Form, FloatingLabel, Row, Col } from "react-bootstrap";
import FormColumnContainer from "../components/FormColumnContainer";
import { useGetServicesQuery } from "../slices/servicesApiSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import ratecard from "../data/ratecard";
import CleaningComponents from "../components/CleaningComponents";
import PestComponents from "../components/PestComponents";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "../components/SearchBox";

// creating functional component ans getting props from app.js and destucturing them
const OrderForm = () => {
  const {
    data: services,
    isLoading,
    error: serviceError,
  } = useGetServicesQuery();

  //conditional form fields depending on service type
  const [serviceType, setServiceType] = useState("");
  const [title, setTitles] = useState({});
  const [selectedServiceCategory, setSelectedServiceCategory] =
    useState("Cleaning Services");
  const [selectedServiceComponent, setSelectedServiceComponent] =
    useState("Home");

  const navigate = useNavigate();
  const params = useParams();
  const cart = useSelector((state) => state.cart);

  const handleSelectedServiceCategory = (e) => {
    setSelectedServiceCategory(e.target.value);
  };

  useEffect(() => {
    setTitles({ main: "Step 1 of 3: Make A Booking", right: "Order Summary" });
    params.category === "cleaning"
      ? setSelectedServiceCategory("Cleaning Services")
      : setSelectedServiceCategory("General Pest Control");
    console.log(selectedServiceCategory);
  }, [serviceType]);

  const submitHandler = async (e) => {
    e.preventDefault();
    navigate("/orderagent");
  };

  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to next step
  };

  //using the ratecard data, create a function that takes category, frequency and type and returns the components

  return isLoading ? (
    <Loader />
  ) : serviceError ? (
    <Message>{serviceError}</Message>
  ) : (
    // removed container to free up space for the form in responsive view
    <>
      <Row className="justify-content-md-center my-3">
        <FormColumnContainer id="step_one_order" title={title}>
          <SearchBox />
          <Form onSubmit={submitFormData}>
            <>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Select Service"
                    >
                      <Form.Select
                        onChange={handleSelectedServiceCategory}
                        onLoad={handleSelectedServiceCategory}
                      >
                        {services.map((service) => (
                          <option id={service._id} value={service.category}>
                            {service.category}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              {/* Select components depending on Service Category */}

              {selectedServiceCategory === "Cleaning Services" ? (
                <>
                  <CleaningComponents />
                </>
              ) : selectedServiceCategory === "General Pest Control" ? (
                <PestComponents
                  selectedServiceComponent={selectedServiceComponent}
                />
              ) : null}
            </>
          </Form>
          {cart.serviceAddress.suburb &&
          cart.serviceAddress.city &&
          selectedServiceCategory &&
          selectedServiceComponent ? (
            <button type="submit" class="button-28" onClick={submitHandler}>
              Choose An Extreme Agent <FaArrowRight />
            </button>
          ) : null}
        </FormColumnContainer>
      </Row>
    </>
  );
};
export default OrderForm;
