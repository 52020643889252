import {
  Row,
  Col,
  Table,
  Alert,
  Form,
  FloatingLabel,
  Dropdown,
  ListGroup,
  Stack,
  Image,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { saveBookingDate } from "../slices/cartSlice";
import { useDispatch } from "react-redux";
import { FaClock } from "react-icons/fa";

const OrderSummary = () => {
  const amt = new Intl.NumberFormat("en-ZA", {
    style: "currency",
    currency: "ZAR",
  });

  const cart = useSelector((state) => state.cart);
  //date iniatilized to next day
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split("T")[0]
  );

  const selectedDay = new Date(selectedDate).toLocaleDateString(undefined, { weekday: 'long' });
  const [selectedTime, setSelectedTime] = useState("8:00 AM");
  const hours = Array.from({ length: 13 }, (_, index) => 8 + index);
  const dispatch = useDispatch();

  useEffect(() => {
    //set date and time to cart
    dispatch(saveBookingDate({ selectedDate, selectedTime }));
  }, [selectedDate, selectedTime]);

  const handleSelectedDate = (e) => {

    
    setSelectedDate(e.target.value);
    dispatch(saveBookingDate({ selectedDate: e.target.value })); //save date to cart
  };


  const handleTimeSelect = async (hour) => {
    setSelectedTime(`${hour}:00 ${hour < 12 ? "AM" : "PM"}`);
    dispatch(
      saveBookingDate({ selectedTime: `${hour}:00 ${hour < 12 ? "AM" : "PM"}` })
    ); //save time to cart
  };

  return (
    <>
      {/* do not show this row in step 3 */}

      <Row>
        <Col>
          <Form.Group className="mt-3">
            <FloatingLabel label="Select Clean Date" className="mb-3">
              <Form.Control
                value={selectedDate}
                onChange={handleSelectedDate}
                type="date"
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col>
          <Dropdown className="mt-3 py-2">
            <Dropdown.Toggle variant="success" id="time-dropdown">
              <FaClock className="m-2" />
              {selectedTime}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {hours.map((hour) => (
                <Dropdown.Item
                  key={hour}
                  onClick={() => handleTimeSelect(hour)}
                >
                  {`${hour}:00 ${hour < 12 ? "AM" : "PM"}`}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      <Alert className="my-3" variant="success">
        <ListGroup>
          <h5 className="text-center">
            <strong>Order Summary</strong>
          </h5>
          <ListGroup.Item>
            <strong>
              Date:{cart.bookingDate ? cart.bookingDate.selectedDate : null}
            </strong>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              {" "}
              Time:{cart.bookingDate ? cart.bookingDate.selectedTime : null}
            </strong>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              Suburb:{" "}
              {cart.serviceAddress.suburb ? cart.serviceAddress.suburb : null}
            </strong>
            <strong>
              {", "}
              {cart.serviceAddress.city ? cart.serviceAddress.city : null}
            </strong>
          </ListGroup.Item>
          <ListGroup.Item>
            {cart.extremeAgent && (
              <Stack direction="horizontal" gap={5}>
                <strong>Agent: {cart.extremeAgent.name}</strong>
                <Image
                  src={cart.extremeAgent.img}
                  alt={cart.extremeAgent.name}
                  roundedCircle
                  style={{ width: 50, height: 50 }}
                />
              </Stack>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Alert>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Item</th>
            <th>Days/Hrs</th>
            <th>Rate</th>
            <th>Item sub</th>
          </tr>
        </thead>
        <tbody>
          {cart.cartItems.map((item) => {
            if (item.type === "One-Time" || item.type === "Task") {
              return (
                <tr key={item.id}>
                  <td>
                    {item.type}:{item.name}
                  </td>
                  <td>
                    {item.duration}
                    {" Hrs"}
                  </td>
                  <td>{amt.format(item.rate)}</td>
                  <td>{item.subtotal}</td>
                </tr>
              );
            } else if (item.type === "Recurring") {
              return (
                <tr key={item.id}>
                  <td>{item.type}</td>
                  <td>
                    {item.duration}
                    {" Hrs"}
                  </td>
                  <td>{amt.format(item.rate)}</td>
                  <td>{item.subtotal}</td>
                </tr>
              );
            } else if (item.type === "Schedule") {
              return (
                <tr key={item.id}>
                  <td>{item.type}</td>
                  <td>
                    {item.duration === 1
                      ? item.duration + " Day- "
                      : item.duration + " Days- "}

                    {/* for one day do not include a comma, for a list of days exclude the comma for the last day */}
                    {item.days.length === 1
                      ? item.days.map((day) => day + " ")
                      : item.days.map((day, index) =>
                          index === item.days.length - 1 ? day : day + ", "
                        )}
                  </td>
                </tr>
              );
            }
          })}
          <tr>
            <td colSpan="2">
              <strong>Sub-Total</strong>
            </td>
            <td></td>
            <td>
              <strong>{amt.format(cart.subtotalPrice)}</strong>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <strong>Tax</strong>
            </td>
            <td></td>
            <td>
              <strong>{amt.format(cart.taxPrice)}</strong>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <strong>Total</strong>
            </td>
            <td></td>
            <td>
              <strong>{amt.format(cart.totalPrice)}</strong>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default OrderSummary;
