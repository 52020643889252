import React, { useEffect, useState } from "react";
import { Container, Accordion, Button, Form, Badge } from "react-bootstrap";
import {
  useGetServicesQuery,
  useUpdateServiceMutation,
  useCreateServiceCategoryMutation,
  useDeleteServiceMutation,
} from "../../slices/servicesApiSlice";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import ProductList from "./ProductListScreen";
import { FaTrash, FaPlusCircle, FaEdit, FaSave } from "react-icons/fa";
import AddCategoryModal from "./AddCategoryModal";
import AddComponentModal from "./AddComponentModal";
import AddTaskModal from "./AddTaskModal";
import AddBillModal from "./AddBillModal";
import DeleteCategoryModal from "./DeleteCategoryModal";
import DeleteComponentModal from "./DeleteComponentModal";
import DeleteTaskModal from "./DeleteTaskModal";
import DeleteBillingModal from "./DeleteBillingModal";
import EditCategoryModal from "./EditCategoryModal"; // Import the new modal

const FormGroupGenerator = ({
  items = [],
  onChange,
  onDelete,
  formConfig,
  showDeleteIcon = true,
  showSaveIcon = false,
}) => {
  return items.map((item, index) => (
    <Form key={item._id || index} className="my-3 border-bottom">
      <Badge className="mb-2">
        {index + 1} of {items.length}
      </Badge>
      {formConfig.map(
        ({ controlId, label, placeholder, valueKey, type = "text" }) => (
          <Form.Group
            controlId={`${controlId}-${item._id || index}`}
            key={controlId}
          >
            <Form.Label>{label}</Form.Label>
            <Form.Control
              type={type}
              placeholder={placeholder}
              value={item[valueKey]}
              onChange={(e) => onChange(index, valueKey, e.target.value)}
            />
          </Form.Group>
        )
      )}
      <div className="d-flex mt-2">
        {showDeleteIcon && (
          <FaTrash className="me-2" onClick={() => onDelete(index)} />
        )}
        {showSaveIcon && <FaSave className="ms-2" />}
      </div>
    </Form>
  ));
};

const ServiceForm = () => {
  const [servicesData, setServicesData] = useState([]);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showAddBillModal, setShowAddBillModal] = useState(false);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [showDeleteComponentModal, setShowDeleteComponentModal] =
    useState(false);
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);
  const [showDeleteBillingModal, setShowDeleteBillingModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false); // State for edit modal
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(null);
  const [selectedTaskToDelete, setSelectedTaskToDelete] = useState(null);
  const [selectedBillingToDelete, setSelectedBillingToDelete] = useState(null);
  const [newCategory, setNewCategory] = useState({ category: "" });
  const [newComponent, setNewComponent] = useState({
    name: "",
    description: "",
  });
  const [newTask, setNewTask] = useState({
    name: "",
    description: "",
    duration: "",
    rate: "",
  });
  const [newBill, setNewBill] = useState({
    type: "",
    category: "",
    name: "",
    rate: "",
    duration: "",
    description: "",
  });
  const [currentCategoryData, setCurrentCategoryData] = useState(null); // State for the current category being edited
  const { data: services, isLoading, isError, error } = useGetServicesQuery();
  const [createServiceCategory] = useCreateServiceCategoryMutation();
  const [updateService] = useUpdateServiceMutation();
  const [deleteService] = useDeleteServiceMutation();

  useEffect(() => {
    if (services) {
      setServicesData(services);
    }
  }, [services]);

  const handleEditCategory = (categoryIndex) => {
    const selectedCategory = servicesData[categoryIndex];
    setCurrentCategoryData({ ...selectedCategory });
    setShowEditCategoryModal(true);
  };

  const handleSaveChanges = async () => {
    // Create a new copy of the servicesData array
    const updatedServicesData = [...servicesData];
    // Replace the category at the selected index with the modified category data
    updatedServicesData[selectedCategoryIndex] = { ...currentCategoryData };
    // Update the state with the new data
    setServicesData(updatedServicesData);
    // Send the updated category data to the backend
    await updateService(currentCategoryData);
    // Close the edit modal
    setShowEditCategoryModal(false);
  };
  

  const handleAddCategory = async (categoryData) => {
    try {
      const { data } = await createServiceCategory(categoryData);
      setServicesData([...servicesData, data]);
      setNewCategory({ category: "", components: [] });
      setShowAddCategoryModal(false);
    } catch (error) {
      console.error("Failed to add category:", error);
    }
  };

  const handleDeleteCategory = async () => {
    try {
      // Get the ID of the category to be deleted
      const serviceId = servicesData[selectedCategoryIndex]._id;
      // Create a copy of servicesData
      const updatedServicesData = JSON.parse(JSON.stringify(servicesData));
      // Remove the category from the array
      updatedServicesData.splice(selectedCategoryIndex, 1);
      // Update the state
      setServicesData(updatedServicesData);
      // Perform the deletion from the backend
      await deleteService(serviceId);
      // Close the modal
      setShowDeleteCategoryModal(false);
    } catch (error) {
      console.error("Failed to delete category:", error);
    }
  };

  const handleAddComponent = async () => {
    const updatedServicesData = JSON.parse(JSON.stringify(servicesData));
    // Ensure that the components array exists
    if (!updatedServicesData[selectedCategoryIndex].component) {
      updatedServicesData[selectedCategoryIndex].component = [];
    }
    // Add a new component with empty tasks and billing arrays
    updatedServicesData[selectedCategoryIndex].component.push({
      ...newComponent,
      tasks: [],
      billing: [],
    });
    setServicesData(updatedServicesData);
    await updateService(updatedServicesData[selectedCategoryIndex]);
    setNewComponent({ name: "", description: "" });
    setShowAddComponentModal(false);
  };

  const handleDeleteComponent = async (componentIndex) => {
    const updatedServicesData = JSON.parse(JSON.stringify(servicesData));
    updatedServicesData[selectedCategoryIndex].component.splice(
      componentIndex,
      1
    );
    setServicesData(updatedServicesData);
    await updateService(updatedServicesData[selectedCategoryIndex]);
    setShowDeleteComponentModal(false);
  };

  const handleAddTask = async () => {
    const updatedServicesData = JSON.parse(JSON.stringify(servicesData));
    updatedServicesData[selectedCategoryIndex].component[
      selectedComponentIndex
    ].tasks.push(newTask);
    setServicesData(updatedServicesData);
    await updateService(updatedServicesData[selectedCategoryIndex]);
    setNewTask({ name: "", description: "", duration: "", rate: "" });
    setShowAddTaskModal(false);
  };

  const handleDeleteTask = async (taskIndex) => {
    const updatedServicesData = JSON.parse(JSON.stringify(servicesData));
    updatedServicesData[selectedCategoryIndex].component[
      selectedComponentIndex
    ].tasks.splice(taskIndex, 1);
    setServicesData(updatedServicesData);
    await updateService(updatedServicesData[selectedCategoryIndex]);
    setShowDeleteTaskModal(false);
  };

  const handleAddBill = async () => {
    const updatedServicesData = JSON.parse(JSON.stringify(servicesData));
    updatedServicesData[selectedCategoryIndex].component[
      selectedComponentIndex
    ].billing.push(newBill);
    setServicesData(updatedServicesData);
    await updateService(updatedServicesData[selectedCategoryIndex]);
    setNewBill({
      type: "",
      category: "",
      name: "",
      rate: "",
      duration: "",
      description: "",
    });
    setShowAddBillModal(false);
  };

  const handleDeleteBilling = async (billingIndex) => {
    const updatedServicesData = JSON.parse(JSON.stringify(servicesData));
    updatedServicesData[selectedCategoryIndex].component[
      selectedComponentIndex
    ].billing.splice(billingIndex, 1);
    setServicesData(updatedServicesData);
    await updateService(updatedServicesData[selectedCategoryIndex]);
    setShowDeleteBillingModal(false);
  };

  const handleTaskChange = (taskIndex, field, value) => {
    const updatedServicesData = JSON.parse(JSON.stringify(servicesData));
    updatedServicesData[selectedCategoryIndex].component[
      selectedComponentIndex
    ].tasks[taskIndex][field] = value;
    setServicesData(updatedServicesData);
    updateService(updatedServicesData[selectedCategoryIndex]);
  };

  const handleBillChange = (billIndex, field, value) => {
    const updatedServicesData = JSON.parse(JSON.stringify(servicesData));
    updatedServicesData[selectedCategoryIndex].component[
      selectedComponentIndex
    ].billing[billIndex][field] = value;
    setServicesData(updatedServicesData);
    updateService(updatedServicesData[selectedCategoryIndex]);
  };

  const formConfigTasks = [
    {
      controlId: "task-name",
      label: "Name",
      placeholder: "Enter name",
      valueKey: "name",
    },
    {
      controlId: "task-description",
      label: "Description",
      placeholder: "Enter description",
      valueKey: "description",
    },
    {
      controlId: "task-duration",
      label: "Duration",
      placeholder: "Enter duration",
      valueKey: "duration",
    },
    {
      controlId: "task-rate",
      label: "Rate",
      placeholder: "Enter rate",
      valueKey: "rate",
    },
  ];

  const formConfigBilling = [
    {
      controlId: "bill-type",
      label: "Type",
      placeholder: "Enter type",
      valueKey: "type",
    },
    {
      controlId: "bill-category",
      label: "Category",
      placeholder: "Enter category",
      valueKey: "category",
    },
    {
      controlId: "bill-name",
      label: "Name",
      placeholder: "Enter name",
      valueKey: "name",
    },
    {
      controlId: "bill-rate",
      label: "Rate",
      placeholder: "Enter rate",
      valueKey: "rate",
    },
    {
      controlId: "bill-duration",
      label: "Duration",
      placeholder: "Enter duration",
      valueKey: "duration",
    },
    {
      controlId: "bill-description",
      label: "Description",
      placeholder: "Enter description",
      valueKey: "description",
    },
  ];

  return (
    <Container>
      <div className="d-flex mb-3">
        <h1>Services</h1>
        <Button variant="light" className="btn-sm">
        <FaPlusCircle
          onClick={() => setShowAddCategoryModal(true)}
        />
        </Button>
      </div>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Message variant="danger">{error.message}</Message>
      ) : (
        <Accordion defaultActiveKey="0">
          {servicesData.map((service, categoryIndex) => (
            <Accordion.Item
              eventKey={categoryIndex.toString()}
              key={service._id}
            >
              <Accordion.Header>
                {service.category}
                <Button variant="danger" className="btn-sm mx-2">
                  <FaTrash
                    onClick={() => {
                      setSelectedCategoryIndex(categoryIndex);
                      setShowDeleteCategoryModal(true);
                    }}
                  />
                </Button>
                <Button variant="light" className="btn-sm">
                    <FaEdit
                      onClick={() => {
                        setSelectedCategoryIndex(categoryIndex);
                        handleEditCategory(categoryIndex); // Edit category
                      }}
                    />
                  </Button>
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex mb-3">
                  <h5>Components</h5>
                  <Button variant="light" className="btn-sm">
                    <FaPlusCircle
                      onClick={() => {
                        setSelectedCategoryIndex(categoryIndex);
                        setShowAddComponentModal(true);
                      }}
                    />
                  </Button>
                </div>
                {service.component.map((comp, componentIndex) => (
                  <Accordion key={comp._id}>
                    <Accordion.Item eventKey={componentIndex.toString()}>
                      <Accordion.Header>
                        <h6>{comp.name}</h6>

                        <Button variant="danger" className="btn-sm mx-2">
                          <FaTrash
                            onClick={() => {
                              setSelectedCategoryIndex(categoryIndex);
                              setSelectedComponentIndex(componentIndex);
                              setShowDeleteComponentModal(true);
                            }}
                          />
                        </Button>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex mb-3">
                          <h5>
                            <strong>Tasks</strong>
                          </h5>

                          <Button variant="light" className="btn-sm">
                            <FaPlusCircle
                              onClick={() => {
                                setSelectedCategoryIndex(categoryIndex);
                                setSelectedComponentIndex(componentIndex);
                                setShowAddTaskModal(true);
                              }}
                            />
                          </Button>
                        </div>
                        <FormGroupGenerator
                          items={comp.tasks}
                          onChange={handleTaskChange}
                          onDelete={handleDeleteTask}
                          formConfig={formConfigTasks}
                          showSaveIcon={true}
                        />
                        <div className="d-flex mb-3">
                          <h5>
                            <strong>Billing</strong>
                          </h5>
                          <Button variant="light" className="btn-sm">
                            <FaPlusCircle
                              onClick={() => {
                                setSelectedCategoryIndex(categoryIndex);
                                setSelectedComponentIndex(componentIndex);
                                setShowAddBillModal(true);
                              }}
                            />
                          </Button>
                        </div>
                        <FormGroupGenerator
                          items={comp.billing}
                          onChange={handleBillChange}
                          onDelete={handleDeleteBilling}
                          formConfig={formConfigBilling}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}

      <AddCategoryModal
        show={showAddCategoryModal}
        handleClose={() => setShowAddCategoryModal(false)}
        newCategory={newCategory}
        setNewCategory={setNewCategory}
        handleAddCategory={handleAddCategory}
      />
      <DeleteCategoryModal
        show={showDeleteCategoryModal}
        handleClose={() => setShowDeleteCategoryModal(false)}
        handleDelete={handleDeleteCategory}
      />
      <AddComponentModal
        show={showAddComponentModal}
        handleClose={() => setShowAddComponentModal(false)}
        newComponent={newComponent}
        setNewComponent={setNewComponent}
        handleAddComponent={handleAddComponent}
      />
      <DeleteComponentModal
        show={showDeleteComponentModal}
        handleClose={() => setShowDeleteComponentModal(false)}
        handleDelete={handleDeleteComponent}
      />
      <AddTaskModal
        show={showAddTaskModal}
        handleClose={() => setShowAddTaskModal(false)}
        newTask={newTask}
        setNewTask={setNewTask}
        handleAddTask={handleAddTask}
      />
      <DeleteTaskModal
        show={showDeleteTaskModal}
        handleClose={() => setShowDeleteTaskModal(false)}
        handleDelete={handleDeleteTask}
      />
      <AddBillModal
        show={showAddBillModal}
        handleClose={() => setShowAddBillModal(false)}
        newBill={newBill}
        setNewBill={setNewBill}
        handleAddBill={handleAddBill}
      />
      <DeleteBillingModal
        show={showDeleteBillingModal}
        handleClose={() => setShowDeleteBillingModal(false)}
        handleDelete={handleDeleteBilling}
      />

      {/* New Edit Category Modal */}
      {currentCategoryData && (
        <EditCategoryModal
          show={showEditCategoryModal}
          handleClose={() => setShowEditCategoryModal(false)}
          categoryData={currentCategoryData}
          setCategoryData={setCurrentCategoryData}
          handleSaveChanges={handleSaveChanges}
        />
      )}

      <hr />
      <ProductList />
    </Container>
  );
};

export default ServiceForm;
