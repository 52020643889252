import React, { useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import { useGetSettingsQuery } from '../slices/settingsApiSlice';
import Loader from './Loader';
import fallback_logo from "../assets/img/logo2.png";

const Header = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutApiCall] = useLogoutMutation();
  const { data: settings, isLoading, error } = useGetSettingsQuery();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate('/signin');
    } catch (err) {
      console.log(err);
    }
  };

  const renderLogo = () => {
    if (isLoading) {
      return <Loader />;
    } else if (error) {
      return <img src={fallback_logo} alt="Fallback Logo" width="125" height="125" />;
    } else if (settings && settings.length > 0) {
      const logo = settings[0].site_logo.find((setting) => setting.isSelected === true);
      if (logo) {
        console.log(logo);
        return <img src={logo.site_logo_url} alt={logo.site_logo_name} width="125" height="125" />;
      } else {
        return <img src={fallback_logo} alt="Fallback Logo" width="125" height="125" />;
      }
    }
  };

  useEffect(() => {
    if (settings && settings.length > 0) {
      const body_bg = settings[0].site_bg.find((setting) => setting.isSelected === true);
      if (body_bg) {
        document.body.style.backgroundImage = `url(${body_bg.site_bg_url})`;
      }
      document.documentElement.style.setProperty("--text-header-bg", settings[0].site_palette[0].site_palette_color);
      document.documentElement.style.setProperty("--header", settings[0].site_palette[3].site_palette_color);
    }
  }, [settings]);

  return (
    <Navbar className="bg-body-tertiary" collapseOnSelect expand="lg">
      <Container fluid className="header">
        <LinkContainer to="/">
          <Navbar.Brand>
            {renderLogo()}
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <LinkContainer to="/" className="nav-link">
              <Nav.Link eventKey="1">Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="contact" className="nav-link">
              <Nav.Link eventKey="2">Contact</Nav.Link>
            </LinkContainer>

            {userInfo ? (
              <NavDropdown title={userInfo.name} id="username">
                <LinkContainer to="/profile">
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={logoutHandler} eventKey="8">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="join" className="nav-link">
                  <Nav.Link eventKey="9">Join Us!</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/signin">
                  <Nav.Link eventKey="7">
                    <FaUser />
                    Sign In
                  </Nav.Link>
                </LinkContainer>
              </>
            )}

            {userInfo && userInfo.isAdmin && (
              <NavDropdown title="Admin Menu" id="adminmenu">
                <LinkContainer to="/admin/userlist">
                  <NavDropdown.Item>Users and Customers</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/admin/serviceform">
                  <NavDropdown.Item>Products and Services</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/admin/salesleads">
                  <NavDropdown.Item>Sales and Marketing</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/admin/partners">
                  <NavDropdown.Item>Partners and Suppliers</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/tasks">
                  <NavDropdown.Item>Business Processes</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/admin/bmcnotes">
                  <NavDropdown.Item>Strategy</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/admin/templates">
                  <NavDropdown.Item>Templates and Docs</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/admin/site_settings">
                  <NavDropdown.Item>Site Settings</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
