import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteComponentModal = ({ show, handleClose, handleDelete, componentData }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Component Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this component?
        {componentData && (
          <div>
            <h5>Component: {componentData.name}</h5>
            <ul>
              {componentData.tasks && componentData.tasks.map((task, i) => (
                <li key={i}>{task.name}</li>
              ))}
              {componentData.billing && componentData.billing.map((bill, i) => (
                <li key={i}>{bill.name}</li>
              ))}
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        <Button variant="danger" onClick={handleDelete}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteComponentModal;
