import React, { useEffect } from "react";

import { Form, Card, Stack } from "react-bootstrap";
import { useGetServicesQuery } from "../slices/servicesApiSlice";
import {
  addToCart,
  removeFromCartByName,
  removeFromCart,
  updateCart,
} from "../slices/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import OrderSummary from "../components/OrderSummary";

const OneTimeCheckout = ({ selectedServiceComponent }) => {
  console.log(selectedServiceComponent);

  const dispatch = useDispatch();
  const {
    data: services,
    isLoading,
    error: serviceError,
  } = useGetServicesQuery();

  const state = useSelector((state) => state.cart);

  useEffect(() => {
    const existingItem = state.cartItems.find(
      (item) => item.name === "Basic Clean" || "Office Clean" || "Hotel Clean"
    );
    if (existingItem) {
      dispatch(
        updateCart({
          ...existingItem,
          type: "One-Time",
          rate: 75,
        })
      );
    }
    // delete the cartItems object if the user switches from scheduler to one-time

    const existingItem2 = state.cartItems.find(
      (item) => item.name === "Scheduler"
    );
    if (existingItem2) {
      console.log("existingItem2", existingItem2);
      dispatch(removeFromCartByName("Scheduler"));
    }
  }, [dispatch]);

  const getComponentTasks = (category, component) => {
    let selectedComponent = services.filter(
      (service) => service.category === category
    );
    let selectedTasks = selectedComponent[0].component.filter(
      (comp) => comp.name === component
    );
    return selectedTasks ? selectedTasks[0].tasks : [];
  };
  const getComponentTaskById = (id) => {
    let selectedComponent = services.filter(
      (service) => service.category === "Cleaning Services"
    );
    let selectedTasks = selectedComponent[0].component.filter(
      (comp) => comp.name === selectedServiceComponent //selectedServiceComponent passed from parent component
      //filter by id
    );

    return selectedTasks
      ? selectedTasks[0].tasks.filter((task) => task._id === id)
      : []; //filter by id here
  };

  const handleAddToCart = (e) => {
    const taskToCart = getComponentTaskById(e.target.id);
    if (e.target.checked) {
      dispatch(addToCart(...taskToCart));
    } else {
      dispatch(removeFromCart(e.target.id));
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : serviceError ? (
        <Message variant="danger">
          {serviceError?.data?.message || serviceError.error}
        </Message>
      ) : (
        <>
          <Card className="card-checkout ">
            <Card.Text className="mt-2 text-center">
              <strong>Choose Your Extra Tasks</strong>
            </Card.Text>
            <Stack
              className="m-1 justify-content-center"
              direction="horizontal"
              gap={2}
            >
              {getComponentTasks(
                "Cleaning Services",
                selectedServiceComponent
              ).map((task) => (
                <Form.Check
                  key={task.id}
                  inline
                  type="checkbox"
                  name={task.name}
                  value={task.amount}
                  id={task._id}
                  label={task.name}
                  onClick={handleAddToCart}
                />
              ))}
            </Stack>
          </Card>
          <OrderSummary />
        </>
      )}
    </>
  );
};

export default OneTimeCheckout;
