import {useState, useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import { FaTimes, FaEdit, FaPlus } from 'react-icons/fa';
import {toast} from 'react-toastify'
import {useCreateBMCNoteMutation} from '../../slices/bmcApiSlice'
import {useGetUsersQuery} from '../../slices/usersApiSlice'    
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Header from '../../components/Header'
import FormContainer from '../../components/FormContainer'
import {Button, Form} from 'react-bootstrap'

const AddBMCNote = () => {
    const [createBMCNote, {isLoading: loadingCreate}] = useCreateBMCNoteMutation()
    const {data:users, isLoading:loadingUsers, error} = useGetUsersQuery()
    const navigate = useNavigate()
    const {block_id} = useParams()

    const submitHandler = async(e) => {
        e.preventDefault()
        try{

            const fd = new FormData(e.target)
            const noteForm = fd.getAll('noteData')
            const data = Object.fromEntries(fd.entries())
            data.noteData = noteForm
            data.block_id = block_id
            console.log(data)
            await createBMCNote({block_id,data})
            toast.success('Note added/updated successfully')
            navigate('/admin/BmcNotes')
        }catch(err){
            toast.error(err?.data?.message || err.error)
        }
    } 

  return (
    <>
    <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">{/* Your content */}
        <Button className='my-3'><Link to='/admin/bmcNotes' className='btn btn-light'>Go Back</Link></Button>
         
        {/* {isLoading ? <Loader/> : loadingUsers  ? <Loader/>: error ? <Message variant='danger'>{error}</Message> :  */}
        {loadingUsers  ? <Loader/>: error ? <Message variant='danger'>{error}</Message> : 

    <FormContainer title='Add BMC Note'>
    <Form onSubmit={submitHandler}>
      <Form.Group>
          <Form.Label htmlFor='name'>Add Note</Form.Label>
          <Form.Control type='text' name='note_name' placeholder='Enter Note Name'></Form.Control>
      </Form.Group>
      <Form.Group>
          <Form.Label htmlFor='name'>Add Note Description</Form.Label>
          <Form.Control type='textarea' name='note_description' placeholder='Enter Note Description'></Form.Control>
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor='status'>Note status</Form.Label>
        <Form.Select name="note_status">
            <option value="">--Please choose an option--</option>
            <option value="todo">Todo</option>
            <option value="doing">Doing</option>
            <option value="done">Done</option>
            <option value="backlog">Backlog</option>
        </Form.Select>
        </Form.Group>
        <Form.Group>
        <Form.Label htmlFor='assignee'>Note assignee</Form.Label>
        <Form.Select name="note_assignee" >
        <option value="" name='user'>--Please choose an option--</option>    
        {users.map(user => (
            <option id={user._id}>{user.name}</option>
            )
        )}
        </Form.Select>
        </Form.Group>
        <Button type="submit">Submit</Button>
    </Form>
    </FormContainer>
  }
    </div>
    </main>
    </>
  )
}

export default AddBMCNote