import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
} from "../../slices/partnersApiSlice";

const PartnerModal = ({ show, handleClose, partner = null }) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [
    createPartner,
    {
      isLoading: isCreating,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreatePartnerMutation();
  const [
    updatePartner,
    {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdatePartnerMutation();

  useEffect(() => {
    if (partner) {
      setName(partner.name);
      setCategory(partner.category);
      setWebsite(partner.website);
      setEmail(partner.email);
      setPhone(partner.phone);
    }
  }, [partner]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const partnerData = {
      name,
      category,
      website,
      email,
      phone,
    };

    try {
      if (partner) {
        await updatePartner({
          partnerId: partner._id,
          ...partnerData,
        }).unwrap();
      } else {
        await createPartner(partnerData).unwrap();
      }
      handleClose();
    } catch (err) {
      console.error("Error:", err); // Log the error object
      console.error("Error details:", err.data?.message || err.message); // Log the detailed error message
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{partner ? "Update partner" : "Add partner"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="website">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          <Button
            type="submit"
            variant="primary"
            className="mt-3"
            disabled={isCreating || isUpdating}
          >
            {partner
              ? isUpdating
                ? "Updating..."
                : "Update partner"
              : isCreating
              ? "Adding..."
              : "Add partner"}
          </Button>
          {isCreateSuccess && (
            <p className="text-success mt-2">partner added successfully!</p>
          )}
          {isUpdateSuccess && (
            <p className="text-success mt-2">partner updated successfully!</p>
          )}
          {(isCreateError || isUpdateError) && (
            <p className="text-danger mt-2">
              {createError?.data?.message ||
                updateError?.data?.message ||
                "An error occurred"}
            </p>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PartnerModal;
