import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
} from "../slices/tasksApiSlice";
import { useGetUsersQuery } from "../slices/usersApiSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import { FaArrowRight, FaArrowLeft, FaEnvelope } from "react-icons/fa";
import { Form, Button } from "react-bootstrap";

const EditTask = () => {
  const { id: taskId } = useParams();
  const navigate = useNavigate();
  const [taskData, setTaskData] = useState({
    name: "",
    description: "",
    status: "",
    assignee: "",
  });
  const {
    data: task,
    isLoading: loadingTask,
    refetch,
    error: loadingTaskError,
  } = useGetTaskByIdQuery(taskId);
  const [updateTask, { isLoading: loadingUpdate }] = useUpdateTaskMutation();
  const { data: users, isLoading: loadingUsers, error } = useGetUsersQuery();

  useEffect(() => {
    if (task) {
      setTaskData({
        id: task._id,
        name: task.name,
        description: task.description,
        status: task.status,
        assignee: task.assignee,
      });
    }
  }, [task, refetch]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const fd = new FormData(e.target);
      const taskForm = fd.getAll("taskData");
      const data = Object.fromEntries(fd.entries());
      data.taskData = taskForm;
      data.id = task._id; //inject id into data for update route
      console.log(data);
      await updateTask({ ...data });
      toast.success("Task added/updated successfully");
      navigate("/tasks");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleOnEventChange = (identifier, value) => {
    setTaskData({ ...taskData, [identifier]: value });
  };

  return (
    <>
      <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          {/* Your content */}
          <button className="button-28">
            <Link to="/admin/tasks">
              {" "}
              Go Back <FaArrowLeft />
            </Link>
          </button>
          {loadingUsers ? (
            <Loader />
          ) : loadingTask ? (
            <Loader />
          ) : loadingUpdate ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <FormContainer title="Edit Task">
              <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12"></div>
              </div>
              {task ? (
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter name"
                      value={taskData.name}
                      onChange={(event) =>
                        handleOnEventChange("name", event.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      placeholder="Enter description"
                      value={taskData.description}
                      onChange={(event) =>
                        handleOnEventChange("description", event.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>
                  <Form.Select
                    name="status"
                    value={taskData.status}
                    onChange={(event) =>
                      handleOnEventChange("status", event.target.value)
                    }
                  >
                    <option value="">--Please choose an option--</option>
                    <option value="todo">Todo</option>
                    <option value="doing">Doing</option>
                    <option value="done">Done</option>
                    <option value="backlog">Backlog</option>
                  </Form.Select>
                  <Form.Select
                    name="assignee"
                    value={taskData.assignee}
                    onChange={(event) =>
                      handleOnEventChange("assignee", event.target.value)
                    }
                  >
                    <option value="">--Please choose an option--</option>
                    {users?.map((user) => (
                      <option key={user._id} value={user.email}>
                        {user.email}
                      </option>
                    ))}
                  </Form.Select>

                  <button type="submit" className="button-28">
                    Update <FaArrowRight />
                  </button>
                </Form>
              ) : (
                <>
                  <p>Task not found!</p>
                  <Button>Go Back</Button>
                </>
              )}
            </FormContainer>
          )}
        </div>
      </main>
    </>
  );
};
export default EditTask;
