
import Message from '../components/Message'
import Loader from '../components/Loader'
import {useGetBMCQuery,useCreateBMCNoteMutation,useDeleteBMCNoteMutation} from '../slices/bmcApiSlice'
import { Container,Row, Col, Card ,Stack, Nav } from 'react-bootstrap'
import { Draggable } from '@hello-pangea/dnd';
import {FaEllipsisH, FaTrash, FaEdit, FaFolder, FaLink, FaLine} from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { LinkContainer, } from 'react-router-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const BmcNotes = ({key,note,index,block_id}) => {
    const {data:canvas, isLoading, error} = useGetBMCQuery()
    const [counter, setCounter] = useState(0);
    
    const [deleteNote, {isLoading: loadingDelete, refetch}] = useDeleteBMCNoteMutation()
  const navigate = useNavigate()
  const deleteHandler = async(block_id,note_id) => {
    if (window.confirm('Are you sure you want to delete this task?')){
        try{
            await deleteNote({block_id,note_id})
            refetch()
            toast.success('Note deleted successfully')
            navigate('/admin/bmcnotes')
        }catch(err){
            toast.error(err?.data?.message || err.error)
        }
    }
}
    return (
        <>
          {isLoading ? (<Loader/>) :  error ? (<Message variant='danger'>{error?.data?.message || error.error }</Message>):
          (
            <Draggable key={note._id} draggableId={note._id} index={index}>
            {(provided) => (
                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                <div className="note" id={block_id}>
                    <h6>{note.note_name}</h6>
                    <p>{note.note_description}</p>
                    <Stack direction="horizontal" gap={3}> 
                        <Nav.Link to="/admin/edit_task/"><FaEllipsisH/></Nav.Link>
                        <Nav.Link to="/admin/tasks/"><FaTrash onClick={() => deleteHandler(block_id,note._id)}></FaTrash></Nav.Link>
                        <LinkContainer to={`/admin/edit_bmc_note/${block_id}/${note._id}`}><Nav.Link ><FaEdit/></Nav.Link></LinkContainer>  
                        <Nav.Link to="/admin/edit_task/"><FaFolder/></Nav.Link>
                        <Nav.Link to="/admin/edit_task/"><FaLink/></Nav.Link>
                    </Stack>
                </div>
                </div>
            )}
            </Draggable>
            )}
        </>
    ) //end of return
}//end of function
export default BmcNotes