import { Container, Row, Col, Card } from "react-bootstrap";

const FormContainer = ({ title, children }) => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <Card className="my-3 p-3 rounded" as="div">
            <div class="custom-card-header">
              <Card.Title as="h3" className="text-center">
                {title}
              </Card.Title>
            </div>
            <Card.Body>{children}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FormContainer;
