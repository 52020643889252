import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  FloatingLabel,
  Row,
  Col,
  Card,
  ListGroup,
  Stack,
} from "react-bootstrap";

import { useGetServicesQuery } from "../slices/servicesApiSlice";
import Loader from "./Loader";
import Message from "./Message";
import locations from "../data/locations";
import OneTimeCheckout from "./OneTimeCheckout";
import SchedulerCheckout from "./SchedulerCheckout";

const PestComponents = ({ selectedServiceComponent }) => {
  return (
    <Container>
      {selectedServiceComponent === "Cockroaches" ? (
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <FloatingLabel label="Cockroaches" className="mb-3">
                <Form.Select>
                  <option value=""> Select No.</option>
                  <option value="1">Site Inspection</option>
                  <option value="2" selected>
                    Baiting (Gel or Bait Stations)
                  </option>
                  <option value="3">
                    Setup Monitoring Devices to track activity
                  </option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
      ) : selectedServiceComponent === "Bugs" ? (
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <FloatingLabel label="Bugs" className="mb-3">
                <Form.Select>
                  <option value=""> Select No.</option>
                  <option value="1">Site Inspection</option>
                  <option value="2" selected>
                    Fumigation
                  </option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
};

export default PestComponents;
