import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteCategoryModal = ({ show, handleClose, handleDelete, categoryData }) => {
    return (
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Category Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to delete this category?
            {categoryData && (
            <div>
                <h5>Category: {categoryData.name}</h5>
                <p>{categoryData.description}</p>
            </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
        </Modal>
    );
    }

export default DeleteCategoryModal;