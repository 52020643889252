import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useCreateProductMutation, useUpdateProductMutation} from '../../slices/productsApiSlice';

const ProductModal = ({ show, handleClose, product = null }) => {
    const [name, setName] = useState(product ? product.name : '');
    const [price, setPrice] = useState(product ? product.price : 0);
    const [category, setCategory] = useState(product ? product.category : '');
    const [brand, setBrand] = useState(product ? product.brand : '');
    const [image, setImage] = useState(product ? product.image : '');
    const [countInStock, setCountInStock] = useState(product ? product.countInStock : 0);
    const [description, setDescription] = useState(product ? product.description : '');


  const [createProduct, { isLoading: isCreating, isSuccess: isCreateSuccess, isError: isCreateError, error: createError }] = useCreateProductMutation();
  const [updateProduct, { isLoading: isUpdating, isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError }] = useUpdateProductMutation();

  useEffect(() => {
    if (product) {
      setName(product.name);
      setPrice(product.price);
      setCategory(product.category);
      setBrand(product.brand);
      setImage(product.image);
      setCountInStock(product.countInStock);
      setDescription(product.description);
    }
  }, [product]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const productData = {
      name,
      price,
      category,
      brand,
      image,
      countInStock,
      description,
    };
   
    try {
      if (product) {
     

        
        await updateProduct({ productId: product._id, ...productData }).unwrap();
      } else {
        console.log('Product Data:', productData); // Log data before submission
        await createProduct(productData).unwrap();
      }
      handleClose();
    } catch (err) {
      console.error('Error:', err); // Log the error object
      console.error('Error details:', err.data?.message || err.message); // Log the detailed error message
    }
  };
  

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{product ? 'Update Product' : 'Add Product'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="productName">
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="productPrice" className="mt-3">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter product price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="productCategory" className="mt-3">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="productBrand" className="mt-3">
            <Form.Label>Brand</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter product brand"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="productImage" className="mt-3">
            <Form.Label>Image</Form.Label>
            <Form.Control
                type="text"
                placeholder="Enter product image URL"
                value={image}
                onChange={(e) => setImage(e.target.value)}
                required
            />
            </Form.Group>

          <Form.Group controlId="productCountInStock" className="mt-3">
            <Form.Label>Count In Stock</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter product count in stock"
              value={countInStock}
              onChange={(e) => setCountInStock(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="productDescription" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter product description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          <Button type="submit" variant="primary" className="mt-3" disabled={isCreating || isUpdating}>
            {product ? (isUpdating ? 'Updating...' : 'Update Product') : (isCreating ? 'Adding...' : 'Add Product')}
          </Button>
          {isCreateSuccess && <p className="text-success mt-2">Product added successfully!</p>}
          {isUpdateSuccess && <p className="text-success mt-2">Product updated successfully!</p>}
          {(isCreateError || isUpdateError) && (
            <p className="text-danger mt-2">{createError?.data?.message || updateError?.data?.message || 'An error occurred'}</p>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
