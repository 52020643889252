import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "./assets/css/custom.css";
import "./assets/css/bmc.css";
import "./assets/css/custom_bootstrap.css";
import HomeScreen from "./screens/HomeScreen";
import ServiceEditScreen from "./screens/admin/ServiceEditScreen";
import ContactScreen from "./screens/ContactScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen.jsx";
import AdminRoute from "./components/AdminRoute";
import PrivateRoute from "./components/PrivateRoute";
import ServiceForm from "./screens/admin/ServiceForm";
import UserListScreen from "./screens/admin/UserListScreen.jsx";
import UserEditScreen from "./screens/admin/UserEditScreen.jsx";
import SalesLeadsListScreen from "./screens/admin/SalesLeadsListScreen";
import BmcListScreen from "./screens/admin/BmcListScreen";
import BmcNotesScreen from "./screens/admin/BmcNotesScreen.jsx";
import AddTask from "./screens/AddTask.jsx";
import EditTask from "./screens/EditTask.jsx";
import AddBMCNote from "./screens/admin/AddBMCNote.jsx";
import EditBMCNote from "./screens/admin/EditBMCNote.jsx";
import ProfileScreen from "./screens/ProfileScreen.jsx";
import PageNotFound from "./screens/PageNotFound";
import OrderResult from "./screens/OrderResult.jsx";
import OrderForm from "./screens/OrderForm.jsx";
import OrderAgent from "./screens/OrderAgent.jsx";
import OrderPayment from "./screens/OrderPayment.jsx";
import AdminDocs from "./screens/admin/AdminDocs.jsx";
import JoinUsScreen from "./screens/JoinUsScreen.jsx";
import TaskGrid from "./components/TaskGrid";
import SiteSettings from "./screens/admin/SiteSettings.jsx";
import PartnersScreen from "./screens/admin/PartnersScreen.jsx";
import ProductListScreen from "./screens/admin/ProductListScreen.jsx";
import TemplatesScreen from "./screens/admin/TemplatesScreen.jsx";
import ProductDetailScreen from "./screens/ProductDetailScreen.jsx";
import SalesLeadsDetailsScreen from "./screens/admin/SalesLeadsDetailScreen.jsx";
import PasswordReset from "./components/PasswordReset.jsx";
import Chat from "./components/Chat.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />} />
      <Route index={true} path="/contact" element={<ContactScreen />} />
      <Route index={true} path="/signin" element={<LoginScreen />} />
      <Route index={true} path="/register" element={<RegisterScreen />} />
      <Route
        index={true}
        path="/order_result/:result/:order_id"
        element={<OrderResult />}
      />
      <Route index={true} path="/orderform/:category" element={<OrderForm />} />
      <Route index={true} path="/orderagent" element={<OrderAgent />} />
      <Route index={true} path="/orderpayment" element={<OrderPayment />} />
      <Route index={true} path="/join" element={<RegisterScreen />} />
      <Route index={true} path="/tasks" element={<TaskGrid />} />
      <Route index={true} path="/resetpassword/:token" element={<PasswordReset />} />
      <Route index={true} path="/chat" element={<Chat />} />
      <Route path="" element={<PrivateRoute />}>
        <Route index={true} path="/edit/:id" element={<EditTask />} />
        <Route index={true} path="/add_task" element={<AddTask />} />
        <Route index={true} path="/profile" element={<ProfileScreen />} />
      </Route>
      <Route path="" element={<AdminRoute />}>
        <Route
          index={true}
          path="/admin/serviceform"
          element={<ServiceForm />}
        />
        <Route
          index={true}
          path="/admin/productlist"
          element={<ProductListScreen />}
        />
          
        <Route
          index={true}
          path="/admin/service/:id/edit"
          element={<ServiceEditScreen />}
        />
        <Route
          index={true}
          path="/admin/userlist"
          element={<UserListScreen />}
        />
        <Route
          index={true}
          path="/admin/user/:id/edit"
          element={<UserEditScreen />}
        />
        <Route
          index={true}
          path="/admin/salesleads"
          element={<SalesLeadsListScreen />}
        />
        <Route
          index={true}
          path="/admin/bmcnotes"
          element={<BmcNotesScreen />}
        />
        <Route index={true} path="/admin/bmc" element={<BmcListScreen />} />
        <Route
          index={true}
          path="/admin/add_bmc_note/:block_id"
          element={<AddBMCNote />}
        />
 
        <Route
          index={true}
          path="/admin/serviceform"
          element={<ServiceForm />}
        />
        <Route
          index={true}
          path="/admin/edit_bmc_note/:block_id/:note_id"
          element={<EditBMCNote />}
        />
      </Route>
      <Route index={true} path="/admin/docs" element={<AdminDocs />} />
      <Route index={true} path="/admin/site_settings" element={<SiteSettings />} />
      <Route index={true} path="/admin/partners" element={<PartnersScreen />} />
      <Route index={true} path="/admin/templates" element={<TemplatesScreen />} />    
      <Route index={true} path="/admin/product/:id/edit" element={<ProductDetailScreen />} />         
      <Route index={true} path="/admin/salesleads/:id" element={<SalesLeadsDetailsScreen />} />     
    </Route>
    
  )
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
