import React from "react";
import customerFlow from "../../assets/img/customer.png";
import agentFlow from "../../assets/img/agent.png";
import { Container, Row, Col, Stack, Card, Nav, Image } from "react-bootstrap";

/**
 * Renders the AdminDocs component.
 * This component provides documentation that traces the system flow for Employers, Employees, and Customers.
 */
const AdminDocs = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={12} lg={12}>
          <Card className="my-3 p-3 rounded" as="div">
            <Card.Body>
              <div class="custom-card-header">
                <Card.Title as="h3" className="text-center">
                  Customer Workflow
                </Card.Title>
              </div>
              <div class="py-3">
                <img
                  src={customerFlow}
                  alt="customerFlow"
                  height="711"
                  width="648"
                />
              </div>
            </Card.Body>
          </Card>
          <Card className="my-3 p-3 rounded" as="div">
            <Card.Body>
              <div class="custom-card-header">
                <Card.Title as="h3" className="text-center">
                  Agent Workflow
                </Card.Title>
              </div>
              <div class="py-3">
                <img
                  src={agentFlow}
                  alt="agentFlow"
                  height="521"
                  width="650"
                />
              </div>
            </Card.Body>
          </Card>
          <Card className="my-3 p-3 rounded" as="div">
            <Card.Body>
              <div class="custom-card-header">
                <Card.Title as="h3" className="text-center">
                  Admin Workflow
                </Card.Title>
              </div>
              <div class="py-3">
              
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDocs;
