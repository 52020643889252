import { PARTNERS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

const partnersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPartners: builder.query({
        query: () => ({
            url: PARTNERS_URL,
        }),
        providesTags: ["Partners"],
        keepUnusedDataFor: 5,
        }),
        getPartnerById: builder.query({
        query: (id) => ({
            url: `${PARTNERS_URL}/${id}`,
        }),
        }),
        createPartner: builder.mutation({
        query: (data) => ({
            url: PARTNERS_URL,
            method: "POST",
            body: data,
        }),
        invalidatesTags: ["Partners"],
        }),
        updatePartner: builder.mutation({
        query: (data) => ({
            url: `${PARTNERS_URL}/${data.partnerId}`,
            method: "PUT",
            body: data,
        }),
        invalidatesTags: ["Partners"],
        }),
        deletePartner: builder.mutation({
        query: (id) => ({
            url: `${PARTNERS_URL}/${id}`,
            method: "DELETE",
        }),
        invalidatesTags: ["Partners"],
        }),
    }),
    });

export const {useGetPartnersQuery, useGetPartnerByIdQuery, useCreatePartnerMutation, useUpdatePartnerMutation, useDeletePartnerMutation} = partnersApiSlice;