import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Card, ListGroup, Image } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useGetAgentsQuery } from "../slices/usersApiSlice";
import OrderSummary from "../components/OrderSummary";
import FormColumnContainer from "../components/FormColumnContainer";
import React, { useEffect, useState } from "react";
import Rating from "../components/Rating";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper/modules";
import { saveExtremeAgent } from "../slices/cartSlice";


// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/a11y";
import "swiper/css/effect-fade";

const StepTwo = () => {
  const navigate = useNavigate();
  const { data: agents, isLoading, error } = useGetAgentsQuery();
  const [title, setTitles] = useState({});

  const dispatch = useDispatch();

    useEffect(() => {
      setTitles({ main: "Step 2 of 3: Choose An Agent", right: "Order Summary" });
     
    }, []);

//check date selected by user against available dates
const checkDateClashes = (selectedDate, existingDates) => {
  const clashes = existingDates.filter((date) => date === selectedDate);
  return clashes.length > 0;
};

  

  const handleSelectedAgent = () => {
    //check that  user has selected date and time
    
    //check that user has selected suburb - match agent in geographical area

    //get the current swiper slide
    const swiper = document.querySelector(".swiper-slide-active");
    //add a class to the current swiper slide
    // dispatch(saveExtremeAgent(swiper.id));
    //display the selected agent in the order summary
    const selectedAgent = agents.filter((agent) => agent.email === swiper.id);
    console.log(...selectedAgent);
    //add selected fields (_id,name,enail) of selectedAgent to cart
    const selectedAgentData = selectedAgent.map(({ _id, name, email, curriculumVitae }) => ({ _id, name, email, img: curriculumVitae.personal_details[0].img }));
    dispatch(saveExtremeAgent(...selectedAgentData));
    //show selected agent in OrderSummary
    console.log(agents);
    

    navigate(`/orderpayment/`);
    console.log(swiper.id);
  };

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    agents && (
      <Row className="justify-content-md-center my-2">
         <FormColumnContainer id="step_one_order" title={title}>
          <OrderSummary />
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, EffectFade, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
            effect="fade"
          >
            {agents.map((agent) => (
              <>
                <SwiperSlide id={agent.email}  >
                  <Card className="flush py-3">
                    <Image
                      src={agent.curriculumVitae.personal_details[0].img}
                      alt={agent.name}

                      roundedCircle
                      style={{ width: 200, height: 200 }}
                      className="my-3 mx-auto d-block"
                    />

                    <Card.Title className="text-center ">
                      <strong>{agent.name}</strong>
                    </Card.Title>
                    <Card.Body>
                      <Card.Text className="text-center ">
                        <Rating
                          value={agent.rating}
                          text={`${agent.numReviews} reviews`}
                        />
                      </Card.Text>
                      <div className="custom-card-header mt-3">
                        <Card.Subtitle className="text-center ">
                          Skills
                        </Card.Subtitle>
                      </div>
                      <div className="custom-card-body mb-3">
                        {agent.curriculumVitae.skills.map((skill, index) => (
                          <ListGroup variant="flush">
                            <ListGroup.Item variant="primary">
                              {skill.name}
                            </ListGroup.Item>
                          </ListGroup>
                        ))}
                      </div>
                      <div className="custom-card-header mt-3">
                        <Card.Subtitle className="text-center ">
                          Interests
                        </Card.Subtitle>
                      </div>
                      <div className="custom-card-body">
                        {agent.curriculumVitae.interests.map(
                          (interest, index) => (
                            <ListGroup variant="flush">
                              <ListGroup.Item variant="primary">
                                {interest.name}
                              </ListGroup.Item>
                            </ListGroup>
                          )
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </>
            ))}
          </Swiper>
          <button
            onClick={() => {
              handleSelectedAgent();
            }}
            variant="primary"
            className="button-28"
          >
            Select Agent
          </button>
        </FormColumnContainer>
      </Row>
    )
  );
};

export default StepTwo;
