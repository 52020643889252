import React, { useEffect, useState } from "react";
import { Container, Table, Button, Row, Col, Modal, Form } from "react-bootstrap";
import {
  FaCopy,
  FaFileWord,
  FaDownload,
  FaPlusCircle,
  FaEdit,
  FaTrash,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useGetSettingsQuery } from "../../slices/settingsApiSlice";
import { useGetServicesQuery } from "../../slices/servicesApiSlice";
import {
  useGetSalesLeadByIdQuery,
  useAddProductToSalesLeadMutation,
  useUpdateProductInSalesLeadMutation,
  useRemoveProductFromSalesLeadMutation,
  useAddServiceToSalesLeadMutation,
  useUpdateServiceInSalesLeadMutation,
  useRemoveServiceFromSalesLeadMutation,
} from "../../slices/salesApiSlice";
import createWordDoc from "../../components/CreateWordDoc";
import GenerateRFQResponseDoc from "../../components/GenerateRFQResponseDoc";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

const SalesLeadsDetailsScreen = () => {
  const { id: salesLeadId } = useParams();
  const {
    data: salesLead,
    isLoading: loading,
    error,
    refetch,
  } = useGetSalesLeadByIdQuery(salesLeadId);
  const {
    data: settings,
    isLoading: settingsLoading,
    error: settingsError,
  } = useGetSettingsQuery("header");
  const {
    data: services,
    isLoading: servicesLoading,
    error: servicesError,
  } = useGetServicesQuery();

  // Product mutations
  const [addProduct] = useAddProductToSalesLeadMutation();
  const [updateProduct] = useUpdateProductInSalesLeadMutation();
  const [removeProduct] = useRemoveProductFromSalesLeadMutation();
  // Service mutations
  const [addService] = useAddServiceToSalesLeadMutation();
  const [updateService] = useUpdateServiceInSalesLeadMutation();
  const [removeService] = useRemoveServiceFromSalesLeadMutation();
  const [isProductSelected, setIsProductSelected] = useState([]);
  const [isServiceSelected, setIsServiceSelected] = useState([]);
  const [logoBase64, setLogoBase64] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [itemType, setItemType] = useState(""); // To differentiate between product and service

  useEffect(() => {
    if (salesLead) {
      console.log("Sales Lead Data: ", salesLead);
    }
    if (settings) {
      const selectedLogo = settings?.[0]?.site_logo?.find(
        (setting) => setting.isSelected === true
      );
      if (selectedLogo) {
        const logoUrl = selectedLogo.site_logo_url;
        fetch(logoUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              setLogoBase64(reader.result);
            };
            reader.readAsDataURL(blob);
          })
          .catch((error) => console.error("Error fetching logo image:", error));
      }
    }
    if (services) {
      console.log("Services Data: ", services);
    }
  }, [salesLead, settings, services]);

  const handleIsSelectedExportCSV = async () => {
    let productData = [];
    let serviceData = [];
    let productHeaders = new Set();
    let serviceHeaders = new Set();
    // Process products
    salesLead.products.forEach((product, index) => {
      if (isProductSelected[index]) {
        const { _id, field, ...productWithoutId } = product;
        productData.push(productWithoutId);
        Object.keys(productWithoutId).forEach((key) =>
          productHeaders.add(key)
        );
      }
    });

    // Process services
    salesLead.services.forEach((service, index) => {
      if (isServiceSelected[index]) {
        const { _id, ...serviceWithoutId } = service;
        serviceData.push(serviceWithoutId);
        Object.keys(serviceWithoutId).forEach((key) =>
          serviceHeaders.add(key)
        );
      }
    });

    // Convert headers to array
    const productHeaderArray = Array.from(productHeaders);
    const serviceHeaderArray = Array.from(serviceHeaders);

    // Create CSV string
    const productCsv = [
      productHeaderArray.join(","), // product headers
      ...productData.map((row) =>
        productHeaderArray.map((field) => row[field] || "").join(",")
      ), // product rows
    ].join("\n");

    const serviceCsv = [
      serviceHeaderArray.join(","), // service headers
      ...serviceData.map((row) =>
        serviceHeaderArray.map((field) => row[field] || "").join(",")
      ), // service rows
    ].join("\n");

    const csv = [productCsv, serviceCsv].join("\n\n");
    const blob = new Blob([csv], { type: "text/csv" });

    // Write to clipboard
    await navigator.clipboard.writeText(csv);
  };

  const handleProductCheckboxChange = (event, index) => {
    setIsProductSelected({
      ...isProductSelected,
      [index]: event.target.checked,
    });
  };

  const handleServiceCheckboxChange = (event, index) => {
    setIsServiceSelected({
      ...isServiceSelected,
      [index]: event.target.checked,
    });
  };

  const handleGenerateSupplierRFQ = async () => {
    await createWordDoc({
      salesLead,
      settings,
      logoBase64,
      isProductSelected,
      isServiceSelected,
    });
  };

  const handleGenerateResponseToRFQ = async () => {
    await GenerateRFQResponseDoc({
      salesLead,
      settings,
      services,
      logoBase64,
      isProductSelected,
      isServiceSelected,
    });
  };

  const handleUploadRFQPricing = async () => {
    console.log("Upload RFQ Pricing");
    // Get pricing in JSON format, convert to object and send to backend
  };

  const handleAddProduct = async () => {
    const newProduct = {
      no: "New Product",
      description: "New Product Description",
      quantity: 1,
      uom: "PCS",
      price: 0,
    };
    await addProduct({ salesLeadId, product: newProduct });
    refetch(); // Refetch the data after adding a product
  };

  const handleUpdateProduct = async (updatedProduct) => {
    await updateProduct({ salesLeadId, productId: updatedProduct._id, product: updatedProduct });
    refetch(); // Refetch the data after updating a product
  };

  const handleDeleteProduct = async (productId) => {
    await removeProduct({ salesLeadId, productId });
    refetch(); // Refetch the data after deleting a product
  };

  const handleAddService = async () => {
    const newService = {
      no: "New Service",
      description: "New Service Description",
      quantity: 1,
      uom: "HOURS",
      price: 0,
    };
    await addService({ salesLeadId, service: newService });
    refetch(); // Refetch the data after adding a service
  };

  const handleUpdateService = async (updatedService) => {
    await updateService({ salesLeadId, serviceId: updatedService._id, service: updatedService });
    refetch(); // Refetch the data after updating a service
  };

  const handleDeleteService = async (serviceId) => {
    await removeService({ salesLeadId, serviceId });
    refetch(); // Refetch the data after deleting a service
  };

  const handleEditItem = (item, type) => {
    setCurrentItem(item);
    setItemType(type);
    setShowModal(true);
  };

  const handleSaveChanges = async (updatedItem) => {
    if (itemType === "product") {
      await handleUpdateProduct(updatedItem);
    } else if (itemType === "service") {
      await handleUpdateService(updatedItem);
    }
    setShowModal(false); // Close the modal after saving changes
  };

  return (
    <Container className="mt-4">
      <h1 className="mb-4 text-center">Sales Lead Details</h1>
      {loading || settingsLoading || servicesLoading ? (
        <Loader />
      ) : error || settingsError || servicesError ? (
        <Message variant="danger">
          {error || settingsError || servicesError}
        </Message>
      ) : (
        <>
          <Row className="mb-4">
            <Col>
              <Table striped bordered hover responsive className="table-sm">
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>COMPANY</th>
                    <th>EMAIL</th>
                    <th>PHONE</th>
                    <th>CATEGORY</th>
                    <th>MESSAGE</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{salesLead.name}</td>
                    <td>{salesLead.company}</td>
                    <td>{salesLead.email}</td>
                    <td>{salesLead.phone}</td>
                    <td>{salesLead.category}</td>
                    <td>{salesLead.message}</td>
                    <td>{salesLead.status}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <h3 className="d-flex justify-content-between align-items-center">
                Products
                <Button
                  variant="success"
                  onClick={handleAddProduct}
                  className="btn-sm"
                >
                  <FaPlusCircle /> Add Product
                </Button>
              </h3>
              <Table striped bordered hover responsive className="table-sm">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>DESCRIPTION</th>
                    <th>QUANTITY</th>
                    <th>UOM</th>
                    <th>PRICE</th>
                    <th>SELECT</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {salesLead.products && salesLead.products.length > 0 ? (
                    salesLead.products.map((product, index) => (
                      <tr key={product._id || index}>
                        <td>{product.no}</td>
                        <td>{product.description}</td>
                        <td>{product.quantity}</td>
                        <td>{product.uom}</td>
                        <td>{product.price}</td>
                        <td>
                          <input
                            type="checkbox"
                            onChange={(event) =>
                              handleProductCheckboxChange(event, index)
                            }
                          />
                        </td>
                        <td className="d-flex">
                          <Button
                            variant="light"
                            onClick={() => handleEditItem(product, "product")}
                            className="btn-sm mx-2"
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteProduct(product._id)}
                            className="btn-sm"
                          >
                            <FaTrash />
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No products available.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <h3 className="d-flex justify-content-between align-items-center">
                Services
                <Button
                  variant="success"
                  onClick={handleAddService}
                  className="btn-sm"
                >
                  <FaPlusCircle /> Add Service
                </Button>
              </h3>
              <Table striped bordered hover responsive className="table-sm">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>DESCRIPTION</th>
                    <th>QUANTITY</th>
                    <th>UOM</th>
                    <th>PRICE</th>
                    <th>SELECT</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {salesLead.services && salesLead.services.length > 0 ? (
                    salesLead.services.map((service, index) => (
                      <tr key={service._id || index}>
                        <td>{service.no}</td>
                        <td>{service.description}</td>
                        <td>{service.quantity}</td>
                        <td>{service.uom}</td>
                        <td>{service.price}</td>

                        <td>
                          <input
                            type="checkbox"
                            onChange={(event) =>
                              handleServiceCheckboxChange(event, index)
                            }
                          />
                        </td>
                        <td className="d-flex">
                          <Button
                            variant="light"
                            onClick={() => handleEditItem(service, "service")}
                            className="btn-sm mx-2"
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => handleDeleteService(service._id)}
                            className="btn-sm"
                          >
                            <FaTrash />
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No services available.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="d-flex justify-content-center">
              <Button className="mx-2" onClick={handleIsSelectedExportCSV}>
                <FaCopy className="icon" />
                Export Selected to CSV
              </Button>
              <Button className="mx-2" onClick={handleGenerateSupplierRFQ}>
                <FaFileWord className="icon" />
                Supplier RFQ
                <FaDownload className="icon mx-2" />
              </Button>
              <Button className="mx-2" onClick={handleGenerateResponseToRFQ}>
                <FaFileWord className="icon" />
                RFQ Response
                <FaDownload className="icon mx-2" />
              </Button>
              {/* <Button className="mx-2" onClick={handleUploadRFQPricing}>
                <FaFileCsv className="icon" />
                Upload RFQ Pricing
                <FaUpload className="icon mx-2" />
              </Button> */}
            </Col>
          </Row>

          <EditModal
            show={showModal}
            onHide={() => setShowModal(false)}
            item={currentItem}
            onSave={handleSaveChanges}
          />
        </>
      )}
    </Container>
  );
};

const EditModal = ({ show, onHide, item, onSave }) => {
  const [formData, setFormData] = useState({
    no: "",
    description: "",
    quantity: "",
    uom: "",
    price: "",
  });

  useEffect(() => {
    if (item) {
      setFormData({
        no: item.no || "",
        description: item.description || "",
        quantity: item.quantity || "",
        uom: item.uom || "",
        price: item.price || "",
      });
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    onSave({ ...item, ...formData });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit {item ? item.description : ""}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formNo">
            <Form.Label>No</Form.Label>
            <Form.Control
              type="text"
              name="no"
              value={formData.no}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formQuantity">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formUom">
            <Form.Label>UOM</Form.Label>
            <Form.Control
              type="text"
              name="uom"
              value={formData.uom}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesLeadsDetailsScreen;

