import { useGetTasksQuery } from "../slices/tasksApiSlice";
import Column from "./Column";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { useUpdateTaskMutation } from "../slices/tasksApiSlice";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useGetUserDetailsQuery } from "../slices/usersApiSlice";
import { useGetUsersQuery } from "../slices/usersApiSlice";
import { Container, Row, Col, Form, FloatingLabel } from "react-bootstrap";

const TaskGrid = () => {
  const { data: tasks, isLoading, error } = useGetTasksQuery();
  const [
    updateTask,
    { isLoading: updateTaskIsLoading, error: updateTaskError, refetch },
  ] = useUpdateTaskMutation();
  const {
    data: user,
    isLoading: loadingUsers,
    error: loadingUsersError,
  } = useGetUserDetailsQuery();
  const {
    data: users,
    isLoading: loadingAllUsers,
    error: usersError,
  } = useGetUsersQuery();
  const dispatch = useDispatch();

  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    // Initialize filteredTasks with all tasks
    if (tasks) {
      setFilteredTasks(tasks);
    }

    //filter tasks by assignee
    if (tasks && selectedUser) {
      setFilteredTasks(tasks.filter((task) => task.assignee === selectedUser));
    }
  }, [tasks, isLoading, loadingAllUsers, users, selectedUser]);

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return; // Drop outside the droppable area
    try {
      await dispatch(
        updateTask({
          id: result.draggableId,
          status: result.destination.droppableId,
        })
      ).unwrap();

      // Update the task status locally without resetting the filter
      setFilteredTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === result.draggableId
            ? { ...task, status: result.destination.droppableId }
            : task
        )
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {isLoading || loadingAllUsers ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        //create a drag and drop context for tasks filtered by assignee
        <>
          <Form>
            <FloatingLabel controlId="floatingSelect" label="Select User">
              <Form.Select
                aria-label="Floating label select example"
                onChange={handleUserChange}
              >
                <option value="">All Tasks</option>
                
                {users.map((user) => (
                  <option key={user.email} value={user.email}>
                    {user.email}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="board" direction="horizontal" type="COLUMN">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Container fluid>
                    <div className="row-flex">
                      <Col xs={6} sm={3} md={3} lg={3} className="content">
                        <Column
                          title="TODO"
                          tasks={filteredTasks?.filter(
                            (task) => task.status === "todo"
                          )}
                          column="todo"
                        />
                      </Col>
                      <Col xs={6} sm={3} md={3} lg={3} className="content">
                        <Column
                          title="DOING"
                          tasks={filteredTasks?.filter(
                            (task) => task.status === "doing"
                          )}
                          column="doing"
                        />
                      </Col>
                      <Col xs={6} sm={3} md={3} lg={3} className="content">
                        <Column
                          title="DONE"
                          tasks={filteredTasks?.filter(
                            (task) => task.status === "done"
                          )}
                          column="done"
                        />
                      </Col>
                      <Col xs={6} sm={3} md={3} lg={3} className="content">
                        <Column
                          title="BACKLOG"
                          tasks={filteredTasks?.filter(
                            (task) => task.status === "backlog"
                          )}
                          column="backlog"
                        />
                      </Col>
                    </div>
                    {provided.placeholder}
                  </Container>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </>
  );
};

export default TaskGrid;
