import { SALES_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const salesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSalesLeads: builder.query({
      query: () => ({
        url: SALES_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    createSalesLead: builder.mutation({
      query: (data) => ({
        url: SALES_URL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Sales"],
    }),
    getSalesLeadById: builder.query({
      query: (salesLeadId) => ({
        url: `${SALES_URL}/${salesLeadId}`,
      }),
    }),
    deleteSalesLead: builder.mutation({
      query: (salesLeadId) => ({
        url: `${SALES_URL}/${salesLeadId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Sales"],
    }),
    updateSalesLead: builder.mutation({
      query: (data) => ({
        url: `${SALES_URL}/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Sales"],
    }),
    
    // New endpoints for handling products
    addProductToSalesLead: builder.mutation({
      query: ({ salesLeadId, product }) => ({
        url: `${SALES_URL}/${salesLeadId}/products`,
        method: "POST",
        body: product,
      }),
      invalidatesTags: ["Sales"],
    }),
    updateProductInSalesLead: builder.mutation({
      query: ({ salesLeadId, productId, product }) => ({
        url: `${SALES_URL}/${salesLeadId}/products/${productId}`,
        method: "PUT",
        body: product,
      }),
      invalidatesTags: ["Sales"],
    }),
    removeProductFromSalesLead: builder.mutation({
      query: ({ salesLeadId, productId }) => ({
        url: `${SALES_URL}/${salesLeadId}/products/${productId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Sales"],
    }),

    // New endpoints for handling services
    addServiceToSalesLead: builder.mutation({
      query: ({ salesLeadId, service }) => ({
        url: `${SALES_URL}/${salesLeadId}/services`,
        method: "POST",
        body: service,
      }),
      invalidatesTags: ["Sales"],
    }),
    updateServiceInSalesLead: builder.mutation({
      query: ({ salesLeadId, serviceId, service }) => ({
        url: `${SALES_URL}/${salesLeadId}/services/${serviceId}`,
        method: "PUT",
        body: service,
      }),
      invalidatesTags: ["Sales"],
    }),
    removeServiceFromSalesLead: builder.mutation({
      query: ({ salesLeadId, serviceId }) => ({
        url: `${SALES_URL}/${salesLeadId}/services/${serviceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Sales"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSalesLeadsQuery,
  useCreateSalesLeadMutation,
  useGetSalesLeadByIdQuery,
  useDeleteSalesLeadMutation,
  useUpdateSalesLeadMutation,
  useAddProductToSalesLeadMutation,
  useUpdateProductInSalesLeadMutation,
  useRemoveProductFromSalesLeadMutation,
  useAddServiceToSalesLeadMutation,
  useUpdateServiceInSalesLeadMutation,
  useRemoveServiceFromSalesLeadMutation,
} = salesApiSlice;
