const ratecard = [
  {
    category: "Cleaning Services",
    frequency: "One_Time",
    type: "Home",
    components: [
    //generate randon mongodb id for each component
      { _id: Math.random(),  name:"general_clean", rate: 75 ,duration:3.5,variables: "Rooms",meta:"Based on 2 bedroom,2 bathroom house, 1 kitchen, 1 lounge"},
      {_id: Math.random(),   name:"general_clean", rate: 75 ,duration:3.5,variables: "Rooms",meta:"Based on 2 bedroom,2 bathroom house, 1 kitchen, 1 lounge"},
      {_id: Math.random(), name:"window_clean", rate: 75,duration:1.5,variables: "Windows",meta:"Based on 2 bedroom,2 bathroom house, 1 kitchen, 1 lounge"},
      {_id: Math.random(), name:"carpet_clean", rate: 150,duration:2,variables: "Rooms",meta:"Room size 3x3m"},
      {_id: Math.random(), name:"upholstery_clean",rate: 150,duration:2,variables: "Rooms",},
      {_id: Math.random(), name:"oven_clean", rate: 100,duration:1,variables: "Ovens",meta:"Standard size oven"},
      {_id: Math.random(), name:"inside_fridge", rate: 75,duration:1,variables: "Fridges",meta:"Standard domestic fridge, 500L"},
      {_id: Math.random(), name:"laundry", rate: 200,duration:2,variables: "Loads",meta:"5kg load"},
      {_id: Math.random(), name:"ironing",rate: 200,duration:2,variables: "Loads",meta:"Normal everyday fabrics, no high maintenance items"},
    ],
  },
  {
    category: "Cleaning Services",
    frequency: "Weekly",
    type: "Home",
    components: [
      { general_home_cleaning: 65 },
      { window_cleaning: 75 },
      { carpet_cleaning: 150 },
      { upholstery_cleaning: 150 },
      { oven_cleaning: 100 },
      { fridge_cleaning: 75 },
      { laundry: 200 },
      { ironing: 200 },
    ],
  },
  {
    category: "Cleaning Services",
    frequency: "One_Time",
    type: "Office",
    components: [
      { general_office_cleaning: 75 },
      { deep_cleaning: 100 },
      { window_cleaning: 75 },
      { carpet_cleaning: 150 },
      { upholstery_cleaning: 150 },
      { oven_cleaning: 100 },
      { fridge_cleaning: 75 },
      { laundry: 200 },
      { ironing: 200 },
    ],
  },
  {
    category: "Cleaning Services",
    frequency: "Weekly",
    type: "Office",
    components: [
      { general_office_cleaning: 75 },
      { deep_cleaning: 100 },
      { window_cleaning: 75 },
      { carpet_cleaning: 150 },
      { upholstery_cleaning: 150 },
      { oven_cleaning: 100 },
      { fridge_cleaning: 75 },
      { laundry: 200 },
      { ironing: 200 },
    ],
  },
  {
    category: "Cleaning Services",
    frequency: "One_Time",
    type: "Hotels",
    components: [
      { general_commercial_cleaning: 75 },
      { deep_cleaning: 100 },
      { window_cleaning: 75 },
      { carpet_cleaning: 150 },
      { upholstery_cleaning: 150 },
      { oven_cleaning: 100 },
      { fridge_cleaning: 75 },
      { laundry: 200 },
      { ironing: 200 },
    ],
  },
  {
    category: "Cleaning Services",
    frequency: "Weekly",
    type: "Hotels",
    components: [
      { general_commercial_cleaning: 75 },
      { deep_cleaning: 100 },
      { window_cleaning: 75 },
      { carpet_cleaning: 150 },
      { upholstery_cleaning: 150 },
      { oven_cleaning: 100 },
      { fridge_cleaning: 75 },
      { laundry: 200 },
      { ironing: 200 },
    ],
  },
  {
    category: "General Pest Control",
    frequency: "One_Time",
    type: "Pest_Control",
    components: [
      { general_pest_control: 75 },
      { bed_bug_control: 75 },
      { cockroach_control: 150 },
    ],
  },
  {
    category: "General Pest Control",
    frequency: "Monthly",
    type: "Pest_Control",
    components: [
      { general_pest_control: 75 },
      { rodent_control: 100 },
      { bed_bug_control: 75 },
      { cockroach_control: 150 },
      { termite_control: 150 },
      { flea_control: 100 },
      { tick_control: 75 },
      { mosquito_control: 200 },
      { spider_control: 200 },
    ],
  },

  {
    category: "Agent Renumerations",
    type: "Agent Rates",
    //agent should receive 75% of the total amount, the remaining 25% goes to the company -
    components: [
      //this rate is for the first 37.5 hours of work, in a month
      { standard_rate: 75 * 0.75 * "Hours" },
      //this rate is for the next 37.5 hours of work, in a month
      { overtime_rate: 75 * 0.75 * "Hours" * 1.5 },
      //bonus for every 5 star rating
      { top_rating_bonus: 75 * 0.75 * 0.1 },
      //bonus for every 200 hours worked
      { loyalty_bonus: 75 * 0.75 * 0.1 },
    ],
  },
  {
    category: "Service Provider Rates",
    type: "Pest Control Service Provider Rates",
    components: [
      { provider_rate: 75 * 0.75 * "Hours" },
      { provider_overtime_rate: 75 * 0.75 * "Hours" * 1.5 },
    ],
  },
];

export default ratecard;
