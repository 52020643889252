/* export const BASE_URL = process.env.NODE_ENV === 'development' ? 
'http://localhost:5000' : ''; */
export const BASE_URL = '';
export const PRODUCTS_URL = `/api/products`;
export const SERVICES_URL = `/api/services`;
export const USERS_URL = `/api/users`;
export const ORDERS_URL = `/api/orders`;
export const SALES_URL = `/api/sales`;
export const BMC_URL = `/api/bmc`;
export const TASKS_URL = `/api/tasks`;
export const SETTINGS_URL = `/api/settings`;
export const PARTNERS_URL = `/api/partners`;
export const TEMPLATES_URL = `/api/templates`;
export const UPLOADS_URL = `/api/upload`;