import {useState, useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useGetBMCNoteByIdQuery,useUpdateBMCNoteMutation,} from '../../slices/bmcApiSlice'
import {useGetUsersQuery} from '../../slices/usersApiSlice'    
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Header from '../../components/Header'
import FormContainer from '../../components/FormContainer'
import { FaArrowRight,FaArrowLeft} from 'react-icons/fa';
import {Form, Button} from 'react-bootstrap'

const EditBMCNote = () => {
    const {block_id, note_id} = useParams()
    const navigate = useNavigate()
    const [noteData, setNoteData] = useState({name:'', description:'', status:'', assignee:''})
    const {data:note, isLoading:loadingNote, refetch, error:loadingNoteError} = useGetBMCNoteByIdQuery({block_id,note_id}) //get the  note by id from block and set data for form display
    const [updateBMCNote, {isLoading: loadingUpdate}] = useUpdateBMCNoteMutation()
    const {data:users, isLoading:loadingUsers, error} = useGetUsersQuery()
   console.log(note)
    useEffect(() => {
        if(note){
           
            setNoteData({id:note[0]._id, name:note[0].note_name, description:note[0].note_description, status:note[0].note_status, assignee:note[0].note_assignee})
            console.log(noteData)
        }
    }
    ,[note, refetch]) 

    const submitHandler = async(e) => {
        e.preventDefault()
        try{
            const fd = new FormData(e.target)
            const noteForm = fd.getAll('noteData')
            const data = Object.fromEntries(fd.entries())
            data.noteData = noteForm
            // data.id =note._id //inject id into data for update route
            console.log(data)
            await updateBMCNote({block_id,note_id, data})
            toast.success('Note added/updated successfully')
            navigate('/admin/bmcnotes')
        }catch(err){
            toast.error(err?.data?.message || err.error)
        }
    }

    const handleOnEventChange = (identifier, value) => {
      setNoteData({...noteData, [identifier]: value})
    }

  return (
    <>
    <main>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">{/* Your content */}
        <button className='button-28' ><Link to='/admin/bmcnotes'> Go Back <FaArrowLeft/></Link></button>
       
        {loadingUsers ? <Loader/>:loadingNote ? <Loader/>: loadingUpdate ? <Loader/>: error ? <Message variant='danger'>{error}</Message> : 
        <FormContainer title="Edit BMC Note">
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
            </div>
            </div>
        {note ?
        <Form onSubmit={submitHandler}>
        <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control type='text' name='name' placeholder='Enter name' value={noteData.name} onChange = {(event) => handleOnEventChange('name',event.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group controlId='description'>
            <Form.Label>Description</Form.Label>
            <Form.Control type='text' name='description' placeholder='Enter description' value={noteData.description} onChange = {(event) => handleOnEventChange('description',event.target.value)}></Form.Control>
        </Form.Group>
        <Form.Select name="status" value={noteData.status} onChange = {(event) => handleOnEventChange('status',event.target.value)}>
        <option value="">--Please choose an option--</option>
            <option value="todo">Todo</option>
            <option value="doing">Doing</option>
            <option value="done">Done</option>
            <option value="backlog">Backlog</option>
        </Form.Select>
        <Form.Select name="assignee" value={noteData.assignee} onChange = {(event) => handleOnEventChange('assignee',event.target.value)}>  
        <option value="">--Please choose an option--</option>
        {users?.map((user) => (
            <option key={user._id} value={user._id}>{user.name}</option>
        ))}
        </Form.Select>
        
        <button type='submit' 
                className='button-28'> 
                Update <FaArrowRight/>
        </button> 
    </Form>
      :
      <>
       <p>Task not found!</p>
       <Button>Go Back</Button>
      </>
     
    }
    </FormContainer>
        }
  </div>
  </main>
    </>
  )
}
export default EditBMCNote