import  {useEffect, useState} from 'react'
import {Link, json, useNavigate,useParams} from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {useGetServicesQuery, useCreateServiceMutation, useUpdateServiceMutation, useGetServiceByIdQuery} from '../../slices/servicesApiSlice'
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import FormContainer from '../../components/FormContainer'
import { FaArrowRight,FaEnvelope} from 'react-icons/fa';

const ServiceEditScreen = () => {
    const {id:serviceId} = useParams()
    const [category, setCategory] = useState('')
    const [component, setComponent] = useState([])
    const {data:service, isLoading, error, refetch} = useGetServiceByIdQuery(serviceId)
    const [updateService, {isLoading:LoadingUpdate}] = useUpdateServiceMutation()
    const navigate = useNavigate()
  
    useEffect(() => {
        if(service){
            setCategory(service.category)
            let comp = JSON.parse(JSON.stringify(service.component)) //make a deep copy of the service.component array - write to comp
            setComponent(comp)
        }
    }
    , [service])

    const handleInputChange = (identifier, value) => {
        console.log(identifier)
        console.log(value)
        console.log(service.component.map(comp =>(comp._id=== identifier? {...comp, name:value}: comp)  ))
        //{name:value, description:value}
        const formData = service.component.map((comp) =>{return (comp._id=== identifier? {...comp, name:value}: comp)})
        console.log(formData)
        setComponent(formData)
    }
  return (
    <>
    <Link to='/admin/servicelist' className='btn btn-light my-3'>
        Go Back
    </Link>
    <FormContainer title="Services">
        <h1>Edit Service</h1>
        {LoadingUpdate && <Loader/>}
        {error && <Message variant='danger'>{error?.data?.message || error.error }</Message>}
        {isLoading ? (<Loader/>) :
        (
            <Form onSubmit={async (e) => {
                e.preventDefault()
                try {
                    await updateService({_id:serviceId, category, component})
                    toast.success('Service Updated Successfully')
                    navigate('/admin/servicelist')
                } catch (err) {
                    toast.error(err?.data?.message || err.error)
                }
            }}>
                    <Form.Group controlId='category'>
                        <Form.Label>Service Category</Form.Label>
                        <Form.Control type='name' placeholder='Enter category' name="category" value={category} onChange={(e)=> setCategory(e.target.value)}></Form.Control>
                    </Form.Group>
               {service.component.map(component => (
                    <>
                  <Row>
                    <Col>
                        <Form.Label>Component Name</Form.Label>
                        <Form.Control id={component._id} type = 'compname' placeholder="service1" name="name" value={component.name} onChange={(event) => handleInputChange(event.target.id,event.target.value)}/>
                    </Col>
                    <Col>
                    <Form.Label>Component Description</Form.Label>
                        <Form.Control id ={component._id} type ='compdescription' placeholder="descripton1" value={component.description}/>
                    </Col>
                  </Row>
                    </>
                 ))}  
                 <button type='submit' disabled={isLoading} 
                    className='button-28'> 
                    Sumbit Enquiry <FaArrowRight/>
                </button> 
            </Form>
        )}
    </FormContainer>
    </>
  )
}
export default ServiceEditScreen