import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  Header,
  Footer,
  WidthType,
  PageNumber,
  AlignmentType,
  TabStopType,
  TabStopPosition,
  UnderlineType,
  ShadingType,
  ImageRun,
  convertInchesToTwip,
  VerticalAlign
} from "docx";
import { saveAs } from "file-saver";

const CreateWordDoc = async ({ salesLead, settings, logoBase64 }) => {
  try {
      const contacts = settings[0]?.site_contacts || [];



      const doc = new Document({
          styles: {
              paragraphStyles: [
                  {
                      id: "mediumPoppins",
                      name: "Medium Poppins",
                      basedOn: "Normal",
                      run: {
                          font: "Poppins",
                          size: 24,
                      },
                      paragraph: {
                          spacing: {
                              line: 480, // Double spacing (240 is single spacing, 480 is double spacing)
                          },
                      },
                  },
                  {
                      id: "headerFooter",
                      name: "Header and Footer",
                      basedOn: "Normal",
                      run: {
                          font: "Poppins",
                          size: 20, // 10pt font size
                      },
                  },
              ],
          },
          sections: [
              {
                  headers: {
                      default: createHeader(logoBase64, contacts),
                  },
                  footers: {
                    default:createFooter(),
                    },
                  children: [
                      new Paragraph({
                          children: [
                              new TextRun({
                                  text: "REQUEST FOR QUOTATION:",
                                  bold: true,
                                  underline: {
                                      type: UnderlineType.SINGLE,
                                  },
                                  break: 3,
                              }),
                          ],
                          alignment: AlignmentType.CENTER,
                          style: "mediumPoppins",
                      }),
                      new Paragraph({
                          text: `Date: ${new Date().toLocaleDateString()}`,
                          heading: "Heading2",
                          style: "mediumPoppins",
                      }),
                      new Paragraph({
                          text: "Products",
                          heading: "Heading2",
                          style: "mediumPoppins",
                          break: 5,
                      }),
                      await createProductsTable(salesLead.products),
                      new Paragraph({
                          text: "Services",
                          heading: "Heading2",
                          style: "mediumPoppins",
                          break: 5,
                      }),
                      await createServicesTable(salesLead.services),
                  ],
              },
          ],
      });

      const blob = await Packer.toBlob(doc);
      saveAs(blob, "SupplierRFQ.docx");
  } catch (error) {
      console.error("Error generating document", error);
  }
};

const createHeader = (logoBase64, contacts) => {
  return new Header({
      children: [
          new Table({
              width: { size: 100, type: WidthType.PERCENTAGE },
              rows: [
                  new TableRow({
                      children: [
                          new TableCell({
                              width: { size: 50, type: WidthType.PERCENTAGE },
                              children: [
                                  new Paragraph({
                                      children: [
                                          new ImageRun({
                                              data: logoBase64,
                                              transformation: {
                                                  width: 100,
                                                  height: 100,
                                              },
                                          }),
                                      ],
                                      style: "headerFooter",
                                  }),
                              ],
                          }),
                          new TableCell({
                              width: { size: 50, type: WidthType.PERCENTAGE },
                              children: contacts
                                  .filter(
                                      (contact) =>
                                          contact.site_contact_name === "email" ||
                                          contact.site_contact_name === "phone" ||
                                          contact.site_contact_name === "whatsapp" ||
                                          contact.site_contact_name === "website"
                                  )
                                  .map(
                                      (contact) =>
                                          new Paragraph({
                                              children: [
                                                  new TextRun({
                                                      text: `${contact.site_contact_url}`,
                                                  }),
                                              ],

                                              style: "headerFooter",
                                              alignment: AlignmentType.RIGHT,
                                              break: 3,
                                          })
                                  ),
                          }),
                      ],
                  }),
              ],
          }),
      ],
  });
};

const createFooter = () => {
    return new Footer({
      children: [
        new Table({
          width: { size: 100, type: WidthType.PERCENTAGE },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  width: { size: 25, type: WidthType.PERCENTAGE },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Request For Quotation`,
                          bold: true,
                          size: 16, // This size is in half-points, so 20 means 10pt
                        }),
                      ],
                      alignment: AlignmentType.VERTICAL_CENTER,
                    }),
                  ],
                }),
                new TableCell({
                  width: { size: 75, type: WidthType.PERCENTAGE },
                  VerticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `Extemelyclean PTY(LTD)`,
                          bold: true,
                          size: 16, // This size is in half-points, so 20 means 10pt
                        }),
                      ],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  width: { size: 100, type: WidthType.PERCENTAGE },
                  VerticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: "Page ",
                          bold: true,
                          size: 18,
                        }),
                        new TextRun({
                          children: [PageNumber.CURRENT],
                          field: true,
                          bold: true,
                          size: 18,
                        }),
                        new TextRun({
                          text: " of ",
                          bold: true,
                          size: 18,
                        }),
                        new TextRun({
                          children: [PageNumber.TOTAL_PAGES],
                          field: true,
                          bold: true,
                          size: 18,
                        }),
                      ],
                      alignment: AlignmentType.CENTER,
                      tabStops: [
                        {
                          type: TabStopType.RIGHT,
                          position: TabStopPosition.MAX,
                        },
                      ],
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    });
  };  
  const createProductsTable = async (products) => {
    const headerRow = new TableRow({
      children: [
        new TableCell({
          width: { size: 20, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "NO",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
        new TableCell({
          width: { size: 40, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "DESCRIPTION",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
        new TableCell({
          width: { size: 10, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "QUANTITY",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
        new TableCell({
          width: { size: 10, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "UOM",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
        new TableCell({
          width: { size: 20, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "PRICE",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
      ],
    });
  
    const dataRows = products.map((product, index) =>
      new TableRow({
        children: [
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: product.no,
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
          new TableCell({
            width: { size: 40, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: product.description,
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
          new TableCell({
            width: { size: 10, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: product.quantity.toString(),
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
          new TableCell({
            width: { size: 10, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: product.uom,
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: "", // Empty cell for PRICE column
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
        ],
      })
    );
  
    const subTotal = products.reduce((total, product) => total + (product.price || 0) * product.quantity, 0);
    const vat = subTotal * 0.15;
    const total = subTotal + vat;
  
    const totalsRow = [
      new TableRow({
        children: [
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: "SUB-TOTAL",
                bold: true,
                allCaps: true,
                style: "mediumPoppins",
              }),
            ],
            shading: { type: ShadingType.SOLID, color: "#fa9000" },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: "",
                bold: true,
                style: "mediumPoppins",
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: "VAT @15%",
                bold: true,
                allCaps: true,
                style: "mediumPoppins",
              }),
            ],
            shading: { type: ShadingType.SOLID, color: "#fa9000" },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: "",
                bold: true,
                style: "mediumPoppins",
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: "TOTAL",
                bold: true,
                allCaps: true,
                style: "mediumPoppins",
              }),
            ],
            shading: { type: ShadingType.SOLID, color: "#fa9000" },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: "",
                bold: true,
                style: "mediumPoppins",
              }),
            ],
          }),
        ],
      }),
    ];
  
    return new Table({
      width: { size: 100, type: WidthType.PERCENTAGE },
      rows: [headerRow, ...dataRows, ...totalsRow],
    });
  };
  
  const createServicesTable = async (services) => {
    const headerRow = new TableRow({
      children: [
        new TableCell({
          width: { size: 20, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "NO",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
        new TableCell({
          width: { size: 40, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "DESCRIPTION",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
        new TableCell({
          width: { size: 10, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "QUANTITY",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
        new TableCell({
          width: { size: 10, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "UOM",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
        new TableCell({
          width: { size: 20, type: WidthType.PERCENTAGE },
          children: [
            new Paragraph({
              text: "PRICE",
              bold: true,
              allCaps: true,
              style: "mediumPoppins",
            }),
          ],
          shading: { type: ShadingType.SOLID, color: "#fa9000" },
        }),
      ],
    });
  
    const dataRows = services.map((service, index) =>
      new TableRow({
        children: [
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: service.no,
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
          new TableCell({
            width: { size: 40, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: service.description,
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
          new TableCell({
            width: { size: 10, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: service.quantity.toString(),
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
          new TableCell({
            width: { size: 10, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: service.uom,
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: "", // Empty cell for PRICE column
                style: "mediumPoppins",
              }),
            ],
            shading: index % 2 === 0 ? { type: ShadingType.SOLID, color: "#D3D3D3" } : {},
          }),
        ],
      })
    );
  
    const subTotal = services.reduce((total, service) => total + (service.price || 0) * service.quantity, 0);
    const vat = subTotal * 0.15;
    const total = subTotal + vat;
  
    const totalsRow = [
      new TableRow({
        children: [
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: "SUB-TOTAL",
                bold: true,
                allCaps: true,
                style: "mediumPoppins",
              }),
            ],
            shading: { type: ShadingType.SOLID, color: "#fa9000" },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: "",
                bold: true,
                style: "mediumPoppins",
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: "VAT @15%",
                bold: true,
                allCaps: true,
                style: "mediumPoppins",
              }),
            ],
            shading: { type: ShadingType.SOLID, color: "#fa9000" },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: "",
                bold: true,
                style: "mediumPoppins",
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            children: [],
          }),
          new TableCell({
            width: { size: 20, type: WidthType.PERCENTAGE },
            children: [
              new Paragraph({
                text: "TOTAL",
                bold: true,
                allCaps: true,
                style: "mediumPoppins",
              }),
            ],
            shading: { type: ShadingType.SOLID, color: "#fa9000" },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: "",
                bold: true,
                style: "mediumPoppins",
              }),
            ],
          }),
        ],
      }),
    ];
  
    return new Table({
      width: { size: 100, type: WidthType.PERCENTAGE },
      rows: [headerRow, ...dataRows, ...totalsRow],
    });
  };
  

export default CreateWordDoc;
