import { useCreateSalesLeadMutation } from "../slices/salesApiSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  FloatingLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FormContainer from "../components/FormContainer";
import { FaArrowRight, FaEnvelope } from "react-icons/fa";

const ContactScreen = () => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [createSalesLead, { isLoading, error }] = useCreateSalesLeadMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await createSalesLead({
        name,
        company,
        email,
        phone,
        category,
        message,
      }).unwrap();
      dispatch({ ...res });
      toast.success("Form submitted successfully");
      navigate("/");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <FormContainer title="Contact Us">
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="name" className="my-3">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Full Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="company" className="my-3">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Company (Optional) "
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="email" className="my-3">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="phone" className="my-3">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Phone"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="category" className="my-3">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Category"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="message" className="my-3">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Message"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></Form.Control>
                </FloatingLabel>
              </Form.Group>
              <h4>Where did you hear about us?</h4>
              <Form.Group controlId="check" className="my-3">
                <Form.Check
                  type="checkbox"
                  id={"check"}
                  label={"Internet Marketing"}
                />
                <Form.Check
                  type="checkbox"
                  id={"check"}
                  label={"Social Media"}
                />
                <Form.Check type="checkbox" id={"check"} label={"Referral"} />
                <Form.Check type="checkbox" id={"check"} label={"Other"} />
              </Form.Group>

              <button type="submit" disabled={isLoading} className="button-28">
                Sumbit Enquiry <FaArrowRight />
              </button>
              {isLoading && <Loader />}
            </Form>
          </FormContainer>
        </>
      )}
    </>
  );
};
export default ContactScreen;
