import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Form, Button, ListGroup, Container, Row, Col } from 'react-bootstrap';

const socket = io('http://localhost:5001'); // Replace with your server's URL

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Listen for messages from the server
        socket.on('chat message', (msg) => {
            setMessages((prevMessages) => [...prevMessages, msg]);
        });

        // Cleanup the socket connection when component unmounts
        return () => {
            socket.off('chat message');
        };
    }, []);

    const sendMessage = (e) => {
        e.preventDefault();
        if (message) {
            socket.emit('chat message', message);
            setMessage(''); // Clear the input field after sending
        }
    };

    return (
        <Container className="my-4">
            <Row className="justify-content-center">
                <Col md={6}>
                    <h3 className="text-center mb-4">Chat Room</h3>
                    <ListGroup variant="flush" id="messages" className="mb-3">
                        {messages.map((msg, index) => (
                            <ListGroup.Item key={index}>
                                {msg}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <Form onSubmit={sendMessage} className="d-flex">
                        <Form.Control
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Enter your message"
                            autoComplete="off"
                            className="me-2"
                        />
                        <Button type="submit" variant="primary">
                            Send
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Chat;
