
import Message from '../components/Message'
import Loader from '../components/Loader'
import {useGetBMCQuery} from '../slices/bmcApiSlice'
import { Container, Row, Col, Card } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { useEffect, useState } from 'react'
import BmcNotes from '../components/BmcNotes'
import { Droppable } from '@hello-pangea/dnd'; 
import { FaEllipsisH,FaPlusCircle } from 'react-icons/fa';
import { Stack,Nav} from 'react-bootstrap' 


const BmcBlock = ({block_id,key,title,notes,index}) => {
   
    const {data:canvas, isLoading, error} = useGetBMCQuery()
    return (
        <>
          {isLoading ? (<Loader/>) :  error ? (<Message variant='danger'>{error?.data?.message || error.error }</Message>):
          (
              <Droppable droppableId="board" direction="horizontal" type="COLUMN">
              {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="scroll">
                          <div class="custom-kanban-card-header ">
                            <Stack direction="horizontal" gap={4}> 
                              <h5 className="text-center">{title}</h5>
                              <Link to={`/admin/add_bmc_note/${block_id}`} className="text-sm font-semibold leading-6">
                                <FaPlusCircle className="h-5 w-5" aria-hidden="true" />
                              </Link>
                              <Nav.Link to="/admin/edit_task/"><FaEllipsisH/></Nav.Link>
                            </Stack>
                          </div>
                            
                          {notes.map((note) => {
                             console.log(block_id)
                            return (<BmcNotes key={note._id} note={note} index={index} block_id={block_id} />)
                          }
                          )}
                          {provided.placeholder}
                  </div>
                  )}
              </Droppable>
            )}
        </>
    ) //end of return
}//end of function
export default BmcBlock