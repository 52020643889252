import { useCallback, useEffect, useState } from "react";
import { Form, Row, Col, Card, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OrderSummary from "../components/OrderSummary";
import { useDispatch, useSelector } from "react-redux";
import { useGetServicesQuery } from "../slices/servicesApiSlice";
import {
  addToCart,
  updateCart,
  clearCart,
  removeFromCart,
} from "../slices/cartSlice";

const SchedulerCheckout = (props) => {
  const navigate = useNavigate();
  const [radioTimesWeek, setRadioTimesWeek] = useState(1);
  const [selectedDaysPerWeek, setSelectedDaysPerWeek] = useState(["Mon"]);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.cart);

  useEffect(() => {
    const existingItem = state.cartItems.find(
      (item) => item.name === "Basic Clean" || "Office Clean" || "Hotel Clean"
    );

    if (existingItem) {
      dispatch(
        updateCart({
          ...existingItem,
          type: "Recurring",
          rate: 60,
          days: selectedDaysPerWeek,
        })
      );
    }

    const existingItemScheduler = state.cartItems.find(
      (item) => item.name === "Scheduler"
    );
    if (!existingItemScheduler) {
      dispatch(
        addToCart({
          name: "Scheduler",
          type: "Schedule",
          rate:60,
          
          duration: radioTimesWeek,
          days: selectedDaysPerWeek,
        })
      );
    }

    //user may have added  more then one task, map through and remove all
    const existingTasks = state.cartItems.filter(
      (item) => item.type === "Task"
    );
    if (existingTasks.length > 0) {
      console.log("existingTasks", existingTasks);
      existingTasks.map((item) => {
        dispatch(removeFromCart(item._id));
      });
    }
  }, [radioTimesWeek, selectedDaysPerWeek, dispatch]);

  const handleRadioTimesWeek = useCallback(
    (e) => {
      setRadioTimesWeek(parseInt(e.target.value));
      const existingItem = state.cartItems.find(
        (item) => item.name === "Scheduler"
      );
      if (existingItem) {
        dispatch(
          updateCart({
            ...existingItem,
            duration: radioTimesWeek,
            days: selectedDaysPerWeek,
          })
        );
      }
    },
    [dispatch, radioTimesWeek, selectedDaysPerWeek, state.cartItems]
  );

  const handleCheckboxChange = useCallback(
    (e) => {
      if (
        selectedDaysPerWeek.length > radioTimesWeek ||
        selectedDaysPerWeek.length === radioTimesWeek
      ) {
        alert("You are trying to select more days than times a week");
        e.target.checked = false;
        return;
      }

      const selectedDay = e.target.value;
      const updatedDays = e.target.checked
        ? [...selectedDaysPerWeek, selectedDay]
        : selectedDaysPerWeek.filter((day) => day !== selectedDay);

      setSelectedDaysPerWeek(updatedDays);

      const existingItem = state.cartItems.find(
        (item) => item.name === "Scheduler"
      );
      if (existingItem) {
        dispatch(
          updateCart({
            ...existingItem,
            duration: radioTimesWeek,
            days: updatedDays,
          })
        );
      }
    },
    [dispatch, radioTimesWeek, selectedDaysPerWeek, state.cartItems]
  );

  const formCheckFieldGenerator = (label, type) => {
    return (
      <Col>
        <Form.Check
          onChange={handleCheckboxChange}
          inline
          type={type}
          value={label}
          defaultChecked={label === "Mon"} // Set defaultChecked to true if label is 'Mon'
          name="rate"
          id="default-checkbox"
          label={label}
        />
      </Col>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    navigate("/serviceform/step2");
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Card className="card-checkout">
          <Card.Title className="text-center">Scheduler</Card.Title>
          <Card.Text className="text-center">
            <strong>How many times a Week</strong>
          </Card.Text>
          <Stack className="mx-3" direction="horizontal" gap={3}>
            <Form.Check
              inline
              type="radio"
              value="1"
              defaultChecked
              name="rate"
              id="default-checkbox"
              label="1"
              onClick={handleRadioTimesWeek}
            />
            <Form.Check
              inline
              type="radio"
              value="2"
              name="rate"
              id="default-checkbox"
              label="2"
              onClick={handleRadioTimesWeek}
            />

            <Form.Check
              inline
              type="radio"
              value="3"
              name="rate"
              id="default-checkbox"
              label="3"
              onClick={handleRadioTimesWeek}
            />
            <Form.Check
              inline
              type="radio"
              value="4"
              name="rate"
              id="default-checkbox"
              label="4"
              onClick={handleRadioTimesWeek}
            />
          </Stack>
          <hr />

          <Card.Text className="mt-1 text-center">
            <strong>Choose your days</strong>
          </Card.Text>
          <Row className="m-1" gap={2}>
            {formCheckFieldGenerator("Mon", "checkbox")}
            {formCheckFieldGenerator("Tue", "checkbox")}
            {formCheckFieldGenerator("Wed", "checkbox")}
            {formCheckFieldGenerator("Thu", "checkbox")}
            {formCheckFieldGenerator("Fri", "checkbox")}
            {formCheckFieldGenerator("Sat", "checkbox")}
            {formCheckFieldGenerator("Sun", "checkbox")}
          </Row>
        </Card>
      </Form.Group>
      <hr />
      <OrderSummary />
    </>
  );
};

export default SchedulerCheckout;
