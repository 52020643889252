import { createSlice } from "@reduxjs/toolkit";
import { updateTotal } from "../utils/cartUtils";

const cartData = localStorage.getItem("cart");
const initialState =
  cartData && isValidJson(cartData)
    ? JSON.parse(cartData)
    : {
        cartItems: [],
        serviceAddress: {},
        extremeAgent: {},
        paymentMethod: "PayFast",
      };

function isValidJson(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const newItem = action.payload;
      console.log(newItem);
      const existingItem = state.cartItems.find(
        (item) => item._id === newItem._id
      );
      if (!existingItem) {
        state.cartItems = [...state.cartItems, newItem];
      }
      localStorage.setItem("cart", JSON.stringify(state));
      updateTotal(state);
    },
    updateCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.cartItems.find(
        (item) => item._id === newItem._id
      );
      if (existingItem) {
        state.cartItems = state.cartItems.map((item) =>
          item._id === existingItem._id ? newItem : item
        );
      }
      localStorage.setItem("cart", JSON.stringify(state));
      updateTotal(state);
    },
    removeFromCart(state, action) {
      const id = action.payload;
      state.cartItems = state.cartItems.filter((item) => item._id !== id);
      localStorage.setItem("cart", JSON.stringify(state));
      updateTotal(state);
    },
    removeFromCartByName(state, action) {
      const name = action.payload;
      state.cartItems = state.cartItems.filter((item) => item.name !== name);
      localStorage.setItem("cart", JSON.stringify(state));
      updateTotal(state);
    },
    saveServiceAddress(state, action) {
      state.serviceAddress = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    saveBookingDate(state, action) {
      state.bookingDate = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    saveExtremeAgent(state, action) {
      state.extremeAgent = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    savePaymentMethod(state, action) {
      state.paymentMethod = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    clearCart(state) {
      state.cartItems = [];
      state.serviceAddress = {};
      state.extremeAgent = {};
      state.bookingDate = {};
      state.paymentMethod = "PayFast";
      localStorage.removeItem("cart");
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  removeFromCartByName,
  updateCart,
  saveServiceAddress,
  saveBookingDate,
  saveExtremeAgent,
  savePaymentMethod,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
