import { USERS_URL,UPLOADS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/auth`,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${USERS_URL}/logout`,
        method: "POST",
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `${USERS_URL}/${id}`,
        method: "DELETE",
      }),
    }),
    getUsers: builder.query({
      query: () => ({
        url: `${USERS_URL}`,
      }),
      providesTags: ["Users"],
      keepUnusedDataFor: 5,
    }),
    getUserDetails: builder.query({
      query: (id) => ({
        url: `${USERS_URL}/profile/${id}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getAgents: builder.query({
      query: () => ({
        url: `${USERS_URL}/agents`,
      }),
      providesTags: ["Agents"],
      keepUnusedDataFor: 5,
    }),
    createAgentsCV: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/agents/${data.id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Agents"],
    }),
    updateAgentsCV: builder.mutation({
      query: ({agentId,data}) => ({
        url: `${USERS_URL}/agents/${agentId }`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Agents"],
    }),

    updateUserProfile: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/profile/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    uploadUserAvatar: builder.mutation({
      query: (data) => ({
        url: `${UPLOADS_URL}`,
        method: "POST",
        body: data,
      }),
    }),
    uploadUserDocuments: builder.mutation({
      query: (data) => ({
        url: `${UPLOADS_URL}/documents`,
        method: "POST",
        body: data,
      }),
    }),
    getUserAvatar: builder.query({
      query: (id) => ({
        url: `${USERS_URL}/avatar/`,
      }),
      keepUnusedDataFor: 5,
    }),
    resetForgotenPassword: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/forgotpassword`,
        method: "POST",
        body: data,
      }),
    }),

  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useGetUserDetailsQuery,
  useUpdateUserProfileMutation,
  useGetAgentsQuery,
  useCreateAgentsCVMutation,
  useUpdateAgentsCVMutation,
  useUploadUserAvatarMutation,
  useGetUserAvatarQuery,
  useUploadUserDocumentsMutation,
  useResetForgotenPasswordMutation,
} = usersApiSlice;
