import { Table, Row, Accordion, Button } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import { FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useGetPartnersQuery,useDeletePartnerMutation } from "../../slices/partnersApiSlice";
import PartnerModal from "./PartnerModal";
import { toast } from "react-toastify";


const PartnersScreen = () => {
  const { data: partners, isLoading, error,refetch } = useGetPartnersQuery();
  const [deletePartner] = useDeletePartnerMutation();

  const [showModal, setShowModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);


  const handleAddPartner = () => {
    setSelectedPartner(null);
    setShowModal(true);
  };
  const handleEditPartner = (partnerId) => {
    const Partner = partners.find((p) => p._id === partnerId);
    setSelectedPartner(Partner);
    setShowModal(true);
  };
  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete this Partner?")) {
      try {
        await deletePartner(id);
        toast.success("Partner deleted successfully");
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Row>
              <Accordion.Header>
                <h1>Partners</h1>
                <Button
                  variant="light"
                  className="btn-sm"
                  onClick={handleAddPartner}
                >
                  <FaPlusCircle />
                </Button>
              </Accordion.Header>
              <Accordion.Body>
                <Table striped hover responsive className="table-sm">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>CATEGORY</th>
                      <th>WEBSITE</th>
                      <th>EMAIL</th>
                      <th>PHONE</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {partners?.map((partner) => (
                      <tr key={partner._id}>
                        <td>{partner.name}</td>
                        <td>{partner.category}</td>
                        <td>{partner.website}</td>
                        <td>{partner.email}</td>
                        <td>{partner.phone}</td>
                        <td>
                          <Button
                            variant="light"
                            className="btn-sm mx-2"
                            onClick={() => handleEditPartner(partner._id)}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            variant="danger"
                            className="btn-sm"
                            onClick={() => deleteHandler(partner._id)}
                          >
                            <FaTrash style={{ color: "white" }} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Row>
          </Accordion.Item>
        </Accordion>
          <PartnerModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          partner={selectedPartner}
        />
        </>
      )}
    </>
  );
};

export default PartnersScreen;
