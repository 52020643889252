import { Container, Row, Col, Stack, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaLinkedinIn,
  FaWhatsapp,
  FaHome,
  FaEnvelope,
  FaLocationArrow,
} from "react-icons/fa";
import { useGetSettingsQuery } from "../slices/settingsApiSlice";
import { useEffect } from "react";
import Loader from "./Loader";
import Message from "./Message";
import fallback_logo from "../assets/img/logo2.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { data: settings, isLoading, error } = useGetSettingsQuery();

  useEffect(() => {
    if (settings && settings.length > 0) {
      document.documentElement.style.setProperty(
        "--footer",
        settings[0].site_palette[4].site_palette_color
      );
    }
  }, [settings]);

  const renderLogo = () => {
    if (isLoading) {
      return <Loader />;
    } else if (error) {
      return <img src={fallback_logo} alt="Fallback Logo" width="125" height="125" />;
    } else if (settings && settings.length > 0) {
      const logo = settings[0].site_logo.find((setting) => setting.isSelected === true);
      if (logo) {
        console.log(logo);
        return <img src={logo.site_logo_url} alt={logo.site_logo_name} width="125" height="125" />;
      } else {
        return <img src={fallback_logo} alt="Fallback Logo" width="125" height="125" />;
      }
    }
  };

  const getContactUrl = (name) => {
    if (!settings || settings.length === 0) {
      return "#";
    }

    const contact = settings[0].site_contacts.find(
      (setting) => setting.site_contact_name === name
    );
    return contact ? contact.site_contact_url : "#";
  };

  const twitter = getContactUrl("twitter");
  const facebook = getContactUrl("facebook");
  const instagram = getContactUrl("instagram");
  const linkedin = getContactUrl("linkedin");
  const whatsapp = getContactUrl("whatsapp");

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <footer style={{ backgroundColor: "var(--footer)", paddingTop: "20px" }}>
          <Container fluid>
            <Row className="justify-content-center text-center">
              <Col md={3} className="mb-3">
                <LinkContainer to="/">
                  <Navbar.Brand>
                    {renderLogo()}
                  </Navbar.Brand>
                </LinkContainer>
              </Col>
              <Col md={6} className="mb-3">
                <Stack direction="horizontal" gap={3} className="justify-content-center">
                  <LinkContainer to="/" className="nav-link">
                    <Nav.Link eventKey="1" style={{ color: "#343a40" }}>
                      <FaHome /> Home
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/contact" className="nav-link">
                    <Nav.Link eventKey="2" style={{ color: "#343a40" }}>
                      <FaEnvelope /> Contact Us
                    </Nav.Link>
                  </LinkContainer>
                  {/* <LinkContainer to="/contact" className="nav-link">
                    <Nav.Link eventKey="3" style={{ color: "#343a40" }}>
                      <FaLocationArrow /> Areas Covered
                    </Nav.Link>
                  </LinkContainer> */}
                </Stack>
              </Col>
              <Col md={3} className="mb-3">
                <Stack direction="horizontal" gap={2} className="justify-content-center">
                  <Nav.Link href={instagram} target="_blank" className="p-2" style={{ color: "#343a40" }}>
                    <FaInstagram />
                  </Nav.Link>
                  <Nav.Link href={facebook} target="_blank" className="p-2" style={{ color: "#343a40" }}>
                    <FaFacebook />
                  </Nav.Link>
                  <Nav.Link href={twitter} target="_blank" className="p-2" style={{ color: "#343a40" }}>
                    <FaTwitter />
                  </Nav.Link>
                  <Nav.Link href={linkedin} target="_blank" className="p-2" style={{ color: "#343a40" }}>
                    <FaLinkedinIn />
                  </Nav.Link>
                  <Nav.Link href={whatsapp} target="_blank" className="p-2" style={{ color: "#343a40" }}>
                    <FaWhatsapp />
                  </Nav.Link>
                </Stack>
              </Col>
            </Row>
            <hr className="my-4" style={{ borderColor: "#343a40" }} />
            <Row className="justify-content-center text-center">
              <Col md={4} className="mb-3">
                {/* <Stack direction="vertical" gap={2} className="text-center">
                  <LinkContainer to="/legal" className="nav-link">
                    <Nav.Link eventKey="4" style={{ color: "#343a40" }}>LEGAL</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/privacy" className="nav-link">
                    <Nav.Link eventKey="5" style={{ color: "#343a40" }}>PRIVACY POLICY</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/terms" className="nav-link">
                    <Nav.Link eventKey="6" style={{ color: "#343a40" }}>TERMS AND CONDITIONS</Nav.Link>
                  </LinkContainer>
                </Stack> */}
              </Col>
              <Col md={12}>
                <small style={{ color: "#343a40" }}>{currentYear} &copy; Extremely Clean PTY(LTD)</small>
              </Col>
            </Row>
          </Container>
        </footer>
      )}
    </>
  );
};

export default Footer;
