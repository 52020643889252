import {
  AlignmentType,
  convertInchesToTwip,
  LevelFormat,
  UnderlineType,
  TextRun,
  Footer,
  Paragraph,
  PageNumber,
  TabStopType,
  TabStopPosition,
} from "docx";

export const styles = {
  default: {
    heading1: {
      run: {
        size: 28,
        bold: true,
      },
      paragraph: {
        spacing: {
          after: 120,
        },
      },
    },
    heading2: {
      run: {
        size: 26,
        bold: true,
      },
      paragraph: {
        spacing: {
          before: 240,
          after: 120,
        },
      },
    },
    listParagraph: {
      run: {
        fontSize: 12,
      },
      paragraph: {
        indent: {
          left: convertInchesToTwip(0.5),
        },
        spacing: {
            line: 2 * 240, // Double line spacing (240 is the default line height, so multiply by 2 for double spacing)
          },
        
      },
    },
    document: {
      run: {
        size: "11pt",
        font: "Arial",
      },
      paragraph: {
        alignment: AlignmentType.LEFT,
       
      },
    },
  },
  paragraphStyles: [],
  characterStyles: [],
};


export const numbering = {
  config: [
    {
      reference: "rfq-response-numbering",
      levels: [
        {
          level: 0,
          format: LevelFormat.DECIMAL,
          text: "%1. ",
          alignment: AlignmentType.START,
          style: {
            paragraph: {
              indent: { left: 360, hanging: 0 }, // Set hanging to 0
            },
          },
        },
        {
          level: 1,
          format: LevelFormat.DECIMAL,
          text: "%1.%2. ",
          alignment: AlignmentType.START,
          style: {
            paragraph: {
              indent: { left: 720, hanging: 0 }, // Set hanging to 0
            },
          },
        },
        {
          level: 2,
          format: LevelFormat.DECIMAL,
          text: "%1.%2.%3. ",
          alignment: AlignmentType.START,
          style: {
            paragraph: {
              indent: { left: 1000, hanging: 0 }, // Set hanging to 0
            },
          },
        },
      ],
    },
  ],
};

export const footers = {
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: "Page ",
            bold: true,
            size: 18,
          }),
          new TextRun({
            children: [PageNumber.CURRENT],
            field: true,
            bold: true,
            size: 18,
          }),
          new TextRun({
            text: " of ",
            bold: true,
            size: 18,
          }),
          new TextRun({
            children: [PageNumber.TOTAL_PAGES],
            field: true,
            bold: true,
            size: 18,
          }),
        ],
        alignment: AlignmentType.RIGHT,
        tabStops: [
          {
            type: TabStopType.RIGHT,
            position: TabStopPosition.MAX,
          },
        ],
      }),
    ],
};






































