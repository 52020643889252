import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, Accordion } from "react-bootstrap";
import { FaEdit, FaTrash, FaPlusCircle } from "react-icons/fa";
import ProductModal from "./ProductModal";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import {
  useGetProductsQuery,
  useDeleteProductMutation,
} from "../../slices/productsApiSlice";

const ProductListScreen = () => {
  const { data: products, isLoading, error, refetch } = useGetProductsQuery();

  const [deleteProduct, { isLoading: loadingDelete }] =
    useDeleteProductMutation();

  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleAddProduct = () => {
    setSelectedProduct(null);
    setShowModal(true);
  };
  const handleEditProduct = (productId) => {
    const product = products.find((p) => p._id === productId);
    console.log(product);

    setSelectedProduct(product);
    setShowModal(true);
  };
  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await deleteProduct(id);
        toast.success("Product deleted successfully");
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Row className="align-items-center">
              <div className="d-flex mb-3">
                <h1>Products</h1>
                <Button
                  variant="light"
                  className="btn-sm"
                  onClick={handleAddProduct}
                >
                  <FaPlusCircle />
                </Button>
              </div>
            </Row>
          </Accordion.Header>
          {loadingDelete && <Loader />}
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            <Accordion.Body>
              <Table striped hover responsive className="table-sm">
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>PRICE</th>
                    <th>CATEGORY</th>
                    <th>BRAND</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product._id}>
                      <td>{product.name}</td>
                      {/* <td>{order.createdAt.substring(0,10)}</td> */}
                      <td>{product.price}</td>
                      <td>{product.category}</td>
                      <td>{product.brand}</td>
                      <td>
                        <Button
                          variant="light"
                          className="btn-sm mx-2"
                          onClick={() => handleEditProduct(product._id)}
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          variant="danger"
                          className="btn-sm"
                          onClick={() => deleteHandler(product._id)}
                        >
                          <FaTrash style={{ color: "white" }} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Body>
          )}
        </Accordion.Item>
      </Accordion>
      <ProductModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        product={selectedProduct}
      />
    </>
  );
};
export default ProductListScreen;
