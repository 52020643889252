import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteTaskModal = ({ show, handleClose, handleDelete, taskData }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Task Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this task?
        {taskData && (
          <div>
            <h5>Task: {taskData.name}</h5>
            <p>{taskData.description}</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        <Button variant="danger" onClick={handleDelete}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTaskModal;
