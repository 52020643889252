import { TEMPLATES_URL, UPLOADS_URL} from "../constants";
import { apiSlice } from "./apiSlice";

export const templatesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTemplates: builder.query({
        query: () => ({
            url: TEMPLATES_URL,
        }),
        providesTags: ["Templates"],
        }),
    
        getTemplateById: builder.query({
        query: (id) => ({
            url: `${TEMPLATES_URL}/${id}`,
        }),
        }),
    
        addTemplate: builder.mutation({
        query: (data) => ({
            url: TEMPLATES_URL,
            method: "POST",
            body: data,
        }),
        invalidatesTags: ["Templates"],
        }),
    
        updateTemplate: builder.mutation({
        query: (data) => ({
            url: `${TEMPLATES_URL}/${data.id}`,
            method: "PUT",
            body: data,
        }),
        invalidatesTags: ["Templates"],
        }),
    
        deleteTemplate: builder.mutation({
        query: (id) => ({
            url: `${TEMPLATES_URL}/${id}`,
            method: "DELETE",
        }),
        invalidatesTags: ["Templates"],
        }),
    }),
    });

export const {useGetTemplatesQuery, useGetTemplateByIdQuery, useAddTemplateMutation, useUpdateTemplateMutation, useDeleteTemplateMutation} = templatesApiSlice;