import {BMC_URL} from "../constants";
import {apiSlice} from "./apiSlice";

export const bmcApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBMC: builder.query({
            query: () => ({
                url: BMC_URL,
            }),
            providesTags: ["BMC"],
            keepUnusedDataFor: 5,
        }),
        getBMCNoteById: builder.query({
            query: ({block_id,note_id}) => ({
                url: `${BMC_URL}/note/${block_id}/${note_id}`,
            }),
        }),
        createBMCNote: builder.mutation({
            query: ({block_id,data} ) => ({
                url: `${BMC_URL}/${block_id}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ["BMC"],
        }),
        updateBMCNote: builder.mutation({
            query: ({block_id,note_id,data}) => ({
                url: `${BMC_URL}/note/${block_id}/${note_id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ["BMC"],
        }),
        deleteBMCNote: builder.mutation({
            query: ({block_id,note_id}) => ({
                url: `${BMC_URL}/note/${block_id}/${note_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["BMC"],
        }),
    }),
});

export const {useGetBMCQuery,useGetBMCNoteByIdQuery,useCreateBMCNoteMutation,useDeleteBMCNoteMutation,useUpdateBMCNoteMutation} = bmcApiSlice;