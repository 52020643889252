import { SERVICES_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const servicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: () => ({
        url: SERVICES_URL,
      }),
      keepUnusedDataFor: 5,
      invalidatesTags: ["Service"],
    }),
    getServiceById: builder.query({
      query: (id) => ({
        url: `${SERVICES_URL}/${id}`,
      }),
      keepUnusedDataFor: 5,
    }),

    deleteService: builder.mutation({
      query: (id) => {
        let url = `${SERVICES_URL}/${id}`;
        // if (type === "component") url += `/component/${sub_id}`;
        // else if (type === "task") url += `/task/${sub_id}`;
        // else if (type === "billing") url += `/billing/${sub_id}`;

        return {
          url,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Service"],
    }),

    updateService: builder.mutation({
      query: (data) => ({
        url: `${SERVICES_URL}/${data._id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }),
      invalidatesTags: ["Service"],
    }),
    createServiceCategory: builder.mutation({
      query: (data) => ({
        url: `${SERVICES_URL}/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Service"],
    }),
  }),
});

export const {
  useGetServicesQuery,
  useUpdateServiceMutation,
  useCreateServiceCategoryMutation,
  useGetServiceByIdQuery,
  useDeleteServiceMutation,
} = servicesApiSlice;
