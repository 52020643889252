import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteBillingModal = ({ show, handleClose, handleDelete, billingData }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Billing Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this billing item?
        {billingData && (
          <div>
            <h5>Billing: {billingData.name}</h5>
            <p>{billingData.description}</p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        <Button variant="danger" onClick={handleDelete}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteBillingModal;
