export const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};
//if the cartItem is a of type schedule, then the duration is the number of days, else the duration is the number of hours
export const updateTotal = (state) => {
  const itemsPrice = state.cartItems.reduce((acc, item) => {
    if (item.type === "Recurring") {
      const recurringRate = item.rate * item.duration;
      return acc + item.rate * (item.duration * item.days.length);
    } else {
      return acc + item.rate * item.duration;
    }
  }, 0);

  // Calculate the tax price
  const taxPrice = 0.15 * itemsPrice;
  state.taxPrice = addDecimals(taxPrice);

  // Calculate the total price
  const totalPrice = itemsPrice + taxPrice;

  //Calculate the subtotal
  state.subtotalPrice = addDecimals(itemsPrice);

  // Calculate the total price
  state.totalPrice = addDecimals(totalPrice);

  localStorage.setItem("cart", JSON.stringify(state));
  // Save the cart to localStorage

  return state;
};

export const updateHomeCleaning = (state, action) => {
  //formula for home cleaning  based on 1 hour per bedroom, 1 hour per bathroom, 1 hour per room, 1 hour per office multiple by the rate plus a base time of 2.5 hours
  const homeCleaningFormula = (bedrooms, bathrooms, rooms, offices, rate) => {
    return (2.5 + bedrooms + bathrooms + rooms + offices) * rate;
  };
  const newItem = action.payload;
  const existingItem = state.cartItems.find((item) => item._id === newItem._id);
  if (!existingItem) {
    state.cartItems = [...state.cartItems, newItem];
  }
  localStorage.setItem("cart", JSON.stringify(state));
  updateTotal(state);
};
