import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  Card,
  Row,
  FloatingLabel,
  Accordion,
  Image,

} from "react-bootstrap";
import yoco from "../assets/yoco.svg";
import visa from "../assets/visa.svg";
import mastercard from "../assets/mastercard.svg";
import eft from "../assets/eft.svg";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useGetAgentsQuery } from "../slices/usersApiSlice";
import {
  useAddOrderItemsMutation,
  useGetOrderByIdQuery,
  useGetPayFastPaymentIdentifierMutation,
  useCreateYocoPaymentMutation,
} from "../slices/ordersApiSlice";
import OrderSummary from "../components/OrderSummary";
import Rating from "../components/Rating";
import { saveServiceAddress, clearCart } from "../slices/cartSlice";
import { FaArrowRight } from "react-icons/fa";
import FormColumnContainer from "../components/FormColumnContainer";


const OrderPayment = () => {
  const dispatch = useDispatch();
  const { data: agents, isLoading, error } = useGetAgentsQuery();
  const [createOrder] = useAddOrderItemsMutation();
  const { id } = useParams();
  const cart = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const [form, setForm] = useState({
    fullName: "",
    address1: "",
    address2: "",
    suburb: cart.serviceAddress.suburb ? cart.serviceAddress.suburb : "",
    city: cart.serviceAddress.city ? cart.serviceAddress.city : "",
    mobile: "",
    email: "",
  });
  const [formAccordion, setFormAccordion] = useState("0");
  const [show, setShow] = useState(false);
  const [orderId, setOrderId] = useState("");
  const {
    data: order,
    isLoading: isLoadingOrder,
    error: errorOrder,
  } = useGetOrderByIdQuery(orderId);
  const [payFastPaymentIdentifier] =
    useGetPayFastPaymentIdentifierMutation(order);
  const [createYocoPayment] = useCreateYocoPaymentMutation();
  const [title, setTitles] = useState({});


  useEffect(() => {
    setTitles({ main: "Step 3 of 3: Order Confirmation", right: "Order Summary" });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //check that form is not empty, only field address2 can be empty
    if (
      form.fullName === "" ||
      form.address1 === "" ||
      form.suburb === "" ||
      form.city === "" ||
      form.mobile === "" ||
      form.email === ""
    ) {
      alert("Please fill in all the required fields");
      return;
    }
    dispatch(saveServiceAddress(form));
    try {
      const res = await createOrder({
        orderItems: cart.cartItems,
        serviceAddress: form,
        extremeAgent: cart.extremeAgent,
        paymentMethod: cart.paymentMethod,
        subtotalPrice: cart.subtotalPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,

        // user: userInfo._id,
      }).unwrap();
      setOrderId(res._id);
      setFormAccordion(false); //close form accordion
      setShow(true); //display enable place order button
    } catch (error) {
      console.log(error);
    }
  };
  const handleAccordionToggle = (e) => {
    setFormAccordion(e);
  };
  const handleYocoPayment = async () => {

    dispatch(clearCart());
    const yocoPaymentResponse = await createYocoPayment({
      order_id: orderId,
      amount: order.totalPrice * 100,
      currency: "ZAR",
    });
    console.log("yocoPaymentResponse", yocoPaymentResponse.data.redirectUrl);
    if (yocoPaymentResponse) {
      window.location.href = yocoPaymentResponse.data.redirectUrl;
    }
    //redict to yoco payment page
  };
  const formGroupGenerator = (label, name, type, value) => {
    return (
      <Form.Group className="my-2" controlId={name}>
        <FloatingLabel label={label}>
          <Form.Control
            type={type}
            value={value}
            onChange={(e) => setForm({ ...form, [name]: e.target.value })}
          ></Form.Control>
        </FloatingLabel>
      </Form.Group>
    );
  };
  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    agents && (

      <>
       <Row className="justify-content-md-center my-2">
         <FormColumnContainer id="step_one_order" title={title}>
          <Row>
            <OrderSummary />
            {agents
              .filter((selectedAgent) => selectedAgent._id === id)
              .map((selectedAgent) => (
                <Card
                  className="my-3 p-3 rounded"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Card.Body>
                    <Card.Title as="div">
                      <strong>Selected Agent</strong>
                    </Card.Title>
                    <Card.Title as="div">
                      <strong>{selectedAgent.name}</strong>
                    </Card.Title>
                    <Rating
                      value={selectedAgent.rating}
                      text={`${selectedAgent.numReviews} reviews`}
                    />
                  </Card.Body>
                  <Image
                    src={selectedAgent.curriculumVitae.personal_details[0].img}
                    alt={selectedAgent.name}
                    roundedCircle
                    style={{ width: 100, height: 100, marginRight: "10px" }}
                  />
                </Card>
              ))}
          </Row>
          <Row>
            <Accordion
              activeKey={formAccordion}
              onSelect={handleAccordionToggle}
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Service Address</Accordion.Header>
                <Accordion.Body>
                  <Form>
                    {formGroupGenerator(
                      "Full Name",
                      "fullName",
                      "text",
                      form.fullName
                    )}
                    {formGroupGenerator(
                      "Address 1",
                      "address1",
                      "text",
                      form.address1
                    )}
                    {formGroupGenerator(
                      "Address 2",
                      "address2",
                      "text",
                      form.address2
                    )}
                    {formGroupGenerator(
                      "Suburb",
                      "suburb",
                      "text",
                      form.suburb,
                     
                    )}
                    {formGroupGenerator("City", "city", "text", form.city)}
                    {formGroupGenerator(
                      "Mobile",
                      "mobile",
                      "text",
                      form.mobile
                    )}
                    {formGroupGenerator("Email", "email", "email", form.email)}
                  </Form>
                  <button onClick={handleSubmit} class="button-28">
                    Save Address <FaArrowRight />
                  </button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
          {show ? (
            <>
              <button onClick={handleYocoPayment} class="button-28">
                Place Booking <FaArrowRight />
              </button>
              <h6 className=" pt-3 text-center">Secure Checkout With YOCO</h6>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <img src={yoco} alt="yoco" width="50" height="50" />
                <img src={visa} alt="visa" width="50" height="50" />
                <img src={mastercard} alt="mastercard" width="50" height="50" />
                <img src={eft} alt="eft" width="50" height="50" />
              </div>
            </>
          ) : null}
        </FormColumnContainer>
        </Row>
      </>
    )
  );
};

export default OrderPayment;
