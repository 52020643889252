import { Outlet, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"   

const AdminRoute = () => {

    const {userInfo} = useSelector(state => state.auth)
    if(!userInfo || !userInfo.isAdmin){
        return <Navigate to='/signin' />
    }

  return userInfo && userInfo.isAdmin ? <Outlet /> : <Navigate to='/signin' />

}

export default AdminRoute