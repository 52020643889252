// src/components/CurriculumVitaeForm.js
import React, { useState } from "react";
import {
  Container,
  Row,
  Form,
  Button,
  FloatingLabel,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { FaArrowRight } from "react-icons/fa";
import {
  useUpdateAgentsCVMutation,
  useUploadUserAvatarMutation,
  useUploadUserDocumentsMutation,
} from "../../slices/usersApiSlice";

const CurriculumVitaeForm = ({ onSubmit, user }) => {
  const [image, setImage] = useState("");
  const [documents, setDocuments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  //set form data to the curriculumVitae if agent is passed as a prop, else set it to an empty object
  const [formData, setFormData] = useState(
    user.curriculumVitae || {
      personal_details: [
        {
          img: "",
          documents: "",
          phone: "",
        },
      ],
      addresses: [
        {
          address1: "",
          address2: "",
          city: "",
          suburb: "",
          code: "",
        },
      ],
      languages: [
        {
          language: "",
          fluency: "",
        },
      ],
      interests: [
        {
          name: "",
          keywords: "",
        },
      ],
      work: [
        {
          company: "",
          position: "",
          startDate: "",
          endDate: "",
          summary: "",
        },
      ],
      education: [
        {
          institution: "",
          qualification: "",
          startDate: "",
          endDate: "",
        },
      ],
      skills: [
        {
          name: "",
          level: "",
          keywords: "",
        },
      ],
      references: [
        {
          name: "",
          position: "",
          email: "",
          phone: "",
        },
      ],
    }
  ); // Add the initial state as the agent's curriculumVitae || intialise fields for user to complete form

  const [updateAgentsCV, { isLoading: loadingUpdate }] =
    useUpdateAgentsCVMutation();
  const [uploadUserAvatar, { isLoading: loadingAvatar }] =
    useUploadUserAvatarMutation();
  const [uploadUserDocuments, { isLoading: loadingDocuments }] =
    useUploadUserDocumentsMutation();

  const generateEmptyItem = (section) => {
    switch (section) {
      case "personal_details":
        return { img: "", documents: "", phone: "" };
      case "addresses":
        return { address1: "", address2: "", city: "", suburb: "", code: "" };
      case "languages":
        return { language: "", fluency: "" };
      case "interests":
        return { name: "", keywords: "" };
      case "work":
        return {
          company: "",
          position: "",
          startDate: "",
          endDate: "",
          summary: "",
        };
      case "education":
        return {
          institution: "",
          qualification: "",
          startDate: "",
          endDate: "",
        };
      case "skills":
        return { name: "", level: "", keywords: "" };
      case "references":
        return { name: "", position: "", email: "", phone: "" };
      // Add other cases for different sections as needed
      default:
        return {};
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNestedChange = (section, index, field, e) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const updatedArray = [...prevData[section]];
      updatedArray[index] = {
        ...updatedArray[index],
        [field]: value,
      };

      return {
        ...prevData,
        [section]: updatedArray,
      };
    });
  };

  const handleAddItem = (section) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: [...prevData[section], generateEmptyItem(section)],
    }));
  };

  const handleRemoveItem = (section, index) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: prevData[section].filter((item, i) => i !== index),
    }));
  };
  const handleAvatarChange = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await uploadUserAvatar(formData).unwrap();
      toast.success(res.message);
      setFormData((prevData) => ({
        ...prevData,
        personal_details: [{ ...prevData.personal_details[0], img: res.image }],
      }));
      setImage(res.image);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleUploadDocuments = async () => {
    // const files = e.target.files;
    const files = selectedFiles;
    const formData = new FormData();

    // Allow only up to 3 files
    if (files.length > 3) {
      toast.error("You can upload a maximum of 3 documents.");
      return;
    }

    // Append each file to the FormData
    for (let i = 0; i < files.length; i++) {
      formData.append("documents", files[i]);
    }

    try {
      const res = await uploadUserDocuments(formData).unwrap();
      toast.success(res.message);
      setFormData((prevData) => ({
        ...prevData,
        documents: res.documents, // Store the uploaded document information separately
      }));
      setDocuments(res.documents);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleSubmit = async (e) => {
    // Add async keyword to the function
    e.preventDefault();
    try {
      await updateAgentsCV({ agentId: user._id, data: formData }); // Await the dispatch call
      toast.success("Curriculum Vitae updated successfully");
      console.log(user._id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileSelect = (e) => {
    const filesArray = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
  };

  const handleFileDelete = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  const createFormLabels = (section) => {
    const labels = Object.keys(formData[section][0]);
    //if section is personal details, add img to the labels array and file input type to the form
    return formData[section].map((item, index) =>
      labels.map((label) => {
        console.log(label);
        if (label.includes("_id")) {
          return null; // Skip creating the field -filter out items with mongodb _id
        }
        if (label.includes("img")) {
          return (
            <FloatingLabel
              className="my-2"
              htmlFor={`${section}-${index}-${label}`}
              label={label}
            >
              <Form.Control
                type="text"
                id={`${section}-${index}-${label}`}
                name={`${section}-${index}-${label}`}
                value={item[label]}
                onChange={(e) => handleNestedChange(section, index, label, e)}
                required
              />
              {/* Add image preview */}
              <img src={image} alt="avatar" style={{ width: "100px" }} />

              <Form.Control
                type="file" // Set the input type to file
                id={`${section}-${index}-${label}`}
                name={`${section}-${index}-${label}`}
                onChange={(e) => handleAvatarChange(e)}
                required
              />
            </FloatingLabel>
          );
        }
        if (label.includes("documents")) {
          return (
            <FloatingLabel
              className="my-2"
              htmlFor={`${section}-${index}-${label}`}
              label={label}
            >
              <Form.Control
                type="text"
                id={`${section}-${index}-${label}`}
                name={`${section}-${index}-${label}`}
                value={item[label]}
                onChange={(e) => handleNestedChange(section, index, label, e)}
                required
              />

              <Form.Control
                type="file"
                id={`${section}-${index}-${label}`}
                name={`${section}-${index}-${label}`}
                onChange={(e) => handleFileSelect(e)}
                multiple // Allow multiple file selection
                required
              />
              <div>
                {selectedFiles.map((file, i) => (
                  <div
                    key={`selected-file-${i}`}
                    
                  >
                    <InputGroup style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}>
                      <Form.Control
                        plaintext
                        readOnly
                        value={file.name}
                        width="50%"
                      />
                      <Button
                        className="mb-2"
                        variant="danger"
                        onClick={() => handleFileDelete(i)}
                      >
                        Remove
                      </Button>
                    </InputGroup>
                  </div>
                ))}
              </div>
              {selectedFiles.length > 0 && (
                <Button onClick={handleUploadDocuments}>
                  Upload Documents
                </Button>
              )}
            </FloatingLabel>
          );
        }

        return (
          <FloatingLabel
            className="my-2"
            htmlFor={`${section}-${index}-${label}`}
            label={label}
          >
            <Form.Control
              type="text"
              id={`${section}-${index}-${label}`}
              name={`${section}-${index}-${label}`}
              value={item[label]}
              onChange={(e) => handleNestedChange(section, index, label, e)}
              required
            />
          </FloatingLabel>
        );
      })
    );
  };
  const SelectedFiles = ({ files }) => {
    return (
      <div>
        {files.map((file, index) => (
          <Form.Control
            plaintext
            readOnly
            value={file.name}
            key={`selected-file-${index}`}
          />
        ))}
      </div>
    );
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row className="g-2">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <p>
                  <h4>Personal Details</h4>
                  <span>Personal info and file upload section:</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                {createFormLabels("personal_details")}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* Address */}
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <p>
                  <h4>Addresses</h4>
                  <span>Your residential Address:</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                {createFormLabels("addresses")}
                <Button
                  variant="primary"
                  onClick={() => handleAddItem("addresses")}
                >
                  Add Address
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <p>
                  <h4>Languages</h4>
                  <span>Spoken Languages:</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                {createFormLabels("languages")}
                <Button
                  variant="primary"
                  onClick={() => handleAddItem("languages")}
                >
                  Add Language
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <p>
                  <h4>Interests</h4>
                  <span>Your Interests or Hobbies:</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                {createFormLabels("interests")}
                <Button
                  variant="primary"
                  onClick={() => handleAddItem("interests")}
                >
                  Add Interest
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <p>
                  <h4>Work</h4>
                  <span>Your Work History:</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                {createFormLabels("work")}
                <Button variant="primary" onClick={() => handleAddItem("work")}>
                  Add Work
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <p>
                  <h4>Education</h4>
                  <span>Your Educational Background:</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                {createFormLabels("education")}
                <Button
                  variant="primary"
                  onClick={() => handleAddItem("education")}
                >
                  Add Education
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <p>
                  <h4>Skills</h4>
                  <span>Your Skills:</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                {createFormLabels("skills")}
                <Button
                  variant="primary"
                  onClick={() => handleAddItem("skills")}
                >
                  Add Skill
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <p>
                  <h4>References</h4>
                  <span>Background contacts:</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                {createFormLabels("references")}
                <Button
                  variant="primary"
                  onClick={() => handleAddItem("references")}
                >
                  Add Reference
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <button type="submit" className="button-28">
            Update CV <FaArrowRight />
          </button>
        </Row>
      </Form>
    </Container>
  );
};

export default CurriculumVitaeForm;
