import {Container, Row, Col,Card} from 'react-bootstrap'

const FormColumnContainer = ( {title,children,title_right,order}) => {
  return (
    <>
     {console.log(title.main)}
        
            <Col xs={12} md={8}>
            <Card className= 'rounded' as='div'>
            <div class="custom-card-header">
                <Card.Title as='h3'  className="text-center" >{title.main}</Card.Title>
              </div>
              <Card.Body>
                {children}
              </Card.Body>
            </Card>
            </Col>
  
    </>
   
  )
}

export default FormColumnContainer