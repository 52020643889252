import React, { useState, useEffect } from "react";
import { Accordion, Form, Button, Row } from "react-bootstrap";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useGetTemplatesQuery } from "../../slices/templatesApiSlice";
import {
  Document,
  Packer,
  Header,
  ImageRun,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  WidthType,
  Footer,
  TableOfContents,
  AlignmentType,
  PageNumber,
  NumberFormat,
  BorderStyle,
  HeadingLevel,
  TabStopType,
  TabStopPosition,
} from "docx";
import { saveAs } from "file-saver";
import { FaFileWord } from "react-icons/fa";
import { useGetSettingsQuery } from "../../slices/settingsApiSlice";
import { useGetServicesQuery } from "../../slices/servicesApiSlice";

const TemplatesScreen = () => {
  const {
    data: settings,
    isLoading: isLoadingSettings,
    error: errorSettings,
  } = useGetSettingsQuery();
  const { data: templates, isLoading, error } = useGetTemplatesQuery();
  const {
    data: services,
    isLoading: isLoadingServices,
    error: errorServices,
  } = useGetServicesQuery();
  const [header, setHeader] = useState("Header Content");
  const [content, setContent] = useState("Body Content");
  const [rfqfooter, setRfqFooter] = useState(
    "RFQ 5354-2168-2023-Request for Network and LAN infrastructure, Upgrade Durban Central SAPS KZN"
  );
  const [logoBase64, setLogoBase64] = useState(null);
  const [tablecontents, setTableContents] = useState(["Introduction", "Vision", "Mission", "Services","Pricing Schedule","Compliance"]);

  useEffect(() => {
    if (settings) {
      const selectedLogo = settings?.[0]?.site_logo?.find(
        (setting) => setting.isSelected === true
      );
      if (selectedLogo) {
        const logoUrl = selectedLogo.site_logo_url;
        fetch(logoUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              setLogoBase64(reader.result);
            };
            reader.readAsDataURL(blob);
          })
          .catch((error) => console.error("Error fetching logo image:", error));
      }
    }
  }, [settings]);

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const handleCreateDoc = () => {
    // Sample data
    const rows = [
      { no: "1", description: "Item 1", uom: "pcs", qty: "10", price: "100" },
      { no: "2", description: "Item 2", uom: "pcs", qty: "5", price: "200" },
      { no: "3", description: "Item 3", uom: "pcs", qty: "2", price: "300" },
    ];

    // Calculations
    const subTotal = rows.reduce(
      (total, row) => total + parseFloat(row.qty) * parseFloat(row.price),
      0
    );
    const vat = subTotal * 0.15; // Assuming VAT is 15%
    const total = subTotal + vat;

    // Create table rows
    const tableRows = rows.map(
      (row) =>
        new TableRow({
          children: [
            new TableCell({ children: [new Paragraph(row.no)] }),
            new TableCell({ children: [new Paragraph(row.description)] }),
            new TableCell({ children: [new Paragraph(row.uom)] }),
            new TableCell({ children: [new Paragraph(row.qty)] }),
            new TableCell({ children: [new Paragraph(row.price)] }),
          ],
        })
    );

    // Add subtotal, VAT, and total rows
    tableRows.push(
      new TableRow({
        children: [
          new TableCell({ children: [new Paragraph("")], columnSpan: 3 }),
          new TableCell({ children: [new Paragraph("SUB-TOTAL")] }),
          new TableCell({ children: [new Paragraph(subTotal.toFixed(2))] }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({ children: [new Paragraph("")], columnSpan: 3 }),
          new TableCell({ children: [new Paragraph("VAT")] }),
          new TableCell({ children: [new Paragraph(vat.toFixed(2))] }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({ children: [new Paragraph("")], columnSpan: 3 }),
          new TableCell({ children: [new Paragraph("TOTAL")] }),
          new TableCell({ children: [new Paragraph(total.toFixed(2))] }),
        ],
      })
    );

    // Create the table
    const table = new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({ children: [new Paragraph("NO")] }),
            new TableCell({ children: [new Paragraph("DESCRIPTION")] }),
            new TableCell({ children: [new Paragraph("UOM")] }),
            new TableCell({ children: [new Paragraph("QTY")] }),
            new TableCell({ children: [new Paragraph("PRICE")] }),
          ],
        }),
        ...tableRows,
      ],
    });

    //Create Introduction,Mission,Vision
     
    const about = () => {
      const about = settings?.[0]?.site_copy || [];
      const introduction = stripHtml(
        about.find((item) => item.heading === "Introduction")
          ?.content || ""
      );
      const vision = stripHtml(
        about.find((item) => item.heading === "Vision")?.content || ""
      );
      const mission = stripHtml(
        about.find((item) => item.heading === "Mission")?.content || ""
      );                                          
      return [introduction,vision,mission];
    };

    // Prepare site copy content
    const siteCopyContent = settings?.[0]?.site_copy || [];
    const introduction = stripHtml(
      siteCopyContent.find((item) => item.heading === "Introduction")
        ?.content || ""
    );
    const vision = stripHtml(
      siteCopyContent.find((item) => item.heading === "Vision")?.content || ""
    );
    const mission = stripHtml(
      siteCopyContent.find((item) => item.heading === "Mission")?.content || ""
    );

    // Prepare service pages content
    const servicePages = services
      ?.map((service) => {
        const category = new Paragraph({
          text: service.category,
          heading: HeadingLevel.HEADING_1,
          pageBreakBefore: true,
        });

        const components = service.component.map((comp) => [
          new Paragraph({
            text: comp.name,
            heading: HeadingLevel.HEADING_2,
          }),
          new Paragraph(stripHtml(comp.description)),
        ]);

        return [category, ...components.flat()];
      })
      .flat();

    // Create the document
    const doc = new Document({
      features: {
        updateFields: true,
      },
      sections: [
        {
          properties: {
            page: {
              pageNumbers: {
                start: 1,
                formatType: NumberFormat.DECIMAL,
              },
            },
          },
          children: [
            new Paragraph({
              children: [
                new ImageRun({
                  data: logoBase64,
                  transformation: {
                    width: 600,
                    height: 600,
                  },
                }),
              ],
              alignment: AlignmentType.CENTER,
              border: {
                top: { color: "0000FF", size: 24, style: BorderStyle.SINGLE },
                bottom: {
                  color: "0000FF",
                  size: 24,
                  style: BorderStyle.SINGLE,
                },
                left: { color: "0000FF", size: 24, style: BorderStyle.SINGLE },
                right: { color: "0000FF", size: 24, style: BorderStyle.SINGLE },
              },
            }),
            new Paragraph({
              text: "Company Name",
              heading: HeadingLevel.TITLE,
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              text: "Cover Page",
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
            }),
          ],
        },
        {
          properties: {
            titlePage: true,
          },
          headers: {
            default: new Header({
              children: [
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new ImageRun({
                                  data: logoBase64,
                                  transformation: {
                                    width: 100,
                                    height: 100,
                                  },
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 20,
                            type: WidthType.PERCENTAGE,
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Website: www.extremelyclean.co.za",
                                  break: 1,
                                  font: {
                                    size: 24,
                                  },
                                  alignment: AlignmentType.RIGHT,
                                }),
                                new TextRun({
                                  text: "Email: info@extremelyclean.co.za",
                                  break: 1,
                                }),
                                new TextRun({
                                  text: "Tel: 031 701 2028",
                                  break: 1,
                                }),
                                new TextRun({
                                  text: "Cell: 083 786 2028",
                                  break: 1,
                                }),
                              ],
                            }),
                          ],
                          width: {
                            size: 80,
                            type: WidthType.PERCENTAGE,
                          },
                        }),
                      ],
                    }),
                  ],
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                }),
              ],
            }),
          },
          children: [
            new Paragraph({
              text: "Table of Contents",
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
            }),
            new TableOfContents("Table of Contents", {
              headingStyleRange: "1-3",
            }),
            new Paragraph({
              text: "First Page",
              heading: HeadingLevel.HEADING_1,
              pageBreakBefore: true,
            }),
            new Paragraph({
              text: "1.0) Introduction",
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph(introduction),
            new Paragraph({
              text: "1.1) Vision",
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph(vision),
            new Paragraph({
              text: "1.2) Mission",
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph(mission),
            ...servicePages,
            new Paragraph({
              children: [
                new TextRun({
                  text: content,
                }),
              ],
            }),
            table,
          ],
          footers: {
            default: new Footer({
              children: [
                new Paragraph(rfqfooter),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Page ",
                      bold: true,
                    }),
                    new TextRun({
                      children: [PageNumber.CURRENT],
                      field: true,
                      bold: true,
                    }),
                    new TextRun({
                      text: " of ",
                      bold: true,
                    }),
                    new TextRun({
                      children: [PageNumber.TOTAL_PAGES],
                      field: true,
                      bold: true,
                    }),
                  ],
                  alignment: AlignmentType.RIGHT,
                  tabStops: [
                    {
                      type: TabStopType.RIGHT,
                      position: TabStopPosition.MAX,
                    },
                  ],
                }),
              ],
            }),
          },
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "example.docx");
    });
  };

  return (
    <>
      {isLoading || isLoadingSettings || isLoadingServices ? (
        <Loader />
      ) : error || errorSettings || errorServices ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h1>Templates</h1>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                {templates?.map((template) => (
                  <div key={template._id}>
                    <h2>{template.name}</h2>
                    <p>{template.description}</p>
                  </div>
                ))}
                <Form>
                  <Form.Group controlId="header">
                    <Form.Label>Header</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={header}
                      onChange={(e) => setHeader(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="content">
                    <Form.Label>Content</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="footer">
                    <Form.Label>Footer</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={rfqfooter}
                      onChange={(e) => setRfqFooter(e.target.value)}
                    />
                  </Form.Group>
                  <Button onClick={handleCreateDoc}>
                    <FaFileWord /> Create Doc
                  </Button>
                </Form>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

export default TemplatesScreen;
