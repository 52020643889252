import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form,  Row, Col, FloatingLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useRegisterMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";
import { FaArrowRight,  } from "react-icons/fa";

const RegisterScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEmployee, setIsEmployee] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();
  const { userInfo } = useSelector((state) => state.auth);
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/profile';

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return; 
    } else {
      try {
        const res = await register({ name, email, password, isEmployee }).unwrap();
        console.log(res);
        dispatch(setCredentials({ ...res }));
        navigate(redirect);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <FormContainer title="Register">
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="name" className="my-3">
          <FloatingLabel
            controlId="floatingInput"
            label="Full Name"
            className="mb-3"
          >
            <Form.Control
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="email" className="my-3">
          <FloatingLabel
            controlId="floatingInput"
            label="Email Address"
            className="mb-3"
          >
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
              title="Please enter a valid email"
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </FloatingLabel>
        </Form.Group>

        <Form.Group controlId="password" className="my-3">
          <FloatingLabel
            controlId="floatingInput"
            label="Enter Password"
            className="mb-3"
          >
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="confirmPassword" className="my-3">
          <FloatingLabel
            controlId="floatingInput"
            label="Confirm Password"
            className="mb-3"
          >
            <Form.Control
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></Form.Control>
          </FloatingLabel>
        </Form.Group>
       
        <Form.Group className="my-3">
          <hr />
          <Form.Check
            type="checkbox"
            label= {isEmployee ? "Sign-in after registering to complete your application" : "Check this box to Apply as an Agent"}
            checked={isEmployee}
            onChange={(e) => setIsEmployee(e.target.checked)}
            className="isAgent-checkbox"
            style={{ fontSize: '1.2rem', color: 'grey' }} // Customize checkbox style
          />
          <hr />
        </Form.Group>
        <button type="submit" disabled={isLoading} className="button-28">
          Register <FaArrowRight />
        </button>
        {isLoading && <Loader />}
      </Form>
      <Row className="py-3">
        <Col>
          Already have an account?{" "}
          <Link to={redirect ? `/signin?redirect=${redirect}` : "/signin"}>
            Login
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default RegisterScreen;
