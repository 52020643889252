import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useGetServicesQuery } from '../slices/servicesApiSlice';
import { useGetSettingsQuery } from '../slices/settingsApiSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import ServicesCard from '../components/ServicesCard';
import StorySection from '../components/Copy';
import Chat from '../components/Chat';

const HomeScreen = () => {
  const { data: services, isLoading: isLoadingServices, error: errorServices } = useGetServicesQuery();
  const { data: settings, isLoading: isLoadingSettings, error: errorSettings } = useGetSettingsQuery();

  return (
    <>
      {isLoadingServices ? (
        <Loader />
      ) : errorServices ? (
        <Message variant="danger">{errorServices}</Message>
      ) : (
        <Container>
          <h1 className="main-heading text-center p-3">Pest Control and Cleaning Services</h1>
          <Row>
            {services.map((service) => (
              <Col xs={12} md={6} lg={6} key={service.id}>
                <ServicesCard service={service} />
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <StorySection settings={settings} isLoading={isLoadingSettings} error={errorSettings} />
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12} md={12} lg={12}>
              <Chat />
            </Col> 
          </Row>*/}
        </Container>
      )}
    </>
  );
};

export default HomeScreen;
